import * as React from 'react';
import Instance from '../Instance';
import { xmlRender } from './XmlRender.Component';

interface IProps {
  instance: Instance;
}

const InstanceRenderComponent = ({ instance }: IProps) => {
  const [lastUpdated, setLastUpdated] = React.useState(new Date());

  const onRefreshClick = () => {
    setLastUpdated(new Date());
  };

  return (
    <div className="xmlDebugContainer">
      <div>
        <h3>{instance.id}</h3>
        <p>Last Updated {lastUpdated.toString()} </p>
      </div>
      <div>
        <button type="button" onClick={onRefreshClick}>
          Refresh
        </button>
      </div>
      <code>
        {xmlRender.render(React.createElement, instance.xml.firstElementChild as unknown as Node)}
      </code>
    </div>
  );
};

export default InstanceRenderComponent;
