import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

interface Props extends Omit<IconButtonProps, 'component' | 'className'> {
  expanded: boolean;
  arialabel?: string;
  handleChange: (value: boolean) => void;
  tooltipText?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  dropdownButton: {
    color: 'white',
    background: '#33444E',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '35px',
    width: '35px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    '&:hover': {
      background: theme.palette.info.light,
    },
  },
  icon: {
    fontSize: '40px',
    '&:hover': {
      //border: "3px solid",
    },
  },
}));

const DropdownArrow = (props: Props) => {
  const { expanded, handleChange, arialabel = 'this section', tooltipText = '', ...rest } = props;

  const classes = useStyles();

  const onClick = (): void => {
    handleChange(!expanded);
  };

  return (
    <Tooltip title={`${expanded ? 'Collapse' : 'Expand'} ${tooltipText}`}>
      <IconButton
        aria-label={`${expanded ? 'Collapse' : 'Expand'} ${arialabel}`}
        className={classes.dropdownButton}
        onClick={onClick}
        {...rest}
      >
        {expanded ? (
          <ArrowDropDownIcon className={classes.icon} />
        ) : (
          <ArrowRightIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default DropdownArrow;
