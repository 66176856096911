import React from 'react';
import useAxios from 'axios-hooks';
import { LoadingSpinner } from '@airelogic/forms';
import { GetFormResponse, BuildingBlockVersionData } from '../FormBuildingInterfaces';
import { useParams } from 'react-router-dom';
import { EditorMode, FormEdit } from '../FormEdit/FormEdit';
import { parseGetFormResponse } from '../FormBuilding.Service';

interface Params {
  key: string;
  version?: string;
}

const blankBuildingBlock: BuildingBlockVersionData = {
  key: '',
  versions: [],
};

const EditForm = () => {
  const { key, version } = useParams<Params>();
  const [{ data: blockVersionsResponse, loading: blocksLoading }] = useAxios<
    BuildingBlockVersionData[]
  >({
    url: '/internalapi/FormBuilding/forms/buildingblocks',
  });

  const [{ data: formData, loading: formLoading }] = useAxios<GetFormResponse>({
    url: `/internalapi/FormBuilding/forms/${key}${version ? `/${version}` : ''}`,
  });

  if (blocksLoading || formLoading || !blockVersionsResponse) {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (blockVersionsResponse) {
    if (!blockVersionsResponse.find((x) => x.key === '')) {
      blockVersionsResponse.unshift(blankBuildingBlock);
    }
  }

  if (!formData) {
    throw new Error('No form data found');
  }

  const editorMode: EditorMode = version ? 'edit' : 'newversion';

  return (
    <FormEdit
      blockVersions={blockVersionsResponse}
      editorMode={editorMode}
      currentValues={parseGetFormResponse(formData, editorMode)}
    ></FormEdit>
  );
};

export default EditForm;
