import React from 'react';
import useAxios from 'axios-hooks';
import { LoadingSpinner } from '@airelogic/forms';
import ReferenceDataFormEdit from '../ReferenceDataFormEdit/ReferenceDataFormEdit';
import { ReferenceDataFields, ReferenceDataItem } from '../Types';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';

interface Props {
  isNew: boolean;
}

const emptyDefaults: ReferenceDataFields = {
  key: '',
  referenceData: [
    {
      displayName: '',
      code: '',
      deprecated: false,
      filterGroups: '',
      isNew: true,
    },
  ],
};

const ReferenceDataPage = ({ isNew }: Props) => {
  interface Params {
    key: string;
    version?: string;
  }

  const { key, version } = useParams<Params>();
  const [defaultValues, setDefaultValues] = useState<ReferenceDataFields>();
  const [{ loading }, fetchExistingData] = useAxios<ReferenceDataItem[]>(
    {
      url: `/internalapi/formbuilding/referencedata/${key}${version ? `/${version}` : ''}`,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    const setUp = async () => {
      if (isNew) {
        setDefaultValues(emptyDefaults);
      } else {
        const results = await fetchExistingData();
        const values: ReferenceDataFields = {
          key: key,
          version: version,
          referenceData: results.data,
        };
        setDefaultValues(values);
      }
    };

    setUp();
  }, [fetchExistingData, isNew, key, version]);

  if (loading) {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (defaultValues) {
    return (
      <ReferenceDataFormEdit currentValues={defaultValues} isNew={isNew}></ReferenceDataFormEdit>
    );
  }

  return null;
};
export default ReferenceDataPage;
