import { ITypeDefinition } from './TypeDefinitionFactory';

export default class DecimalDefinition implements ITypeDefinition {
  private static regex: RegExp = /^([-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+))?$/;

  formatToLocale(value: string) {
    return value; //localisation should kick in here. e.g different decimal seperators
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return DecimalDefinition.regex.test(value);
  }
}
