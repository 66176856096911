import Enumerable from 'linq';
import { Element } from 'slimdom';
import { assertNever } from '../Utils';
import Bind from '../Components/Bind';
import BindStore from '../Components/BindStore';
import BuildingBlock from '../Components/BuildingBlock';
import Cell from '../Components/Cell';
import Control from '../Components/Control';
import Grid from '../Components/Grid';
import Instance from '../Components/Instance';
import MasterChild from '../Components/MasterChild';
import Repeat from '../Components/Repeat';
import Row from '../Components/Row';
import Section from '../Components/Section';
import { IBuildingBlockDefinition } from '../Definitions/IBuildingBlockDefinition';
import { ICellDefinition } from '../Definitions/ICellDefinition';
import { IGridDefinition } from '../Definitions/IGridDefinition';
import { IMasterChildDefinition } from '../Definitions/IMasterChildDefinition';
import { IRepeatDefinition } from '../Definitions/IRepeatDefinition';
import { ISectionDefinition } from '../Definitions/ISectionDefinition';
import { tryResolveBindToElement } from './BindConverter';
import { InstanceStore } from '../Components/InstanceStore';

//Temp
export const convertBuildingBlock = (
  instanceStore: InstanceStore,
  bindStore: BindStore,
  buildingBlocks: IBuildingBlockDefinition[]
): Enumerable.IDictionary<string, BuildingBlock> => {
  return new BuildingBlockConverter().convert(instanceStore, bindStore, buildingBlocks);
};

class BuildingBlockConverter {
  public convert(
    instanceStore: InstanceStore,
    bindStore: BindStore,
    buildingBlocks: IBuildingBlockDefinition[]
  ) {
    return Enumerable.from(buildingBlocks).toDictionary(
      (definition) => definition.key,
      (definition) =>
        this.convertBlock(instanceStore.resolve(definition.key), bindStore, definition)
    );
  }

  private convertBlock(
    instance: Instance,
    bindStore: BindStore,
    definition: IBuildingBlockDefinition
  ): BuildingBlock {
    const sections = definition.layout.map((section) =>
      this.convertSection(instance, bindStore, section)
    );

    const bind = bindStore.getBindById(`${definition.key}-fr-form-binds`);

    bind.attachToElement(instance.xml.firstElementChild!);

    return new BuildingBlock(definition.key, bind, sections, definition.viewMode);
  }

  private convertSection(
    instance: Instance,
    bindStore: BindStore,
    sectionDefinition: ISectionDefinition
  ): Section {
    const sectionBind = bindStore.getBindById(sectionDefinition.id);

    this.attachBindToElement(instance, sectionBind);

    const grids: Array<Grid | Repeat | MasterChild> = sectionDefinition.grids.map((grid) => {
      switch (grid.type) {
        case 'repeat':
          return this.createRepeat(instance, bindStore, grid);
        case 'grid':
          return this.createGrid(instance, bindStore, grid);
        case 'master-child':
          return this.createMasterChild(instance, bindStore, grid);
        default:
          return assertNever(grid);
      }
    });

    return new Section(sectionDefinition, sectionBind, grids);
  }

  private createGrid(instance: Instance, bindStore: BindStore, definition: IGridDefinition): Grid {
    const convertedRows = definition.rows.map(
      (rowDefinition) =>
        new Row(
          rowDefinition,
          rowDefinition.cells.map((cell) => this.createCell(instance, bindStore, cell))
        )
    );
    return new Grid(convertedRows);
  }

  private createCell(instance: Instance, bindStore: BindStore, definition: ICellDefinition): Cell {
    if (definition.control) {
      const resolvedBind = bindStore.getBindById(definition.control.id);
      const element = this.attachBindToElement(instance, resolvedBind);

      if (definition.control.type === 'secret') {
        element.setAttribute('f4h:secret', 'true');
      }

      return new Cell(definition, new Control(definition.control, resolvedBind));
    } else {
      return new Cell(definition);
    }
  }

  private attachBindToElement(instance: Instance, bind: Bind): Element {
    const resolvedElement = tryResolveBindToElement(instance, bind.fullRef);

    if (resolvedElement == null) {
      throw Error('Unable to find element ' + bind.fullRef);
    }

    bind.attachToElement(resolvedElement);

    return resolvedElement;
  }

  private createRepeat(
    instance: Instance,
    bindStore: BindStore,
    definition: IRepeatDefinition
  ): Repeat {
    const resolvedBind = bindStore.getBindById(definition.id);
    const repeatElement = this.attachBindToElement(instance, resolvedBind);
    return new Repeat(definition, resolvedBind, repeatElement, bindStore);
  }

  private createMasterChild(
    instance: Instance,
    bindStore: BindStore,
    definition: IMasterChildDefinition
  ): MasterChild {
    const resolvedBind = bindStore.getBindById(definition.id);
    const repeatElement = this.attachBindToElement(instance, resolvedBind);
    return new MasterChild(definition, resolvedBind, repeatElement, bindStore);
  }
}
