import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Role from './Role';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  })
);

const RoleKeyList = (props: { roles: Role[]; className?: string }) => {
  const { roles, className } = props;
  const classes = useStyles();

  return (
    <Paper className={classNames(classes.root, className)} elevation={2}>
      <List>
        <ListItem>
          <ListItemText primary={<Typography variant="h6">User Role Key</Typography>} />
        </ListItem>
        {roles.map((role) => (
          <React.Fragment key={role.identifier}>
            <Divider component="li" />
            <ListItem alignItems="flex-start">
              <ListItemText primary={role.identifier} secondary={role.description} />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default RoleKeyList;
