import * as React from 'react';
import User from './User';
import Organisation from './Organisation';
import Role from './Role';
import UsersTable, { UserUpdate } from './UsersTable';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RoleKeyList from './RoleKeyList';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    roleKeys: {
      marginLeft: theme.spacing(2),
    },
  })
);

const UserManagement = () => {
  const [isDirty, setIsDirty] = React.useState(true);
  const [users, setUsers] = React.useState(new Array<User>());
  const [roles, setRoles] = React.useState(new Array<Role>());
  const [organisations, setOrganisations] = React.useState(new Array<Organisation>());

  const classes = useStyles();

  React.useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get<User[]>('/internalapi/admin/usermanagement/users');
      if (response.status === 200) {
        setUsers(response.data);
      }
    };

    const getRoles = async () => {
      const response = await axios.get<Role[]>('/internalapi/admin/usermanagement/roles');
      if (response.status === 200) {
        setRoles(response.data);
      }
    };

    const getOrganisations = async () => {
      const response = await axios.get<Organisation[]>(
        '/internalapi/admin/usermanagement/organisations'
      );
      if (response.status === 200) {
        setOrganisations(response.data);
      }
    };

    if (isDirty) {
      getUsers();
      getOrganisations();
      getRoles();
      setIsDirty(false);
    }
  }, [isDirty]);

  const updateUser = async (user: UserUpdate): Promise<void> => {
    const { userRoleIdentifier, organisationIdentifier, isEnabled } = user;
    const body = { userRoleIdentifier, organisationIdentifier, isEnabled };

    const response = await fetch(`/internalapi/admin/usermanagement/user/${user.identifier}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
      },
    });
    if (response.ok) {
      setIsDirty(true);
      return Promise.resolve();
    }

    return Promise.reject();
  };

  return (
    <div className={classes.root}>
      <div>
        <UsersTable
          users={users}
          roles={roles}
          organisations={organisations}
          updateUser={updateUser}
        />
      </div>
      <div className={classes.roleKeys}>
        <RoleKeyList roles={roles} />
      </div>
    </div>
  );
};

export default UserManagement;
