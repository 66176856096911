import { IItem } from '../Definitions/IItem';
import Enumerable from 'linq';
import { splitString } from '../StringSplitter';
import { observable, action, reaction, computed, makeObservable } from 'mobx';
import { otherValueIdentifier } from './Controls/Other.Control';
import Bind from './Bind';

const valueSplitChar = ' ';
export const parseSelectValue = (value: string): string[] => splitString(value, valueSplitChar);

export default class Itemset {
  private readonly bind: Bind;

  constructor(bind: Bind, private readonly items: IItem[]) {
    makeObservable<Itemset, 'bind' | 'deselectValuesNotInItemset'>(this, {
      bind: observable,
      deselectValuesNotInItemset: action,
      hasStopConditionFired: computed,
      getFiredStopConditionText: computed,
    });

    this.bind = bind;
    this.bind.displayNameResolver = this.displayNameForValue.bind(this);
    this.setDisplayNameForDefaultValues();

    reaction(
      () => this.bind.filterGroups,
      () => this.deselectValuesNotInItemset(),
      {
        name: 'Reacting to filterGroups change for control ' + this.bind.id,
        fireImmediately: false,
      }
    );

    reaction(
      () => this.bind.value,
      () => {
        const displayName = this.displayNameForValue(this.bind.value);
        if (displayName !== this.bind.displayName) {
          this.bind.updateValue(this.bind.value);
        }
      },
      {
        name: 'Reacting to value change for control ' + this.bind.id,
        fireImmediately: false,
      }
    );
  }

  private setDisplayNameForDefaultValues() {
    if (this.bind.value !== '' && this.bind.displayName === '') {
      this.bind.updateValue(this.bind.value);
    }
  }

  public itemsForSelection(): IItem[] {
    return Enumerable.from(this.items)
      .where((item) => this.itemIsAvailableForSelection(item))
      .toArray();
  }

  private itemIsAvailableForSelection(item: IItem): boolean {
    if (this.itemIsDeprecatedAndSelected(item) === true) {
      return true;
    }

    if (item.deprecated === true) {
      return false;
    }

    if (item.filterGroups.length === 0) {
      return true;
    }

    if (this.bind.filterGroups === null) {
      return true;
    }

    return Enumerable.from(item.filterGroups).intersect(this.bind.filterGroups).any();
  }

  private itemIsDeprecatedAndSelected(item: IItem): boolean {
    return item.deprecated === true && Enumerable.from(this.selectedItems).contains(item.value);
  }

  public isValueSelected(value: string) {
    return this.selectedItems.some((item) => item === value);
  }

  private isExclusive(value: string): boolean {
    return this.items.some((item) => item.value === value && item.isExclusive === true);
  }

  public get otherOptionIsSelected(): boolean {
    return parseSelectValue(this.bind.value).some((value) => value === otherValueIdentifier);
  }

  private deselectValuesNotInItemset() {
    const currentSelectedItems: string[] = this.selectedItems;

    this.selectedItems = Enumerable.from(this.itemsForSelection())
      .join(
        currentSelectedItems,
        (possibleOption) => possibleOption.value,
        (selectedOption) => selectedOption,
        (_, selectedOption) => selectedOption
      )
      .toArray();
  }

  get selectedItems(): string[] {
    return parseSelectValue(this.bind.value);
  }

  set selectedItems(items: string[]) {
    if (items.length === 0) {
      this.bind.clearValue();
      return;
    }

    const newlyAddedItem = items[items.length - 1];

    if (this.isExclusive(newlyAddedItem)) {
      this.bind.updateValue(this.valueForSelection(newlyAddedItem));
      if (this.otherOptionIsSelected === false) {
        this.bind.updateOtherValue('');
      }
    } else {
      const nonExclusiveItems = items.filter(
        (selectedItem) => this.isExclusive(selectedItem) === false
      );

      this.bind.updateValue(this.valueForSelection(...nonExclusiveItems));
      if (this.otherOptionIsSelected === false) {
        this.bind.updateOtherValue('');
      }
    }
  }

  private getStopConditionText(value: string): string {
    const item = this.items.filter((item) => item.value === value)[0];

    if (item && item.stopConditionText) {
      return item.stopConditionText;
    }

    return '<p class="card-text">The answer you have selected suggests that you may require more urgent attention.</p><p class="card-text">Please contact your GP directly or consider calling 111 (or 999 in an emergency).</p>';
  }

  get hasStopConditionFired(): boolean {
    return this.selectedItems.some((item) => this.isStopCondition(item));
  }

  get getFiredStopConditionText(): string | undefined {
    const item = this.selectedItems.find((item) => this.isStopCondition(item));
    return item ? this.getStopConditionText(item) : undefined;
  }

  private isStopCondition(value: string): boolean {
    return this.items.some((item) => item.value === value && item.isStopCondition === true);
  }

  private valueForSelection(...selected: string[]): string {
    return selected.join(valueSplitChar);
  }

  private displayNameForValue(...value: string[]): string {
    const valueToSplit = this.valueForSelection(...value);

    return Enumerable.from(parseSelectValue(valueToSplit))
      .join(
        this.items,
        (valueItem) => valueItem,
        (item) => item.value,
        (_, item) => item.displayName
      )
      .toJoinedString('~~');
  }
}
