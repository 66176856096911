import * as React from 'react';
import { Card, Typography, Button, Box, CardContent } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { performPasswordReset } from '../../Services/Auth.Service';
import { useForm } from 'react-hook-form';
import TextInput from '../../Components/TextInput/TextInput';
import { sortResponseErrors } from '../Account';

interface ResetPasswordFormValues {
  password: string;
  confirmedPassword: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<ResetPasswordFormValues>();
  const [formSubmitted, setFormSubmitted] = React.useState<Boolean>(false);
  const query = useQuery();

  const onSubmit = async ({ password, confirmedPassword }: ResetPasswordFormValues) => {
    const email = query.get('email');
    const code = query.get('code');

    if (email !== null && code !== null) {
      const response = await performPasswordReset({ email, password, confirmedPassword, code });

      if (response === true) {
        setFormSubmitted(true);
      } else {
        const sortedErrors = sortResponseErrors(Object.entries(response));
        sortedErrors.forEach((error) => {
          // List of errors found at https://github.com/aspnet/Identity/blob/master/src/Core/Resources.resx
          if (error.type === 'PasswordMismatch' || error.type === 'ConfirmPassword') {
            setError('confirmedPassword', {
              type: 'server',
              message: error.message,
            });
          } else {
            setError('password', {
              type: 'server',
              message: error.message,
            });
          }
        });
      }
    }
  };

  if (formSubmitted === false) {
    return (
      <Card>
        <Typography variant="h3" component="h1">
          Reset your password
        </Typography>

        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            error={errors.password !== undefined}
            helperText={errors.password?.message}
            required
            id="password"
            {...register('password', { required: true })}
            label="Password"
            type="password"
          />

          <TextInput
            error={errors.confirmedPassword !== undefined}
            helperText={errors.confirmedPassword?.message}
            required
            id="confirmedPassword"
            {...register('confirmedPassword', { required: true })}
            label="Confirm Password"
            type="password"
          />

          <Button variant="contained" color="primary" type="submit">
            Reset Password
          </Button>
        </form>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Typography variant="h3" component="h1">
            Your password has been reset
          </Typography>

          <Box textAlign="center">
            <div>
              <Link to="/auth/login">Back to login</Link>
            </div>
          </Box>
        </CardContent>
      </Card>
    );
  }
};
