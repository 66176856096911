import * as React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { login } from '../../Services/Auth.Service';
import { useForm } from 'react-hook-form';
import TextInput from '../../Components/TextInput/TextInput';
import { useHistory } from 'react-router-dom';
import { sortResponseErrors } from '../Account';
import { useApplicationContext } from '../../AppContext/AppContext';

interface FormValues {
  email: string;
  password: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Login() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const history = useHistory();
  const query = useQuery();
  const { reloadContext } = useApplicationContext();

  const onSubmit = async ({ email, password }: FormValues) => {
    const response = await login({ email, password });

    if (response === true) {
      await reloadContext();
      const returnUrl = query.get('returnurl');
      history.push(returnUrl !== null ? returnUrl : '/formbuilding/buildingblocks');
    } else {
      const sortedErrors = sortResponseErrors(Object.entries(response));
      if (sortedErrors.length > 0) {
        setError('email', {
          type: 'server',
          message: sortedErrors[0].message,
        });
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" align="center">
          Login
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            error={errors.email !== undefined}
            helperText={errors.email?.message}
            id="email"
            required
            {...register('email', { required: true })}
            label="Email address"
            type="email"
          />

          <TextInput
            id="password"
            required
            {...register('password', { required: true })}
            label="Password"
            type="password"
          />

          <Box textAlign="right">
            <Button variant="contained" color="primary" type="submit">
              Log in
            </Button>
          </Box>
        </form>

        <Box textAlign="center">
          <div>
            <Link to="/auth/forgotpassword">Forgot your password?</Link>
          </div>

          <div>
            <Link to="/auth/register">Don't have an account?</Link>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
}
