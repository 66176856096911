import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  spinner: {
    width: '200px',
    height: '200px',
    display: 'inline-block',
    overflow: 'hidden',
    background: '#ffffff',
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  spinnerInner: {
    width: '100%',
    height: '100%',
    position: 'relative',
    transform: 'translateZ(0) scale(1)',
    backfaceVisibility: 'hidden',
    transformOrigin: '0 0',
    '& div': {
      left: '94px',
      top: '48px',
      position: 'absolute',
      animation: '$blink linear 1s infinite',
      background: '#5A6872',
      width: '12px',
      height: '24px',
      borderRadius: '6px / 12px',
      transformOrigin: '6px 52px',
      boxSizing: 'content-box',
    },
    '& div:nth-child(1)': {
      transform: 'rotate(0deg)',
      animationDelay: '-0.9166666666666666s',
      background: '#5A6872',
    },
    '& div:nth-child(2)': {
      transform: 'rotate(30deg)',
      animationDelay: '-0.8333333333333334s',
      background: '#5A6872',
    },
    '& div:nth-child(3)': {
      transform: 'rotate(60deg)',
      animationDelay: '-0.75s',
      background: '#5A6872',
    },
    '& div:nth-child(4)': {
      transform: 'rotate(90deg)',
      animationDelay: '-0.6666666666666666s',
      background: '#5A6872',
    },
    '& div:nth-child(5)': {
      transform: 'rotate(120deg)',
      animationDelay: '-0.5833333333333334s',
      background: '#5A6872',
    },
    '& div:nth-child(6)': {
      transform: 'rotate(150deg)',
      animationDelay: '-0.5s',
      background: '#5A6872',
    },
    '& div:nth-child(7)': {
      transform: 'rotate(180deg)',
      animationDelay: '-0.4166666666666667s',
      background: '#5A6872',
    },
    '& div:nth-child(8)': {
      transform: 'rotate(210deg)',
      animationDelay: '-0.3333333333333333s',
      background: '#5A6872',
    },
    '& div:nth-child(9)': {
      transform: 'rotate(240deg)',
      animationDelay: '-0.25s',
      background: '#5A6872',
    },
    '& div:nth-child(10)': {
      transform: 'rotate(270deg)',
      animationDelay: '-0.16666666666666666s',
      background: '#5A6872',
    },
    '& div:nth-child(11)': {
      transform: 'rotate(300deg)',
      animationDelay: '-0.08333333333333333s',
      background: '#5A6872',
    },
    '& div:nth-child(12)': {
      transform: 'rotate(330deg)',
      animationDelay: '0s',
      background: '#5A6872',
    },
  },
});

const Spinner = () => {
  const { spinner, spinnerInner } = useStyles();

  return (
    <div className={spinner}>
      <div className={spinnerInner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
