import { ICustomSuccessAction } from '../Definitions/ICustomSuccessAction';
import { IDefaultSuccessAction } from '../Definitions/IDefaultSuccessAction';
import NavigateParams from './NavigateParams';
import SubmissionResponse from './SubmissionResponse';

export interface ActionResult {
  message?: string;
  redirectUrl: string | null;
  isAutoRedirect?: boolean;
  execute: () => void;
}

export default class SuccessActionResolver {
  constructor(
    private readonly successAction: ICustomSuccessAction | IDefaultSuccessAction,
    private readonly navigateParams: NavigateParams,
    private readonly evaluateXPathToBoolean: (xpath: string) => Boolean
  ) {}

  public resolveCloseSuccessAction(): ActionResult {
    if (this.navigateParams.onClose) {
      const onCloseLocation = this.navigateParams.onClose;
      return {
        execute: () => (window.location.href = onCloseLocation),
        redirectUrl: onCloseLocation,
      };
    }
    return {
      execute: () => {},
      redirectUrl: null,
    };
  }

  public resolveSaveDraftSuccessAction(): ActionResult {
    if (this.navigateParams.onSaveDraft) {
      const onSaveDraftLocation = this.navigateParams.onSaveDraft;
      return {
        execute: () => (window.location.href = onSaveDraftLocation),
        redirectUrl: onSaveDraftLocation,
      };
    }
    return {
      execute: () => {},
      redirectUrl: null,
    };
  }

  public resolveDiscardSuccessAction(): ActionResult {
    if (this.navigateParams.onDiscard) {
      const onDiscardLocation = this.navigateParams.onDiscard;
      return {
        execute: () => (window.location.href = onDiscardLocation),
        redirectUrl: onDiscardLocation,
      };
    }
    return {
      execute: () => {},
      redirectUrl: null,
    };
  }

  public resolveSubmissionSuccessAction(
    submissionResponse: SubmissionResponse | null
  ): ActionResult {
    if (this.successAction.type === 'Custom') {
      const customSuccessAction = this.successAction;
      const redirectUrl = this.getRedirectUri(customSuccessAction);

      if (this.successAction.message) {
        let textToShow: string;

        if (
          this.successAction.message.displaySubmissionResultMessage &&
          submissionResponse?.message
        ) {
          textToShow = submissionResponse.message.replace('\n', '<br />');
        } else {
          textToShow = this.successAction.message.text;
        }

        let executeFunc: () => void = () => {};
        if (customSuccessAction.autoRedirectAfterMs != null) {
          executeFunc = () =>
            setTimeout(
              () => (window.location.href = redirectUrl),
              customSuccessAction.autoRedirectAfterMs!
            );
        }

        return {
          message: textToShow,
          isAutoRedirect: customSuccessAction.autoRedirectAfterMs != null,
          execute: executeFunc,
          redirectUrl,
        };
      } else {
        return {
          execute: () => (window.location.href = redirectUrl),
          redirectUrl,
        };
      }
    }

    const defaultAction: ActionResult = {
      message: submissionResponse?.message?.replace('\n', '<br />') || this.successAction.message,
      isAutoRedirect: false,
      execute: () => {},
      redirectUrl: null,
    };

    if (this.navigateParams.onSubmit) {
      const onSubmitLocation = this.navigateParams.onSubmit;
      defaultAction.execute = () => (window.location.href = onSubmitLocation);
      defaultAction.redirectUrl = onSubmitLocation;
    }

    return defaultAction;
  }

  private getRedirectUri = (customSuccessAction: ICustomSuccessAction): string => {
    if (customSuccessAction.uriRedirectOptions) {
      for (const option of customSuccessAction.uriRedirectOptions) {
        if (this.evaluateXPathToBoolean(option.xPath)) return urlCleaner(option.uri);
      }
    }
    return urlCleaner(customSuccessAction.defaultUri);
  };
}

//This basically allows urls specified without http to work. Eventually this would be validated in the UI config.
export const urlCleaner = (url: string) => {
  if (url.startsWith('/')) {
    return url;
  }

  return url.includes('http') ? url : 'http://' + url;
};
