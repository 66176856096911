import { getLuminance, hexToRgb } from '@material-ui/core/styles/colorManipulator';

export function colorIsDark(colorHex: string) {
  if (colorHex.charAt(0) !== '#') {
    return false;
  }

  const luminance = getLuminance(hexToRgb(colorHex));
  return luminance < 0.179;
}

export function getContrastingColor(colorHex: string) {
  return colorIsDark(colorHex) ? '#ffffff' : '#000000';
}
