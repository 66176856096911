import LoginPage from './Auth/Page';
import RegisterPage from './Register/Page';
import ForgotPasswordPage from './ForgotPassword/Page';
import ResetPasswordPage from './ResetPassword/Page';
import { Route, useRouteMatch } from 'react-router';
import { Container } from '@material-ui/core';
import Switch from '../ErrorHandlingSwitch';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Container maxWidth="sm">
      <Switch>
        <Route path={`${match.path}/login`} component={LoginPage} />
        <Route path={`${match.path}/register`} component={RegisterPage} />
        <Route path={`${match.path}/forgotpassword`} component={ForgotPasswordPage} />
        <Route path={`${match.path}/resetpassword`} component={ResetPasswordPage} />
      </Switch>
    </Container>
  );
};

export default Routing;
