import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headerText: {
      color: '#33444E',
      fontSize: 30,
    },
    formInput: {
      margin: theme.spacing(3),
    },
    surface: {
      background: '#EDF0F2',
      padding: theme.spacing(2),
    },
    itemPanel: {
      background: '#EDF0F2',
      padding: theme.spacing(2),
      height: '100%',
    },
    outerGrid: {
      margin: 0,
      marginBottom: theme.spacing(2),
      flex: 1,
    },
    tabsHolder: {
      flexGrow: 1,
      backgroundColor: 'red',
      display: 'flex',
    },
    tabs: {},
    addButton: {
      color: '#3463FD',
    },
  })
);
