import { ITypeDefinition } from './TypeDefinitionFactory';

export default class BooleanDefinition implements ITypeDefinition {
  private static regex: RegExp = /^(true|false|0|1)?$/;

  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return BooleanDefinition.regex.test(value);
  }
}
