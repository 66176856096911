import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme: Theme) => ({
  generalIcon: {
    color: '#889AA5',
  },
}));

const InputTooltip = (
  props: Omit<TooltipProps, 'children'> & Partial<Pick<TooltipProps, 'children'>>
) => {
  const classes = useStyles();

  if (props?.children) {
    return (
      <Tooltip {...props}>
        {React.cloneElement(props.children, { className: classes.generalIcon })}
      </Tooltip>
    );
  } else {
    return (
      <Tooltip {...props}>
        <HelpIcon className={classes.generalIcon}></HelpIcon>
      </Tooltip>
    );
  }
};

export default InputTooltip;
