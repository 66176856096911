import { observable, action, makeObservable } from 'mobx';
import Bind from './Bind';

export default class BindStore {
  private readonly _bindsById = new Map<string, Bind[]>();

  private readonly _bindsByParentId = new Map<string, Bind[]>();

  constructor() {
    makeObservable<BindStore, '_bindsById' | '_bindsByParentId'>(this, {
      _bindsById: observable,
      _bindsByParentId: observable,
      addBind: action,
      addBinds: action,
      removeBindAndChildren: action,
      getChildBinds: observable,
    });
  }

  public addBind(bind: Bind) {
    const existing = this._bindsById.get(bind.id);

    if (existing) {
      existing.push(bind);
    } else {
      this._bindsById.set(bind.id, [bind]);
    }

    if (bind.parentBindId) {
      const existingByParent = this._bindsByParentId.get(bind.parentBindId);
      if (existingByParent) {
        existingByParent.push(bind);
      } else {
        this._bindsByParentId.set(bind.parentBindId, [bind]);
      }
    }
  }

  public addBinds(binds: Bind[]) {
    for (const bind of binds) {
      this.addBind(bind);
    }
  }

  public removeBindAndChildren(bind: Bind) {
    for (const childBind of bind.childBinds) {
      this.removeBindAndChildren(childBind);
    }

    bind.disposeReactions();
    const binds = this._bindsById.get(bind.id);
    if (binds) {
      const index = binds.indexOf(bind);
      binds.splice(index, 1);

      if (binds.length === 0) {
        this._bindsById.delete(bind.id);
      }
    }

    if (bind.parentBindId) {
      const bindsByParent = this._bindsByParentId.get(bind.parentBindId);

      if (bindsByParent) {
        const index = bindsByParent.indexOf(bind);
        bindsByParent.splice(index, 1);
      }
    }
  }

  //Returns the original bind + repeat iteration binds, used for "referenced binds"
  public getBindsById(id: string): Bind[] {
    const binds = this._bindsById.get(id);
    if (binds) {
      return binds;
    }
    return [];
  }

  public getBindById(id: string, repeatIterationId?: string): Bind {
    const binds = this._bindsById.get(id);
    if (binds) {
      if (repeatIterationId) {
        const repeatIterationBind = binds.find(
          (bind) => bind.repeatIterationId === repeatIterationId
        );
        if (repeatIterationBind) {
          return repeatIterationBind;
        }
        //This is a bit of a fudge, but its to allow iteration binds to find the root repeat.
        const nonRepeatIterationBind = binds.find((bind) => bind.repeatIterationId === undefined);
        if (nonRepeatIterationBind) {
          return nonRepeatIterationBind;
        }
      } else {
        const nonRepeatIterationBind = binds.find((bind) => bind.repeatIterationId === undefined);
        if (nonRepeatIterationBind) {
          return nonRepeatIterationBind;
        }
      }
    }

    throw Error('Unable to find bind with id ' + id);
  }

  public getChildBinds(parentId: string, repeatIterationId?: string): Bind[] {
    const binds = this._bindsByParentId.get(parentId);

    if (binds) {
      if (repeatIterationId) {
        return binds.filter((bind) => bind.repeatIterationId === repeatIterationId);
      }

      return binds;
    }
    return [];
  }
}
