import Enumerable from 'linq';
import Page from '../Components/Page';
import BuildingBlock from '../Components/BuildingBlock';
import { IPage } from '../Definitions/IPage';

export const convertPages = (
  buildingBlocks: Enumerable.IDictionary<string, BuildingBlock>,
  pages: IPage[]
): Page[] =>
  Enumerable.from(pages)
    .select(
      (page) =>
        new Page(
          page.title,
          page.buildingBlocks.map((block) => buildingBlocks.get(block.key))
        )
    )
    .toArray();
