import { useFormContext, useFieldArray } from 'react-hook-form';
import {
  BuildingBlockVersionData,
  createEmptyBuildingBlock,
  FormSchema,
} from '../FormBuildingInterfaces';
import BuildingBlock from '../BuildingBlockEdit/BuildingBlockEdit';
import { Paper, Box, Button, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './PageEdit.styles';
import { useCallback } from 'react';

interface Props {
  index: number;
  buildingBlocksData: BuildingBlockVersionData[];
}

const PageEdit = ({ index, buildingBlocksData }: Props) => {
  const classes = useStyles();
  const { control } = useFormContext<FormSchema>();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `pages.${index}.buildingBlocks`,
  });

  const onAppendBuildingBlock = () => {
    append(createEmptyBuildingBlock());
  };

  const onMoveBuildingBlockUp = useCallback(
    (index: number) => {
      if (fields.length > 1 && index > 0) {
        move(index, index - 1);
      }
    },
    [fields.length, move]
  );

  const onMoveBuildingBlockDown = useCallback(
    (index: number) => {
      if (fields.length > 1 && index < fields.length - 1) {
        move(index, index + 1);
      }
    },
    [fields.length, move]
  );

  const onRemoveBuildingBlock = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  return (
    <Paper elevation={3}>
      <Box display="flex" flexDirection="row" className={classes.paperHeader}>
        <Typography variant="h5" component="h2" className={classes.headerText}>
          Building Blocks
        </Typography>
      </Box>
      <Paper elevation={0} className={classes.scrollingPaper}>
        <ol className={classes.blockList} aria-label="Building blocks">
          {fields.map((item, innerIndex) => {
            return (
              <li key={item.id}>
                <BuildingBlock
                  index={index}
                  innerIndex={innerIndex}
                  isLastBB={fields.length === 1}
                  buildingBlocksData={buildingBlocksData}
                  onMoveDown={onMoveBuildingBlockDown}
                  onMoveUp={onMoveBuildingBlockUp}
                  onRemove={onRemoveBuildingBlock}
                />
              </li>
            );
          })}
        </ol>
      </Paper>
      <Box className={classes.addBBPaper}>
        <Button
          startIcon={<AddCircleIcon />}
          onClick={onAppendBuildingBlock}
          className={classes.addButton}
        >
          Add Building Block
        </Button>
      </Box>
    </Paper>
  );
};

export default PageEdit;
