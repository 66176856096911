import Form from '../../Components/Form';
import { DocumentLayout } from '../../Definitions/IFormDefinition';
import { IPatient } from '../../Definitions/IPatient';
import { ViewMode } from '../../Definitions/ViewMode';
import PersistenceHandler from '../../Persistence/PersistenceHandler';
import QueryConfigParams from './QueryConfigParams';

interface IConfiguration {
  viewMode: ViewMode;
  layoutSettings: DocumentLayout;
}

export default class FormContext {
  private readonly queryConfig: QueryConfigParams = new QueryConfigParams(window.location.search);

  constructor(
    private readonly configuration: IConfiguration,
    public readonly peristenceHandler: PersistenceHandler,
    public readonly form: Form
  ) {}

  public get hideBanner(): boolean {
    return this.configuration.layoutSettings.hideBanner || this.queryConfig.hideBanner;
  }

  public get hideFooter(): boolean {
    return this.configuration.viewMode === 'read' && this.queryConfig.hideFooter;
  }

  public get hideNavigation(): boolean {
    return this.queryConfig.hideNavigation;
  }

  public get patient(): IPatient {
    return this.configuration.layoutSettings.patient;
  }
}
