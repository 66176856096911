import { ITypeDefinition } from './TypeDefinitionFactory';
import moment from 'moment';

export const Format = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export default class DateTimeDefinition implements ITypeDefinition {
  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return value === '' || moment(value, Format, true).isValid();
  }
}
