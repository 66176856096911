import { useFormContext, useWatch, Controller } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Paper, TextField } from '@material-ui/core';
import DeleteIcon from '../../../../Components/IconButtons/DeleteIcon';
import UpIcon from '../../../../Components/IconButtons/UpIcon';
import DownIcon from '../../../../Components/IconButtons/DownIcon';
import TextInput from '../../../../Components/TextInput/TextInput';
import SelectInput from '../../../../Components/SelectInput/SelectInput';
import DropdownArrow from '../../../../Components/IconButtons/DropdownArrow';
import ConfirmationDialog from '../../../../Components/Dialogs/ConfirmationDialog';
import { useStyles } from './BuildingBlockEdit.styles';
import {
  FormSchema,
  BuildingBlockVersionData,
  BuildingBlockSchema,
} from '../FormBuildingInterfaces';
import { FlexRow, FlexColumn, Row, Column } from '../../../../Components/FlexBoxes/FlexBoxes';
import { When } from '../../../../Components/When/When';
import UpdateIcon from '@material-ui/icons/Update';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  index: number;
  innerIndex: number;
  buildingBlocksData: BuildingBlockVersionData[];
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  isLastBB: boolean;
}

export const BuildingBlockEdit: React.FC<Props> = ({
  index,
  innerIndex,
  buildingBlocksData,
  onMoveUp,
  onMoveDown,
  onRemove,
  isLastBB,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onRemove(innerIndex);
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const formTypeWatch = useWatch({
    control,
    name: 'metadata.layout',
  });

  const relevanceRuleWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.relevance`,
  });

  const buildingBlockKeyWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.key`,
  });

  const buildingBlockVersionWatch = useWatch({
    control,
    name: `pages.${index}.buildingBlocks.${innerIndex}.version`,
  });

  const getVersionsByKey = useCallback(
    (key: string) => {
      return buildingBlocksData?.find((x) => x.key === key)?.versions ?? [];
    },
    [buildingBlocksData]
  );

  const [availableBlockVersions, setAvailableBlockVersions] = React.useState<number[]>(
    getVersionsByKey(buildingBlockKeyWatch)
  );

  const [previousKey, setPreviousKey] = React.useState(buildingBlockKeyWatch);
  const [keyHasChanged, setKeyHasChanged] = React.useState(false);

  useEffect(() => {
    if (buildingBlockKeyWatch !== previousKey) {
      setPreviousKey(buildingBlockKeyWatch);
      setKeyHasChanged(true);
    }
    setAvailableBlockVersions(getVersionsByKey(buildingBlockKeyWatch));
  }, [buildingBlockKeyWatch, getVersionsByKey, previousKey]);

  useEffect(() => {
    if (keyHasChanged) {
      setValue(
        `pages.${index}.buildingBlocks.${innerIndex}.version`,
        Math.max(...availableBlockVersions)
      );
    }
  }, [availableBlockVersions, index, innerIndex, keyHasChanged, setValue]);

  const getError = (key: keyof BuildingBlockSchema) => {
    return errors?.pages?.[index]?.buildingBlocks?.[innerIndex]?.[key];
  };

  const hasErrors = () => {
    return errors?.pages?.[index]?.buildingBlocks?.[innerIndex] !== undefined;
  };

  const hasCollapsedErrors = () => {
    const collapsedErrors =
      getError('customRule') !== undefined ||
      getError('pdfReadVisibility') !== undefined ||
      getError('prePopulationRule') !== undefined ||
      getError('relevance') !== undefined ||
      getError('view') !== undefined;
    if (collapsedErrors && !expanded) setExpanded(true);
    return collapsedErrors;
  };

  return (
    <Paper
      elevation={2}
      data-testid="block"
      className={!hasErrors() ? classes.paper : classes.blockError}
    >
      <FlexRow padding="10px">
        <Column height="100%" minWidth="50px" margin="0 0 auto 0">
          <DropdownArrow
            expanded={expanded}
            handleChange={setExpanded}
            arialabel={'this building block'}
            tooltipText="configuration options"
          />
        </Column>
        <FlexColumn margin="auto auto auto 10px">
          <Controller
            control={control}
            name={`pages.${index}.buildingBlocks.${innerIndex}.key`}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(e, data) => {
                  field.onChange(data);
                }}
                clearOnBlur
                handleHomeEndKeys
                id={`pages.${index}.buildingBlocks.${innerIndex}.key`}
                options={buildingBlocksData.map((item) => item.key)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Building block"
                    error={getError('key') !== undefined}
                    helperText={getError('key')?.message}
                    size="small"
                    fullWidth={true}
                    required
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </FlexColumn>
        <When condition={!!buildingBlockKeyWatch}>
          <Column height="100%" width="200px" margin="0 10px auto 10px">
            <SelectInput
              className={classes.formInput}
              required
              id={`pages.${index}.buildingBlocks.${innerIndex}.version`}
              {...register(`pages.${index}.buildingBlocks.${innerIndex}.version`, {
                valueAsNumber: true,
              })}
              label="Version"
              tooltip={
                availableBlockVersions.length > buildingBlockVersionWatch
                  ? 'A newer version of this block is available'
                  : ''
              }
              adornmentIcon={<UpdateIcon />}
            >
              {availableBlockVersions
                .slice()
                .reverse()
                .map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
            </SelectInput>
          </Column>
        </When>

        <Column height="100%" margin="0 0px auto 0px">
          <Row height="100%" justifyContent="flex-end" margin="auto 0px auto 0px">
            <UpIcon
              aria-label="move block up in order"
              tooltipText="Move block up in order"
              onClick={() => onMoveUp(innerIndex)}
            />
            <DownIcon
              aria-label="move block down in order"
              tooltipText="Move block down in order"
              onClick={() => onMoveDown(innerIndex)}
            />
            <DeleteIcon
              disabled={isLastBB}
              aria-label="remove building block from page"
              tooltipText="Remove building block from page"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
            />
          </Row>
        </Column>
      </FlexRow>

      <When condition={expanded || hasCollapsedErrors()}>
        <FlexRow padding="10px">
          <FlexColumn margin="auto 0 auto 0">
            <SelectInput
              className={classes.formInput}
              required
              {...register(`pages.${index}.buildingBlocks.${innerIndex}.view`)}
              label="View"
              tooltip={"Blocks with an 'inherit' view will share the view mode of the form."}
            >
              <option value="inherit">Inherit</option>
              <option value="edit">Edit</option>
              <option value="read">Read</option>
            </SelectInput>
          </FlexColumn>
          <When condition={formTypeWatch === 'clinician'}>
            <FlexColumn margin="auto auto auto 10px">
              <TextInput
                className={classes.formInput}
                error={getError('prePopulationRule') !== undefined}
                helperText={getError('prePopulationRule')?.message}
                {...register(`pages.${index}.buildingBlocks.${innerIndex}.prePopulationRule`)}
                label="Pre-population rule"
                type="text"
                tooltip={
                  'A numerical value in days or a string value to provide a custom date via the plugin to pre-populate data from.'
                }
              />
            </FlexColumn>
          </When>
        </FlexRow>
        <FlexRow padding="10px">
          <FlexColumn margin="auto 0 auto 0">
            <SelectInput
              className={classes.formInput}
              required
              {...register(`pages.${index}.buildingBlocks.${innerIndex}.pdfReadVisibility`)}
              label="Read view visibility"
            >
              <option value="alwaysvisible">Always visible</option>
              <option value="hidefrompdf">Hide from PDF</option>
              <option value="hidefromreadandpdf">Hide from read and PDF</option>
            </SelectInput>
          </FlexColumn>

          <FlexColumn margin="auto auto auto 10px">
            <SelectInput
              className={classes.formInput}
              required
              {...register(`pages.${index}.buildingBlocks.${innerIndex}.relevance`)}
              label="Relevance"
            >
              <option value="alwaysdisplay">Always display</option>
              <option value="customrule">Custom rule</option>
            </SelectInput>
          </FlexColumn>
        </FlexRow>
        <When condition={relevanceRuleWatch === 'customrule'}>
          <FlexRow padding="10px">
            <FlexColumn margin="auto 0 auto 0">
              <TextInput
                className={classes.formInput}
                error={getError('customRule') !== undefined}
                helperText={getError('customRule')?.message}
                {...register(`pages.${index}.buildingBlocks.${innerIndex}.customRule`)}
                required
                label="Custom rule"
                type="text"
                multiline={true}
                tooltip={'The XPath rule governing when this building block should appear.'}
              />
            </FlexColumn>
          </FlexRow>
        </When>
      </When>
      <ConfirmationDialog
        confirmationText={
          'Are you sure you want to remove this building block from the page? Any configuration settings you have given it will be lost.'
        }
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </Paper>
  );
};

export default BuildingBlockEdit;
