import Grid from './Grid';
import Repeat from './Repeat';
import Bind from './Bind';
import { computed, makeObservable, action } from 'mobx';
import { ISectionDefinition } from '../Definitions/ISectionDefinition';
import MasterChild from './MasterChild';
import { Highlight } from '../Definitions/Highlight';

export default class Section {
  constructor(
    private readonly definition: ISectionDefinition,
    private readonly bind: Bind,
    public readonly grids: Array<Grid | Repeat | MasterChild>
  ) {
    makeObservable(this, {
      isRelevant: computed,
      isReadOnly: computed,
      isValid: computed,
      validate: action,
    });
  }

  get id(): string {
    return this.definition.id;
  }

  get label(): string {
    return this.definition.label;
  }

  get highlight(): Highlight {
    return this.definition.highlight;
  }

  get isRelevant(): boolean {
    return this.bind.isRelevant;
  }

  get isReadOnly(): boolean {
    return this.bind.isReadOnly;
  }

  get isValid(): boolean {
    return this.bind.isValid;
  }

  get isHiddenFromUi(): boolean {
    return this.definition.isHiddenFromUi;
  }

  validate(): void {
    for (const grid of this.grids) {
      grid.validate();
    }
  }

  get updatedSinceLastAutosave(): boolean {
    return this.grids.some((grid) => grid.updatedSinceLastAutosave);
  }

  get touched(): string[] {
    const controls: string[] = [];

    for (const grid of this.grids) {
      for (const control of grid.touched) {
        controls.push(control);
      }
    }

    return controls;
  }

  markControlsAsNotUpdatedSinceLastAutosave() {
    this.grids.map((grid) => grid.markControlsAsNotUpdatedSinceLastAutosave());
  }
}
