export interface BackendValidationError {
  type: string;
  message: string;
}

export function sortResponseErrors(response: [string, string[]][]) {
  const sortedResponse: BackendValidationError[] = response.reduce(
    (accumulator: BackendValidationError[], currentValue) => {
      const errorType = currentValue[0];
      const errorMessages = currentValue[1];

      const flatErrors = errorMessages.map((errorMessage: any) => {
        return { type: errorType, message: errorMessage };
      });

      return accumulator.concat(flatErrors);
    },
    []
  );

  return sortedResponse;
}
