import Row from './Row';
import Enumerable from 'linq';
import { action, makeObservable } from 'mobx';

export default class Grid {
  constructor(public readonly rows: Row[]) {
    makeObservable(this, {
      validate: action,
    });
  }

  private get allControls() {
    return Enumerable.from(this.rows)
      .selectMany((row) => row.cells)
      .where((cell) => cell.control !== undefined)
      .select((cell) => cell.control!);
  }

  validate(): void {
    this.allControls.forEach((control) => control.validate());
  }

  get updatedSinceLastAutosave(): boolean {
    return this.allControls.any((control) => control.updatedSinceLastAutosave);
  }

  get touched(): string[] {
    return this.allControls
      .where((control) => control.touched)
      .select((control) => control.id)
      .toArray();
  }

  markControlsAsNotUpdatedSinceLastAutosave() {
    this.allControls
      .where((control) => control.updatedSinceLastAutosave)
      .forEach((control) => control.markAsNotUpdatedSinceLastAutosave());
  }
}
