import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      position: 'fixed',
      top: 0,
      height: '100%',
      objectFit: 'cover',
      right: '0%',
      width: '100%',
      zIndex: -1000,
    },
    box: {
      height: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      minWidth: '30em',
      width: '30%',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    warningIcon: {
      width: '40%',
    },
  })
);
