import * as React from 'react';
import { ResetPassword } from './ResetPassword';

export default function ResetPasswordPage() {
  return (
    <>
      <ResetPassword />
    </>
  );
}
