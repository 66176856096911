import React from 'react';
import classNames from 'classnames';
import { createUseFormThemeStyles } from '../../Themes/ThemeProvider';
import { FormTheme } from '../../Themes/FormTheme';
import Container from './Container';

interface IProps {
  siteTitle: string;
  siteLogoUri: string;
}

const useHeaderStyles = createUseFormThemeStyles((style: FormTheme) => ({
  header: {
    padding: 0,
    backgroundColor: style.headerBackgroundColor,
    flexDirection: style.headerFlexDirection,
    display: 'flex',
    minHeight: '5em',
  },
  headerLogo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '5em',
    margin: style.headerMargin,
    color: style.headerTitleTextColor,
  },
  headerBar: {
    height: '0.5rem',
    maxHeight: '0.5rem',
    backgroundColor: style.primaryColor,
  },
  siteTitle: {
    color: style.headerTitleTextColor,
    margin: 0,
    fontSize: '2rem',
    fontFamily: "'Hind', sans-serif",
    '@media screen and (max-width:30em)': {
      fontSize: '1.6rem',
    },
  },
  logoImage: {
    maxHeight: '4em',
    margin: '1em',
  },
}));

const Header: React.FC<IProps> = ({ siteTitle, siteLogoUri }) => {
  const { header, headerBar, headerLogo, siteTitle: siteTitleClass, logoImage } = useHeaderStyles();

  let logo = undefined;

  if (siteLogoUri && siteLogoUri !== '') {
    logo = (
      <img
        className={classNames('img-fluid ml-auto', logoImage)}
        src={siteLogoUri}
        alt={siteTitle}
      />
    );
  }

  return (
    <header className={header} data-testid="header">
      <div className={headerBar} />
      <Container>
        <div className={headerLogo}>
          <div
            className={classNames(siteTitleClass, { 'd-none d-sm-block': siteLogoUri })}
            data-testid="site-title"
          >
            {siteTitle}
          </div>
          {logo}
        </div>
      </Container>
    </header>
  );
};

export default Header;
