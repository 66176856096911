import AireLogicTheme from './AireLogic';
import AireLogicCompactTheme from './AireLogicCompact';
import GDSTheme from './GDS';
import CustomTheme from './Custom';
import { FormTheme } from './FormTheme';
import { ThemeOptions } from '../Definitions/IFormDefinition';

export function loadTheme(themeOptions: ThemeOptions): FormTheme {
  switch (themeOptions.name) {
    case 'Custom':
      return CustomTheme(themeOptions.primaryColor);
    case 'GDS':
      return GDSTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
    case 'DefaultCompact':
      return AireLogicCompactTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
    default:
      return AireLogicTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
  }
}
