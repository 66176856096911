import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box } from '@material-ui/core';
import InputTooltip from '../Tooltips/InputTooltip';
import { When } from '../When/When';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#27C940',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const BaseballCapSwitch = React.forwardRef(
  (props: SwitchProps & { label: string; tooltipText?: string }, ref): React.ReactElement => {
    const classes = useStyles();
    const { label, tooltipText, ...rest } = props;

    return (
      <FormControlLabel
        control={
          <Box display="flex" flexDirection="row" alignItems="center">
            <Switch
              id={props.id ?? props.name}
              focusVisibleClassName={classes.focusVisible}
              disableRipple
              inputRef={ref}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={props.value === true}
              {...rest}
            />
            <When condition={!!tooltipText}>
              <InputTooltip title={tooltipText!}></InputTooltip>
            </When>
          </Box>
        }
        label={label}
      />
    );
  }
);

export default BaseballCapSwitch;
