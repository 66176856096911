import { FormTheme, getTextColor } from './FormTheme';
import { alpha, lighten } from '@material-ui/core/styles';
import { getContrastingColor } from './utils/themeUtils';

const highlightColor = '#00b5fb';
const warningColor = '#ffd9a2';
const dangerColor = '#f3a69e';
const successColor = '#73dd8b';

export default function CustomTheme(primaryColor: string): FormTheme {
  const primaryTextColor = getContrastingColor(primaryColor);

  return {
    name: 'Custom',
    primaryColor: primaryColor,
    primaryTextColor: primaryTextColor,
    headerFlexDirection: 'column',
    headerMargin: '0',
    headerBackgroundColor: '#fff',
    headerTitleTextColor: '#000',
    headerTitleBorder: 'solid #ccc',
    headerTitleBorderWidth: '0 0 0 1px',
    islandTextColor: '#000',
    islandTextSecondaryColor: '#666666',
    islandBackgroundColor: '#F1F1F1',
    islandBorderTop: 'none',
    islandHeaderFontWeight: 'normal',
    stepSpacingMargin: '0 0 1em 0',
    explanatoryTextPadding: '1em',
    explanatoryTextMargin: 0,
    explanatoryTextBorder: 'none',
    explanatoryTextBorderWidth: 0,
    highlightBackgroundColor: highlightColor,
    highlightTextColor: '#000',
    highlightBorderColor: highlightColor,
    warningBackgroundColor: warningColor,
    warningTextColor: '#000',
    warningBorderColor: warningColor,
    dangerBackgroundColor: dangerColor,
    dangerTextColor: '#000',
    dangerBorderColor: dangerColor,
    successBackgroundColor: successColor,
    successTextColor: '#000',
    successBorderColor: successColor,
    badgeBorderRadius: '0.25rem',
    badgeSuccessBackgroundColor: '#E0E0E0',
    badgeSuccessTextColor: '#333',
    badgeDangerBackgroundColor: '#dc3545',
    badgeDangerTextColor: '#fff',
    formGroupMargin: '0 0 1em',
    formGroupPadding: 0,
    formGroupInvalidBorder: 'none',
    formGroupInvalidBorderWidth: 0,
    formGroupInvalidPadding: 0,
    formGroupInvalidLeft: 0,
    formControlBorderRadius: '0.25rem',
    formControlBorderWidth: '1px',
    formControlBorderColor: '#ced4da',
    formControlFocusedOutline: 'none',
    formControlFocusedOutlineOffset: 0,
    formControlFocusedBorderColor: alpha(primaryColor, 0.2),
    formControlFocusedBoxShadow: `0 0 0 0.2rem ${alpha(primaryColor, 0.4)}`,
    formControlInvalidBorder: 'solid #ced4da',
    formControlInvalidBorderWidth: '1px',
    selectOptionDisabledBackgroundColor: '#ced4da',
    selectOptionDisabledTextColor: '#000',
    selectOptionSelectedBackgroundColor: primaryColor,
    selectOptionSelectedTextColor: getTextColor(primaryColor, primaryTextColor),
    selectOptionFocusedBackgroundColor: lighten(primaryColor, 0.8),
    selectOptionFocusedTextColor: getTextColor(lighten(primaryColor, 0.8), primaryTextColor),
    selectOptionUnfocusedBackgroundColor: '#fff',
    selectOptionUnfocusedTextColor: '#000',
    radioButtonTwoItemGroupFlexDirection: 'column' as const,
    checkboxSize: '30px',
    checkboxLabelPadding: '10px 0',
    checkboxPadding: '0px 10px 0px 0',
    checkboxFillColor: '#3F526',
    checkboxFocusBoxShadow: `0 0 0 0.2rem ${alpha(primaryColor, 0.4)}`,
    checkboxCheckedFillColor: '#000',
    checkboxSelectedLabelBackgroundColor: '#e6ff99',
    buttonBorderRadius: '5px',
    defaultButtonColor: '#fff',
    defaultButtonTextColor: '#000',
    defaultButtonBorder: 'solid 1px #ccc',
    defaultButtonBoxShadow: 'none',
    defaultButtonHoverColor: '#f2f2f2',
    defaultButtonFocusedBoxShadow: '0 0 0 0.2rem #ccc',
    defaultButtonDisabledColor: '#fff',
    defaultButtonDisabledBorder: 'solid 1px #ccc',
    selectedButtonFocusedBoxShadow: `0 0 0 0.2rem ${alpha(primaryColor, 0.4)}`,
    primaryButtonColor: primaryColor,
    primaryButtonTextColor: primaryTextColor,
    primaryButtonBoxShadow: 'none',
    primaryButtonHoverColor: primaryColor,
    primaryButtonFocusedBoxShadow: `0 0 0 0.2rem ${alpha(primaryColor, 0.4)}`,
    primaryButtonFocusedColor: primaryColor,
    primaryButtonFocusedTextColor: primaryTextColor,
    primaryButtonFocusedBorder: 'none',
    primaryBorderedButtonColor: '#000',
    secondaryButtonColor: '#5a6268',
    secondaryButtonTextColor: '#fff',
    secondaryButtonBoxShadow: 'none',
    secondaryButtonHoverColor: '#5a6268',
    secondaryButtonFocusedBoxShadow: `0 0 0 0.2rem ${alpha('#5a6268', 0.4)}`,
    dangerButtonColor: '#dc3545',
    dangerButtonTextColor: '#fff',
    dangerButtonBoxShadow: 'none',
    dangerButtonHoverColor: '#dc3545',
    dangerButtonFocusedBoxShadow: `0 0 0 0.2rem ${alpha('#dc3545', 0.4)}`,

    section: {
      margin: '0 0 1em 0',
      heading: {
        margin: '0 0 .5em 0',
      },
      content: {
        backgroundColor: '#F5F7F8',
        padding: '1.5em',
      },
      strokes: {
        highlight: {
          content: {
            backgroundColor: highlightColor,
          },
        },
        warning: {
          content: {
            backgroundColor: warningColor,
          },
        },
        danger: {
          content: {
            backgroundColor: dangerColor,
          },
        },
        success: {
          content: {
            backgroundColor: successColor,
          },
        },
      },
    },
  };
}
