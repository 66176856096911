import * as React from 'react';
import { Card, Typography, Button, CardContent } from '@material-ui/core';
import { requestPasswordReset } from '../../Services/Auth.Service';
import { useForm } from 'react-hook-form';
import TextInput from '../../Components/TextInput/TextInput';
import { sortResponseErrors } from '../Account';

interface RequestPasswordResetFormValues {
  email: string;
}

export const ForgotPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<RequestPasswordResetFormValues>();
  const [formSubmitted, setFormSubmitted] = React.useState<Boolean>(false);

  const onSubmit = async ({ email }: RequestPasswordResetFormValues) => {
    const response = await requestPasswordReset({ email });

    if (response === true) {
      setFormSubmitted(true);
    } else {
      const sortedErrors = sortResponseErrors(Object.entries(response));
      sortedErrors.forEach((error) => {
        setError('email', {
          type: 'server',
          message: error.message,
        });
      });
    }
  };

  if (formSubmitted === false) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h3" component="h1" align="center">
            Forgot your password?
          </Typography>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              error={errors.email !== undefined}
              helperText={errors.email?.message}
              id="email"
              required
              {...register('email', { required: true })}
              label="Email address"
              type="email"
            />

            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Typography variant="h2" component="h1" align="center">
            Password reset email sent
          </Typography>
          <p>You should receive an email shortly with details about how to change your password.</p>
        </CardContent>
      </Card>
    );
  }
};
