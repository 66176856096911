import { Switch } from 'react-router';
import ErrorTemplate from './Error/ErrorTemplate';

const newSwitch = (props: any) => {
  const { children } = props;
  return (
    <Switch>
      {children}
      <ErrorTemplate
        header={'Page Not Found'}
        message={
          "The page you're looking for cannot be found. Check you've entered the URL correctly"
        }
      />
    </Switch>
  );
};

export default newSwitch;
