import Cell from './Cell';
import { IRowDefinition } from '../Definitions/IRowDefinition';

export default class Row {
  constructor(private readonly definition: IRowDefinition, public readonly cells: Cell[]) {}

  public get totalCellSpan(): number {
    return this.definition.totalCellSpan;
  }
}
