import { registerXFormsFunctions } from './XFormFunctions';
import { registerCUIFunctions } from './CUIFunctions';
import { registerCustomFunctions } from './CustomFunctions';
import { Node } from 'slimdom';
import Instance from '../Components/Instance';

export const registerAllCustomFunctions = (
  xpathRegister: IXPathRegister,
  instanceResolver: () => IInstanceResolver,
  currentNodeResolver: ICurrentNodeResolver
) => {
  registerXFormsFunctions(xpathRegister, instanceResolver, currentNodeResolver);
  registerCUIFunctions(xpathRegister);
  registerCustomFunctions(xpathRegister);
};

// tslint:disable-next-line:ban-types
export type IXPathRegister = (
  name: string,
  params: string[],
  result: string,
  callback: Function
) => void;

export type IInstanceResolver = (id: string) => Instance;

export type ICurrentNodeResolver = () => Node | null;
