import { createTheme } from '@material-ui/core/styles';
// "#251430" -- purple
export const theme = createTheme({
  palette: {
    primary: {
      main: '#3463FD', // Blue
      contrastText: '#fff',
    },
    secondary: {
      main: '#33444E', // Grey
      //contrastText: "#000",
    },
    info: {
      main: '#33444E', // Grey
      contrastText: '#fff',
      light: '#889AA5',
    },
  },
  typography: {
    fontFamily: 'Hind',
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: { backgroundColor: '#251430' },
    },
    MuiTabs: {
      indicator: {
        opacity: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderWidth: 'medium',
        },
        '&:hover $notchedOutline': {
          borderWidth: 'medium',
        },
        '&$focused $notchedOutline': {
          borderWidth: 'medium',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
});
