import { observable, computed, action, makeObservable } from 'mobx';
import parseXml from '../SlimdomSaxParser';
import Bind from './Bind';
import BindStore from './BindStore';
import Row from './Row';
import Cell from './Cell';
import { evaluateXPathToFirstNode, evaluateXPathToNodes } from '@airelogic/fontoxpath';
import Control from './Control';
import Enumerable from 'linq';

import { ICellDefinition } from '../Definitions/ICellDefinition';
import { IRepeatSettings } from '../Definitions/IRepeatSettings';
import { IRowDefinition } from '../Definitions/IRowDefinition';
import newGuid from '../Guid';
import { Element } from 'slimdom';

export interface IRepeatableDefinition {
  id: string;
  settings: IRepeatSettings;
  rows: IRowDefinition[];
  classes: string;
  isHiddenFromUi: boolean;
}

export interface IRepeatIteration {
  bind: Bind;
  element: Element;
  rows: Row[];
}

export abstract class Repeatable<U extends IRepeatableDefinition, T extends IRepeatIteration> {
  public readonly iterations: T[] = new Array<T>();

  private readonly iterationBind: Bind;
  protected readonly iterationChildBinds: Bind[] = new Array<Bind>();

  constructor(
    protected readonly definition: U,
    protected readonly bind: Bind,
    private readonly element: Element,
    private readonly bindStore: BindStore
  ) {
    makeObservable<Repeatable<U, T>, '_addIteration'>(this, {
      iterations: observable,
      isRelevant: computed,
      isReadonly: computed,
      validate: action,
      addRowEnabled: computed,
      deleteRowEnabled: computed,
      addIteration: action,
      deleteIteration: action,
      _addIteration: action,
    });

    this.iterationBind = this.bindStore.getBindById(this.definition.settings.iterationBindId);
    //Slice is required to ensure we take a copy.
    this.iterationChildBinds = this.iterationBind.childBinds.slice();

    this.bindStore.removeBindAndChildren(this.iterationBind);

    const existingData = evaluateXPathToNodes<Element>(
      './' + definition.settings.iterationName,
      element
    );

    for (const existingIteration of existingData) {
      this._addIteration(existingIteration);
    }
  }

  get classes(): string {
    return this.definition.classes;
  }

  get isRelevant(): boolean {
    return this.bind.isRelevant;
  }

  get isReadonly(): boolean {
    return this.bind.isReadOnly;
  }

  get isMultiRow(): boolean {
    return this.definition.rows.length > 1;
  }

  get noItemsText(): string {
    return this.definition.settings.noItemsText;
  }

  get isHiddenFromUi(): boolean {
    return this.definition.isHiddenFromUi;
  }

  private get allControls() {
    return Enumerable.from(this.iterations)
      .selectMany((iteration) => iteration.rows)
      .selectMany((row) => row.cells)
      .where((cell) => cell.control !== undefined)
      .select((cell) => cell.control!);
  }

  get updatedSinceLastAutosave(): boolean {
    return this.allControls.any((control) => control.updatedSinceLastAutosave);
  }

  get touched(): string[] {
    return this.allControls
      .where((control) => control.touched)
      .select((control) => control.id)
      .toArray();
  }

  markControlsAsNotUpdatedSinceLastAutosave() {
    const updatedControls = this.allControls
      .where((control) => control.updatedSinceLastAutosave)
      .toArray();
    for (const updatedControl of updatedControls) {
      updatedControl.markAsNotUpdatedSinceLastAutosave();
    }
  }

  validate(): void {
    this.allControls.forEach((control) => control.validate());
  }

  get addRowEnabled(): boolean {
    return (
      this.definition.settings.addRowEnabled &&
      (this.definition.settings.maxRows
        ? this.iterations.length < this.definition.settings.maxRows
        : true)
    );
  }

  get deleteRowCanBeEnabled(): boolean {
    return this.definition.settings.deleteRowEnabled;
  }

  get deleteRowEnabled(): boolean {
    return (
      this.definition.settings.deleteRowEnabled &&
      (this.definition.settings.minRows
        ? this.iterations.length > this.definition.settings.minRows
        : true)
    );
  }

  public addIteration(): void {
    const newElement = parseXml(this.definition.settings.iterationTemplate).firstElementChild!;
    this._addIteration(newElement);
    this.element.appendChild(newElement);
  }

  public deleteIteration(iterationToDelete: T): void {
    const iterationToDeleteIndex = this.iterations.indexOf(iterationToDelete);
    this.iterations.splice(iterationToDeleteIndex, 1);
    this.bindStore.removeBindAndChildren(iterationToDelete.bind);
    this.element.removeChild(iterationToDelete.element);
  }

  private _addIteration(iterationElement: Element) {
    const iterationIdentifier = newGuid();
    const iterationBind = this.cloneIterationBind(iterationIdentifier);
    const rows = this.definition.rows.map(
      (rowDefinition) =>
        new Row(
          rowDefinition,
          rowDefinition.cells.map((cell) =>
            this.createCell(iterationIdentifier, iterationElement, cell)
          )
        )
    );
    const iteration = this.createIteration(iterationBind, iterationElement, rows);
    iterationBind.attachToElement(iteration.element);
    this.iterations.push(iteration);
  }

  private cloneIterationBind(iterationIdentifier: string): Bind {
    const iterationClone = this.iterationBind.clone(iterationIdentifier);
    const childBindClones = this.iterationChildBinds.map((childBind) =>
      childBind.clone(iterationIdentifier, iterationClone.id)
    );
    this.bindStore.addBinds([iterationClone, ...childBindClones]);
    return iterationClone;
  }

  protected abstract createIteration(
    iterationBind: Bind,
    iterationElement: Element,
    rows: Row[]
  ): T;

  private createCell(
    iterationIdentifier: string,
    iterationElement: Element,
    definition: ICellDefinition
  ): Cell {
    if (definition.control) {
      const resolvedBind = this.bindStore.getBindById(definition.control.id, iterationIdentifier);
      const resolvedElement = evaluateXPathToFirstNode<Element>(
        './' + resolvedBind.ref,
        iterationElement
      );

      if (resolvedElement === null) {
        throw Error('Unable to find element ' + resolvedBind.ref);
      }
      resolvedBind.attachToElement(resolvedElement);

      return new Cell(
        definition,
        new Control(definition.control, resolvedBind, iterationIdentifier)
      );
    } else {
      return new Cell(definition);
    }
  }
}
