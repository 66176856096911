import * as React from 'react';
import { Box, BoxProps } from '@material-ui/core';

export const FlexColumn = (
  props: Omit<BoxProps, 'display' | 'flexDirection' | 'height' | 'width'>
): React.ReactElement => {
  return <Box {...props} display="flex" flexDirection="column" height="100%" width="100%" />;
};

export const FlexRow = (
  props: Omit<BoxProps, 'display' | 'flexDirection' | 'height' | 'width'>
): React.ReactElement => {
  return <Box {...props} display="flex" flexDirection="row" height="100%" width="100%" />;
};

export const Column = (props: Omit<BoxProps, 'display' | 'flexDirection'>): React.ReactElement => {
  return <Box {...props} display="flex" flexDirection="column" />;
};

export const Row = (props: Omit<BoxProps, 'display' | 'flexDirection'>): React.ReactElement => {
  return <Box {...props} display="flex" flexDirection="row" />;
};
