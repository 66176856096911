import { ReactRenderer } from '@airelogic/xml-renderer';
import * as React from 'react';
import './XmlRender.css';

export const xmlRender = new ReactRenderer();
// text nodes
xmlRender.add('self::text()', ({ node }) => <>{node.nodeValue}</>);

// element nodes
xmlRender.add('self::element()', (value) => (
  <XmlElementRender key={value.key} element={value.node as Element}>
    {value.traverse()}
  </XmlElementRender>
));
interface IElementRenderProps {
  element: Element;
}

const XmlElementRender: React.FC<IElementRenderProps> = ({ element, children }) => {
  return (
    <div className="xmlDebugDiv">
      <span className="xmlDebugElement">&lt;{element.nodeName}</span>
      <span className="xmlDebugAttrs">
        {Array.from(element.attributes).map((attribute) => {
          return (
            <React.Fragment key={attribute.name}>
              <span className="xmlDebugAttKey"> {attribute.name}=</span>
              <span className="xmlDebugAttValue">"{attribute.value}"</span>
            </React.Fragment>
          );
        })}
      </span>
      <span className="xmlDebugElement">&gt;</span>
      {element.hasAttribute('f4h:secret') ? '************' : children}
      <span className="xmlDebugElement">&lt;/{element.nodeName}&gt;</span>
    </div>
  );
};
