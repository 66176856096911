import { Router, Route } from 'react-router-dom';
import AdminRoutes from './Admin/Routing';
import ErrorRoutes from './Error/Routing';
import { ClinicianPreview, SinglePassPreview } from './FormPreviews/Routing';
import Layout from './Layout/Layout';
import FormBuildingRoutes from './FormBuilding/Routing';
import AuthRoutes from './Account/Routing';
import PublishingRoutes from './Publishing/Routing';
import { AuthorisedRoute } from './AuthorisedRoute';
import AccessDenied from './AccessDenied';
import Switch from './ErrorHandlingSwitch';
import { History } from 'history';

const paths = {
  auth: `/auth`,
  admin: `/admin`,
  formBuilding: `/formbuilding`,
  error: `/error`,
  publishing: `/publishing`,
};

interface Props {
  history: History;
}

const Routing = ({ history }: Props) => {
  return (
    <Router history={history}>
      <Switch>
        <AuthorisedRoute
          path={'/ClinicianPreview/:form/:version/:viewMode?'}
          can={{ I: 'preview', a: 'Form' }}
        >
          <ClinicianPreview />
        </AuthorisedRoute>
        <AuthorisedRoute path={'/singlepassdemo/:form/:version'} can={{ I: 'preview', a: 'Form' }}>
          <SinglePassPreview />
        </AuthorisedRoute>
        <Route>
          <Layout>
            <Switch>
              <Route path={paths.auth}>
                <AuthRoutes />
              </Route>
              <Route path={paths.error}>
                <ErrorRoutes />
              </Route>
              <AuthorisedRoute path={paths.admin} can={{ I: 'update', a: 'User' }}>
                <AdminRoutes />
              </AuthorisedRoute>
              <Route path={paths.formBuilding}>
                <FormBuildingRoutes />
              </Route>
              <Route path={paths.publishing}>
                <PublishingRoutes />
              </Route>
              <Route path="accessdenied">
                <AccessDenied />
              </Route>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
};

export default Routing;
