import * as React from 'react';
import ErrorTemplate from '../Error/ErrorTemplate';

interface IProps {
  message: string;
}

const PreviewErrorPage = ({ message }: IProps) => {
  return <ErrorTemplate header={'There was an issue generating your form.'} message={message} />;
};

export default PreviewErrorPage;
