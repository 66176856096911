import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';

const ErrorPage = () => {
  return (
    <Paper>
      <Box height="100%" padding="20px" display="flex" flexDirection="column" alignItems="centre">
        <Typography variant="h5" align="center">
          A fatal error has occurred that requires the Forms4Health team's attention.
        </Typography>
        <Typography variant="h6" align="center">
          We apologise for the inconvenience.
        </Typography>
      </Box>
    </Paper>
  );
};

export default ErrorPage;
