import { Paper, Typography, Box } from '@material-ui/core';
import WarningIcon from './Assets/Warning_F4H_Icon.svg';
import { useStyles } from './ErrorTemplate.styles';
import BackgroundImage from './Assets/aire_background.png';

interface Props {
  header: string;
  message: string;
}

const ErrorTemplate = ({ header, message }: Props) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <img src={WarningIcon} className={classes.warningIcon} alt="warning icon" />
          <br />
          <Typography variant="h4" align="center">
            {header}
          </Typography>

          <Typography variant="h6" align="center">
            {message}
          </Typography>
        </Box>
      </Paper>
      <img src={BackgroundImage} className={classes.background} alt="" />;
    </>
  );
};

export default ErrorTemplate;
