import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as EditIconSVG } from './SVGs/edit-icon.svg';
import { useStyles } from './IconButton.styles';

interface Props extends IconButtonProps {
  tooltipText?: string;
}

const EditIcon = (props: Props) => {
  const classes = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.strokeButton} {...rest}>
        <SvgIcon component={EditIconSVG} className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default EditIcon;
