import BuildingBlocks from './BuildingBlocks/Page';
import FormsRouting from './Forms/Routing';
import ReferenceDataRouting from './ReferenceData/Routing';
import { useRouteMatch } from 'react-router';
import { AuthorisedRoute } from '../AuthorisedRoute';

import Switch from '../ErrorHandlingSwitch';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute
        path={`${match.path}/buildingblocks`}
        can={{ I: 'create', a: 'BuildingBlock' }}
      >
        <BuildingBlocks />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/forms`} can={{ I: 'preview', a: 'Form' }}>
        <FormsRouting />
      </AuthorisedRoute>

      <AuthorisedRoute
        path={`${match.path}/referencedata`}
        can={{ I: 'create', a: 'ReferenceDataSet' }}
      >
        <ReferenceDataRouting />
      </AuthorisedRoute>
    </Switch>
  );
};

export default Routing;
