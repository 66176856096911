import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useContainerStyle = createUseStyles({
  structureContainer: {
    position: 'relative',
    margin: '0 auto',
    width: '95%',
    minWidth: '15em',
    maxWidth: '1008px',
  },
});

const Container: React.FC = (props) => {
  const { structureContainer } = useContainerStyle();

  return <div className={structureContainer}>{props.children}</div>;
};

export default Container;
