import Row from './Row';
import Bind from './Bind';
import { Element } from 'slimdom';
import Control from './Control';

export default class MasterChildIteration {
  constructor(
    public readonly bind: Bind,
    public readonly element: Element,
    public readonly summaryRows: Array<{ controls: Control[] }>,
    public readonly rows: Row[]
  ) {}
}
