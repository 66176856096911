import queryString from 'query-string';

export default class NavigateParams {
  constructor(search: string) {
    const parsedQuery = queryString.parse(search);
    this.navigate = this.getParam(parsedQuery['navigate']);
    this.navigateOnSubmit = this.getParam(parsedQuery['navigateOnSubmit']);
    this.navigateOnSaveDraft = this.getParam(parsedQuery['navigateOnSaveDraft']);
    this.navigateOnDiscard = this.getParam(parsedQuery['navigateOnDiscard']);
    this.navigateOnClose = this.getParam(parsedQuery['navigateOnClose']);
  }

  private getParam(param: string | (string | null)[] | null): string | null {
    if (!param) {
      return null;
    }

    if (param instanceof Array) {
      if (param.length > 0) {
        return param[0];
      }
      return null;
    }

    return param;
  }

  private navigate: string | null;
  private navigateOnSubmit: string | null;
  private navigateOnSaveDraft: string | null;
  private navigateOnDiscard: string | null;
  private navigateOnClose: string | null;

  public get onSubmit(): string | null {
    return this.navigateOnSubmit || this.navigate;
  }
  public get onSaveDraft(): string | null {
    return this.navigateOnSaveDraft || this.navigate;
  }
  public get onDiscard(): string | null {
    return this.navigateOnDiscard || this.navigate;
  }
  public get onClose(): string | null {
    return this.navigateOnClose || this.navigate;
  }
}
