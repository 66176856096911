import { Container } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import NavMenu from './NavMenu';
import FooterBar from './FooterBar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  container: {
    height: '100%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    marginBottom: theme.spacing(2),
  },
}));

interface ILayout {
  children: NonNullable<React.ReactNode>;
}

const Layout = ({ children }: ILayout) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <NavMenu />
      <main className={classes.main}>
        <Container className={classes.container}>{children}</Container>
      </main>
      <FooterBar />
    </div>
  );
};

export default Layout;
