import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  arrowButton: {
    color: 'white',
    background: '#3463FD',
    marginLeft: '4px',
    marginRight: '4px',
    height: '35px',
    width: '35px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
  },
  icon: {
    fontSize: '30px',
  },
}));

interface Props extends Omit<IconButtonProps, 'component' | 'className'> {
  tooltipText?: string;
}

const UpIcon = (props: Props) => {
  const classes = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton component="span" className={classes.arrowButton} {...rest}>
        <ArrowUpwardIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default UpIcon;
