import Page from './Page';
import { ViewMode } from '../Definitions/ViewMode';
import { computed, makeObservable, action } from 'mobx';
import { WarningCode } from '../Definitions/IFormDefinition';

export interface IFormOptions {
  readonly requireRecaptcha: boolean;
  readonly privacyPolicyUri: string;
  readonly accessibilityStatementUri: string;
}

export default class Form {
  constructor(
    public readonly title: string,
    public readonly warningCode: WarningCode,
    public readonly viewMode: ViewMode,
    public readonly pages: Page[],
    public readonly formOptions: IFormOptions
  ) {
    makeObservable(this, {
      visiblePages: computed,
      validate: action,
      isValid: computed,
    });
  }

  get hasMultiplePages(): boolean {
    return this.pages.length > 1;
  }

  get visiblePages(): Page[] {
    return this.pages.filter((page) => page.isRelevant && page.isHiddenFromUi === false);
  }

  validate = (): boolean => {
    for (const page of this.pages) {
      page.validate();
    }

    return this.isValid;
  };

  get isValid(): boolean {
    return this.pages.every((page) => page.isValid);
  }

  get updatedSinceLastAutosave(): boolean {
    return this.pages.some((block) => block.updatedSinceLastAutosave);
  }

  markControlsAsNotUpdated = (): void => {
    for (const page of this.pages) {
      page.markControlsAsNotUpdated();
    }
  };
}
