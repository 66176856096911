import { validationDomFacade } from './ValidationDomFacade';
import { createXPathEvaluator } from './XPathEvaluator';
import Instance from '../Components/Instance';
import { Document } from 'slimdom';

export type ValidationResult = { isValid: true } | { isValid: false; message: string };

const createDummyInstance = () => {
  const document = new Document();
  document.append(document.createElement('root'));
  return document;
};

const xPathEvaluator = createXPathEvaluator((id: string) => {
  return new Instance(id, createDummyInstance());
});

export const validateXPath = (xpath: string): ValidationResult => {
  const nonDollaredXPath = xpath.replaceAll('$', '');
  try {
    xPathEvaluator.evaluateXPath(nonDollaredXPath, createDummyInstance(), validationDomFacade);
  } catch (error) {
    if (error instanceof Error) {
      return {
        isValid: false,
        message: error.message,
      };
    }
    return {
      isValid: false,
      message: 'An unexpected error has occured',
    };
  }

  return {
    isValid: true,
  };
};
