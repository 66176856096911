import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      margin: 'auto 0',
    },
    formInputSpaced: {
      margin: 'auto 10px auto 10px',
    },
    paperHeader: {
      borderBottom: 'solid 1px #33444E1A',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    formError: {
      borderLeft: 'red 7px solid',
    },
    paperStyle: {
      borderLeft: '7px solid #ffffff00',
    },
    headerText: {
      marginRight: 'auto',
    },
    versionText: {
      color: '#889AA5',
    },
    versionTextBold: {
      fontWeight: 'bold',
      color: '#33444E',
    },
    autoCompleteTooltip: {
      marginLeft: '8px',
    },
  })
);
