import * as React from 'react';
import { Register } from './Register';

export default function RegisterPage() {
  return (
    <>
      <Register />
    </>
  );
}
