import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar, SnackbarProps } from '@material-ui/core';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ToastProps extends Pick<AlertProps, 'severity'>, Pick<SnackbarProps, 'open' | 'onClose'> {
  children: React.ReactNode;
}

const Toast = ({ open, onClose, severity, children }: ToastProps) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert severity={severity}>{children}</Alert>
    </Snackbar>
  );
};

export default Toast;
