import { action, computed, observable, when, makeObservable, runInAction } from 'mobx';
import { IPersistenceSettings } from '../Definitions/IFormDefinition';
import AutoSaveResult from './AutoSaveResult';
import SubmissionRequest from './SubmissionRequest';
import { SubmissionService } from './SubmissionService';
import SuccessActionResolver, { ActionResult } from './SuccessActionResolver';
import axios from 'axios';
import SubmissionResponse from './SubmissionResponse';

export default class PersistenceHandler {
  private readonly autoSaveInterval: NodeJS.Timer;

  constructor(
    private readonly configuration: IPersistenceSettings,
    private readonly form: {
      validate: () => boolean;
      updatedSinceLastAutosave: () => boolean;
      markControlsAsNotUpdated: () => void;
    },
    private readonly composeSubmission: (recaptchaToken?: string) => SubmissionRequest,
    private readonly submissionService: SubmissionService,
    private readonly successActionResolver: SuccessActionResolver
  ) {
    makeObservable<
      PersistenceHandler,
      | '_submitting'
      | 'autoSaveInTransit'
      | '_submissionCompleted'
      | '_submissionResult'
      | 'markAsNoFurtherActionsAllowed'
      | '_lastAutoSaveResult'
      | 'tryAutosave'
    >(this, {
      _submitting: observable,
      submissionInProgress: computed,
      autoSaveInTransit: observable,
      _submissionCompleted: observable,
      formCompleted: computed,
      _submissionResult: observable,
      submissionResult: computed,
      markAsNoFurtherActionsAllowed: action,
      validateForm: action,
      trySubmit: action,
      trySaveDraft: action,
      _lastAutoSaveResult: observable,
      lastAutosaveResult: computed,
      tryAutosave: action,
      tryDiscard: action,
    });

    if (this.configuration.allowAutoSave) {
      this.autoSaveInterval = setInterval(async () => {
        await this.tryAutosave();
      }, this.configuration.autoSaveIntervalSeconds * 1000);
    }
  }

  public get canSaveAsDraft() {
    return this.configuration.allowSaveDraft;
  }

  public get canDiscard() {
    return this.configuration.allowDiscard;
  }

  private _submitting: boolean = false;

  public get submissionInProgress() {
    return this._submitting;
  }

  private autoSaveInTransit: boolean = false;

  private _submissionCompleted: boolean = false;

  public get formCompleted(): boolean {
    return this._submissionCompleted;
  }

  private _submissionResult?: ActionResult;

  public get submissionResult(): ActionResult | undefined {
    return this._submissionResult;
  }

  private markAsNoFurtherActionsAllowed() {
    this._submissionCompleted = true;
    clearInterval(this.autoSaveInterval);
  }

  public validateForm(): boolean {
    return this.form.validate();
  }

  public trySubmit = async (recaptchaToken?: string): Promise<boolean> => {
    this.assertCanDoFurtherActions();

    if (this.form.validate() === false) {
      return Promise.resolve(false);
    }

    this._submitting = true;

    return new Promise((resolve, reject) => {
      when(
        () => !this.autoSaveInTransit,
        async () => {
          let submissionResponse: SubmissionResponse | null = null;
          try {
            const submission = this.composeSubmission(recaptchaToken);
            submissionResponse = await this.submissionService(
              this.configuration.submissionUri,
              submission
            );
            if (submissionResponse.success === false) {
              return reject(new Error(submissionResponse.message));
            }
            this.markAsNoFurtherActionsAllowed();
          } catch {
            return reject();
          } finally {
            runInAction(() => (this._submitting = false));
          }

          this.postMessageToParent('form-submitted');
          const actionResult =
            this.successActionResolver.resolveSubmissionSuccessAction(submissionResponse);
          runInAction(() => (this._submissionResult = actionResult));
          actionResult.execute();
          resolve(true);
        }
      );
    });
  };

  public trySaveDraft = async (): Promise<void> => {
    if (this.configuration.allowSaveDraft === false) {
      throw new Error('Form does not allow saving as draft');
    }

    this.assertCanDoFurtherActions();
    this._submitting = true;

    return new Promise((resolve, reject) => {
      when(
        () => !this.autoSaveInTransit,
        async () => {
          try {
            await this.submissionService(this.configuration.saveUri, this.composeSubmission());
            this.markAsNoFurtherActionsAllowed();
          } catch {
            return reject();
          } finally {
            runInAction(() => (this._submitting = false));
          }

          this.postMessageToParent('form-draft-saved');
          this.postMessageToParent('form-closed');
          const actionResult = this.successActionResolver.resolveSaveDraftSuccessAction();
          actionResult.execute();
          resolve();
        }
      );
    });
  };

  private _lastAutoSaveResult: AutoSaveResult;

  public get lastAutosaveResult(): AutoSaveResult | undefined {
    return this._lastAutoSaveResult;
  }

  private tryAutosave = async (): Promise<void> => {
    if (this.configuration.allowAutoSave === false) {
      throw new Error('Form does not allow saving as draft');
    }

    this.assertCanDoFurtherActions();

    if (
      this.submissionInProgress ||
      this.autoSaveInTransit ||
      this.form.updatedSinceLastAutosave() === false
    ) {
      if (this._lastAutoSaveResult) {
        const { success, timestamp } = this._lastAutoSaveResult;
        //REapply values to trigger the last updated text to update
        this._lastAutoSaveResult = { success: success, timestamp: timestamp };
      }
      return;
    } else {
      this.autoSaveInTransit = true;
      try {
        await this.submissionService(
          this.configuration.saveUri + '&autosave=true',
          this.composeSubmission()
        );
      } catch {
        this._lastAutoSaveResult = { success: false, timestamp: new Date() };
        return;
      } finally {
        this.autoSaveInTransit = false;
      }
      this.form.markControlsAsNotUpdated();
      this._lastAutoSaveResult = { success: true, timestamp: new Date() };
    }

    return;
  };

  public tryDiscard = async (): Promise<void> => {
    if (this.configuration.allowDiscard === false) {
      throw new Error('Form does not allow discarding');
    }

    this.assertCanDoFurtherActions();

    this._submitting = true;

    try {
      const response = await axios(this.configuration.discardUri, {
        method: 'DELETE',
        withCredentials: true,
      });

      if (response.status === 200) {
        this.markAsNoFurtherActionsAllowed();
        this.postMessageToParent('form-discarded');
        const actionResult = this.successActionResolver.resolveDiscardSuccessAction();
        actionResult.execute();
        return Promise.resolve();
      }
    } catch {
      return Promise.reject();
    } finally {
      runInAction(() => (this._submitting = false));
    }
  };

  public close = () => {
    this.markAsNoFurtherActionsAllowed();
    this.postMessageToParent('form-closed');
    const actionResult = this.successActionResolver.resolveCloseSuccessAction();
    actionResult.execute();
  };

  private postMessageToParent = (event: string) => {
    //We need to use postMessage as it supports cross origin communication
    window.parent.postMessage({ eventType: event }, '*');
  };

  private assertCanDoFurtherActions() {
    if (this._submissionCompleted) {
      throw new Error(
        'Form has already been submitted/saved as draft/discarded.  No further actions can occur'
      );
    }
  }
}
