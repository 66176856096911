import { ReferenceDataFields } from './Types';

export interface DuplicatedReferenceDatum {
  code: string;
  index: number;
}

export const getDuplicatedReferenceData = (
  data: ReferenceDataFields
): DuplicatedReferenceDatum[] => {
  const currentData = data.referenceData.map((d, index) => {
    return { code: d.code, index: index };
  });

  const uniqueCodes = new Set(
    currentData.map((d) => {
      return d.code;
    })
  );

  if (currentData.length !== uniqueCodes.size) {
    return currentData.filter(function (datum) {
      if (uniqueCodes.has(datum.code)) {
        uniqueCodes.delete(datum.code);
        return false;
      } else {
        return true;
      }
    });
  }

  return [];
};
