import { useStyles } from './ReferenceDataFormEdit.styles';
import { Prompt } from 'react-router';
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import useAxios from 'axios-hooks';
import ReferenceDataPageEdit from '../ReferenceDataPageEdit/ReferenceDataPageEdit';
import StyledButton from '../../../Components/Buttons/StyledButton';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import { ReferenceDataFields } from '../Types';
import { getDuplicatedReferenceData } from '../ReferenceData.Service';

interface Props {
  currentValues: ReferenceDataFields;
  isNew: boolean;
}

const ReferenceDataFormEdit = ({ currentValues, isNew }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const formMethods = useForm<ReferenceDataFields>({
    defaultValues: currentValues,
    shouldUnregister: false,
  });
  const [showDiscardFormConfirmation, setShowDiscardFormConfirmation] = useState<boolean>(false);
  const [canNavigateAway, setCanNavigateAway] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: { isDirty, isSubmitting },
  } = formMethods;
  const [, submit] = useAxios(
    {},
    {
      manual: true,
    }
  );

  const validateReferenceData = (formInput: ReferenceDataFields): boolean => {
    const invalidData = getDuplicatedReferenceData(formInput);
    if (invalidData.length > 0) {
      invalidData.forEach((b) => {
        setError(`referenceData.${b.index}.code`, {
          type: 'manual',
          message: 'This code is used more than once',
        });
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (data: ReferenceDataFields) => {
    if (!validateReferenceData(data)) {
      return;
    }

    const response = await submit({
      url: `/internalapi/formbuilding/referencedata/${data.key}${
        data.version ? `/${data.version}` : ''
      }`,
      method: isNew ? 'POST' : 'PUT',
      validateStatus: (status: number) => status === 200 || status === 409,
      data: data.referenceData,
    });

    if (response.status === 409) {
      setError('key', { message: 'Value is already in use' });
    }

    if (response.status === 200) {
      setCanNavigateAway(true);
    }
  };

  const navBackToReferenceData = () => {
    setShowDiscardFormConfirmation(false);
    setCanNavigateAway(true);
  };

  const hideConfirmation = () => {
    setShowDiscardFormConfirmation(false);
  };

  const onCancelClicked = () => {
    if (isDirty) {
      setShowDiscardFormConfirmation(true);
    } else {
      history.push('/formbuilding/referencedata');
    }
  };

  useEffect(() => {
    if (canNavigateAway) {
      history.push('/formbuilding/referencedata');
    }
  }, [canNavigateAway, history]);

  const onHandleNavigate = () => {
    if (canNavigateAway) {
      return true;
    }

    setShowDiscardFormConfirmation(true);
    return false;
  };

  return (
    <>
      <Prompt when={isDirty} message={onHandleNavigate} />
      <FormProvider<ReferenceDataFields> {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={12} className={classes.itemPanel}>
              <ReferenceDataPageEdit isNew={isNew} />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" spacing={0} alignItems="center">
            <Grid item xs={12} className={classes.surface}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" height="100%">
                <Box display="flex" flexDirection="row">
                  <StyledButton onClick={onCancelClicked} aria-label="cancel button">
                    Cancel
                  </StyledButton>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Save and Exit
                  </StyledButton>
                  <ConfirmationDialog
                    confirmationText={'Are you sure you want to discard changes?'}
                    negativeChoice={'No, keep editing'}
                    open={showDiscardFormConfirmation}
                    handleCancel={hideConfirmation}
                    handleConfirm={navBackToReferenceData}
                    aria-label="submit confirmation dialog"
                  ></ConfirmationDialog>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
export default ReferenceDataFormEdit;
