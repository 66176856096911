import ReferenceDataTable from './ReferenceDataTable';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NavLinkButton from '../../Components/Buttons/NavLinkButton';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const List = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <>
      <NavLinkButton
        variant="contained"
        color="primary"
        className={classes.button}
        to={`${path}/new`}
      >
        Create new
      </NavLinkButton>
      <ReferenceDataTable />
    </>
  );
};

export default List;
