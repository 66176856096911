import { useRouteMatch } from 'react-router';
import { AuthorisedRoute } from '../../AuthorisedRoute';
import CreateNewPage from './FormBuilder/CreateNew/Page';
import EditFormPage from './FormBuilder/EditExisting/Page';
import Forms from './Page';
import Switch from '../../ErrorHandlingSwitch';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute path={`${match.path}`} exact can={{ I: 'preview', a: 'Form' }}>
        <Forms />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/new`} can={{ I: 'create', a: 'Form' }}>
        <CreateNewPage />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/newversion/:key`} can={{ I: 'create', a: 'Form' }}>
        <EditFormPage />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/edit/:key/:version`} can={{ I: 'create', a: 'Form' }}>
        <EditFormPage />
      </AuthorisedRoute>
    </Switch>
  );
};

export default Routing;
