import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: 'black',
    marginLeft: '4px',
    marginRight: '4px',
    height: '35px',
    width: '35px',
    padding: theme.spacing(0.5),
    marginTop: 'auto',
    '&:hover': {
      color: theme.palette.info.light,
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  icon: {
    fontSize: '30px',
  },
}));

interface Props extends Omit<IconButtonProps, 'component' | 'className'> {
  tooltipText?: string;
}

const DropdownArrow = (props: Props) => {
  const classes = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton component="span" className={classes.deleteButton} {...rest}>
        <DeleteIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default DropdownArrow;
