import {
  readOnlyAttributeName,
  validAttributeName,
  relevanceAttributeName,
  omitFromSubmissionAttributeName,
} from './Bind';
import { Document, Element } from 'slimdom';

export default class Instance {
  constructor(public readonly id: string, public readonly xml: Document) {}

  public cleanForSubmission(): Document {
    const clone = this.xml.cloneNode(true);
    this.recursivelyCleanElementAndChildren(clone.firstElementChild!);
    return clone;
  }

  private recursivelyCleanElementAndChildren(element: Element): void {
    if (
      element.getAttribute(relevanceAttributeName) === 'false' ||
      element.getAttribute(omitFromSubmissionAttributeName) === 'true'
    ) {
      if (element.parentElement) {
        element.parentElement.removeChild(element);
      } else {
        //we are the root element so just remove all children
        Array.from(element.children).forEach((child) => element.removeChild(child));
      }
    }

    element.removeAttribute(relevanceAttributeName);
    element.removeAttribute(readOnlyAttributeName);
    element.removeAttribute(validAttributeName);
    element.removeAttribute(omitFromSubmissionAttributeName);
    element.removeAttribute('xmlns:f4h');
    element.removeAttribute('f4h:secret');

    Array.from(element.children).forEach((childElement) =>
      this.recursivelyCleanElementAndChildren(childElement)
    );
  }
}
