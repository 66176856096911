import MaterialTable, { Column } from '@material-table/core';
import MaterialTableIcons from '../../MaterialTableIcons';
import { ExistingForm } from './ExistingForm';
import useAxios from 'axios-hooks';
import EditIcon from '../../Components/IconButtons/EditIcon';
import NewVersionIcon from '../../Components/IconButtons/NewVersionIcon';
import PreviewIcon from '../../Components/IconButtons/PreviewIcon';
import { Can } from '../../AppContext/AuthenticatedUser/AbilityContext';
import { createPreviewUrl } from '../../UrlHelper';
import { useHistory } from 'react-router-dom';
import { When } from '../../Components/When/When';
import { useCallback } from 'react';

const Table = () => {
  const [{ data, loading }] = useAxios<ExistingForm[]>('/internalapi/formbuilding/forms');
  const history = useHistory();

  const handleEditClick = useCallback(
    (existingForm: ExistingForm) => {
      history.push(`/formbuilding/forms/edit/${existingForm.key}/${existingForm.version}`);
    },
    [history]
  );

  const handleNewVersionClick = useCallback(
    (existingForm: ExistingForm) => {
      history.push(`/formbuilding/forms/newversion/${existingForm.key}`);
    },
    [history]
  );

  const handlePreviewClick = (existingForm: ExistingForm) => {
    const previewLink = createPreviewUrl(
      existingForm.layout,
      existingForm.key,
      existingForm.version
    );
    window.open(previewLink, '_blank');
  };

  const formTypeLookup: Record<string, string> = {
    wizard: 'Single-Pass',
    document: 'Clinician',
  };

  const actionButtons = useCallback(
    (rowData) => (
      <>
        <Can I="preview" a="Form">
          <PreviewIcon
            aria-label={`Preview ${rowData.key} at version ${rowData.version}`}
            tooltipText={`Preview '${rowData.title}' version ${rowData.version}`}
            onClick={() => {
              handlePreviewClick(rowData);
            }}
          />
        </Can>
        <Can I="create" a="Form">
          <EditIcon
            aria-label={`Edit ${rowData.key} at version ${rowData.version}`}
            tooltipText={`Edit version ${rowData.version}`}
            onClick={() => {
              handleEditClick(rowData);
            }}
          />
          <When condition={rowData.isLatestVersion}>
            <NewVersionIcon
              aria-label={`Create new version of ${rowData.key} from version ${rowData.version}`}
              tooltipText={'Create new version'}
              onClick={() => {
                handleNewVersionClick(rowData);
              }}
            />
          </When>
        </Can>
      </>
    ),
    [handleEditClick, handleNewVersionClick]
  );

  const columns: Column<ExistingForm>[] = [
    { title: 'Key', field: 'key' },

    { title: 'Version', field: 'version' },

    { title: 'Title', field: 'title' },

    { title: 'Form type', field: 'layout', lookup: formTypeLookup },

    { title: 'Delivery status', field: 'deliveryStatus' },

    { title: 'Classification', field: 'classification' },
    {
      title: 'Actions',
      sorting: false,
      render: actionButtons,
    },
  ];

  return (
    <MaterialTable<ExistingForm>
      icons={MaterialTableIcons}
      isLoading={loading}
      title="Forms"
      columns={columns}
      data={data ?? []}
      options={{
        search: true,
        pageSize: 20,
        pageSizeOptions: [10, 20, 50],
      }}
    />
  );
};

export default Table;
