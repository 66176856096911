import Form from './Form';
import { IFormDefinition } from './Definitions/IFormDefinition';
import { ThemeOptions } from './Definitions/IFormDefinition';
import Header from './Layouts/Wizard/Header';
import Container from './Layouts/Wizard/Container';
import Loading from './Loading';
import './i18n';
import newGuid from './Guid';
import { mediaBreakPoints } from './MediaBreakpoints';
import lazyRetry from './LazyRetry';
import { configure } from 'mobx';
import InvalidXPathError from './Conversion/InvalidXPathError';
import { createXPathEvaluator } from './XPath/XPathEvaluator';
import Instance from './Components/Instance';

configure({
  useProxies: 'never', //Move to ifavailable once its no longer experimental
  enforceActions: 'observed',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
});

export {
  ThemeOptions,
  IFormDefinition as FormDefinition,
  Form,
  Header as WizardHeader,
  Container as WizardContainer,
  Loading as LoadingSpinner,
  newGuid,
  mediaBreakPoints,
  lazyRetry,
  InvalidXPathError,
  createXPathEvaluator,
  Instance,
};

export * from './XPath/XPathValidator';
