import { darken } from '@material-ui/core/styles';
import { FormTheme, getTextColor } from './Themes/FormTheme';
import { createUseFormThemeStyles } from './Themes/ThemeProvider';

export const useButtonStyles = createUseFormThemeStyles((style: FormTheme) => {
  const primaryBorderedButtonColor = style.primaryBorderedButtonColor ?? style.primaryButtonColor;

  return {
    button: {
      display: 'inline-block',
      fontWeight: 400,
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      verticalAlign: 'middle',
      userSelect: 'none',
      border: '1px solid transparent',
      padding: '.375rem .75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      borderRadius: style.buttonBorderRadius,
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      cursor: 'pointer',
      '&:focus': {
        outlineStyle: 'none',
      },
    },
    buttonPrimary: {
      backgroundColor: style.primaryButtonColor,
      border: `1px solid ${darken(style.primaryButtonColor, 0.2)}`,
      color: getTextColor(style.primaryButtonColor, style.primaryButtonTextColor),
      boxShadow: style.primaryButtonBoxShadow,
      '&:focus': {
        boxShadow: style.primaryButtonFocusedBoxShadow,
        backgroundColor: style.primaryButtonFocusedColor,
        color: style.primaryButtonFocusedTextColor,
        border: style.primaryButtonFocusedBorder,
        zIndex: 5,
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: style.primaryButtonHoverColor,
        color: getTextColor(style.primaryButtonColor, style.primaryButtonTextColor),
        border: `1px solid ${darken(style.primaryButtonColor, 0.2)}`,
        boxShadow: style.primaryButtonBoxShadow,
      },
    },
    buttonBorderedPrimary: {
      backgroundColor: 'white',
      border: `1px solid ${primaryBorderedButtonColor}`,
      color: primaryBorderedButtonColor,
      '&:focus': {
        boxShadow: style.primaryButtonFocusedBoxShadow,
        backgroundColor: style.primaryButtonFocusedColor,
        color: style.primaryButtonFocusedTextColor,
        border: style.primaryButtonFocusedBorder,
        zIndex: 5,
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: style.primaryButtonHoverColor,
        color: getTextColor(style.primaryButtonColor, style.primaryButtonTextColor),
        border: `1px solid ${darken(style.primaryButtonColor, 0.2)}`,
        boxShadow: style.primaryButtonBoxShadow,
      },
    },
    buttonSecondary: {
      backgroundColor: style.secondaryButtonColor,
      border: `1px solid ${darken(style.secondaryButtonColor, 0.2)}`,
      color: getTextColor(style.secondaryButtonColor, style.secondaryButtonTextColor),
      boxShadow: style.secondaryButtonBoxShadow,
      '&:focus': {
        boxShadow: style.secondaryButtonFocusedBoxShadow,
        zIndex: 5,
      },
      '&:hover': {
        backgroundColor: style.secondaryButtonHoverColor,
      },
    },
    buttonDanger: {
      backgroundColor: style.dangerButtonColor,
      border: `1px solid ${darken(style.dangerButtonColor, 0.2)}`,
      color: getTextColor(style.dangerButtonColor, style.dangerButtonTextColor),
      boxShadow: style.dangerButtonBoxShadow,
      '&:focus': {
        boxShadow: style.dangerButtonFocusedBoxShadow,
        zIndex: 5,
      },
      '&:hover': {
        backgroundColor: style.dangerButtonHoverColor,
      },
    },
    buttonNoShadow: {
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    buttonDefault: {
      backgroundColor: style.defaultButtonColor,
      border: style.defaultButtonBorder,
      color: style.defaultButtonTextColor,
      '&:hover': {
        backgroundColor: style.defaultButtonHoverColor,
        cursor: 'pointer',
      },
      '&:focus': {
        boxShadow: style.defaultButtonFocusedBoxShadow,
      },
      '&:disabled': {
        backgroundColor: style.defaultButtonDisabledColor,
        border: style.defaultButtonBorder,
      },
    },
    buttonRow: {
      width: '100%',
      margin: '5px 0px',
      borderRadius: `${style.buttonBorderRadius} !important`,
      '&:not(:last-child)': {
        borderRight: `${style.formControlBorderWidth} solid ${style.formControlBorderColor} !important`,
      },
    },
    buttonGroup: {
      display: 'group',
      '& button': {
        borderRadius: '0',
        position: 'relative',
        '&:first-child': {
          borderRadius: `${style.buttonBorderRadius} 0 0 ${style.buttonBorderRadius}`,
        },
        '&:last-child': {
          borderRadius: `0 ${style.buttonBorderRadius} ${style.buttonBorderRadius} 0`,
        },
      },
    },
    buttonGroupSpaceBetween: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& button': {
        '&:first-child': {
          borderRadius: `${style.buttonBorderRadius} 0 0 ${style.buttonBorderRadius}`,
        },
        '&:last-child': {
          borderRadius: `0 ${style.buttonBorderRadius} ${style.buttonBorderRadius} 0`,
        },
        '&:not(:last-child)': {
          borderRight: 0,
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    buttonGroupLarge: {
      '& button': {
        padding: '.5rem 1rem',
        fontSize: '1.25rem',
        lineHeight: '1.5',
      },
    },
    buttonGroupVertical: {
      display: 'group',
      '& button': {
        width: '100%',
        borderRadius: '0',
        position: 'relative',
        '&:first-child': {
          borderRadius: `${style.buttonBorderRadius} ${style.buttonBorderRadius} 0 0`,
        },
        '&:last-child': {
          borderRadius: `0 0 ${style.buttonBorderRadius} ${style.buttonBorderRadius}`,
        },
        '&:not(:last-child)': {
          borderBottom: 0,
        },
      },
    },
    buttonGroupButtonSelected: {
      backgroundColor: style.primaryColor,
      color: style.primaryTextColor,
      border: `${style.formControlBorderWidth} solid ${style.formControlBorderColor}`,
      '&:focus': {
        boxShadow: style.selectedButtonFocusedBoxShadow,
      },
    },
    fullWidthGroup: {
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        width: '100%',
        borderRadius: '0 !important',
        marginBottom: '0.5rem',
      },
    },
    buttonLink: {
      backgroundColor: 'transparent',
      padding: 0,
      color: style.primaryButtonColor,
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:not(:disabled)': {
        cursor: 'pointer',
      },
    },
  };
});

export const useBadgeStyles = createUseFormThemeStyles((style: FormTheme) => ({
  badge: {
    display: 'inline-block',
    borderRadius: style.badgeBorderRadius,
    border: `solid 1px transparent`,
    backgroundColor: 'white',
    color: '#ccc',
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    padding: '.35rem .35rem .2rem .35rem',
    fontSize: '.8rem',
  },
  badgeSuccess: {
    backgroundColor: style.badgeSuccessBackgroundColor,
    color: style.badgeSuccessTextColor,
  },
  badgeDanger: {
    backgroundColor: style.badgeDangerBackgroundColor,
    color: style.badgeDangerTextColor,
  },
}));

export const useInputStyles = createUseFormThemeStyles((style: FormTheme) => ({
  formControl: {
    borderRadius: style.formControlBorderRadius,
    borderColor: style.formControlBorderColor,
    borderWidth: style.formControlBorderWidth,
    '&:focus': {
      outline: style.formControlFocusedOutline,
      outlineOffset: style.formControlFocusedOutlineOffset,
      borderColor: style.formControlFocusedBorderColor,
      boxShadow: style.formControlFocusedBoxShadow,
    },
  },
}));

export const useCardStyles = createUseFormThemeStyles((style: FormTheme) => {
  const textColor = getTextColor(style.primaryColor, style.primaryTextColor);

  return {
    card: {
      border: `${style.formControlBorderWidth} solid ${style.formControlBorderColor}`,
      backgroundColor: '#fff',
      '&:first-child': {
        borderRadius: `${style.formControlBorderWidth} ${style.formControlBorderWidth} 0 0`,
      },
      '&:last-child': {
        borderRadius: `0 0 ${style.formControlBorderWidth} ${style.formControlBorderWidth}`,
      },
      '&:not(:last-child)': {
        borderBottom: 0,
      },
    },
    header: {
      padding: '.75rem 1.25rem',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outlineStyle: 'none',
        boxShadow: style.defaultButtonFocusedBoxShadow,
      },
    },
    headerSelected: {
      backgroundColor: style.primaryColor,
      border: 'none',
      color: textColor,
      '&:hover': {
        backgroundColor: style.primaryColor,
        color: textColor,
      },
      '&:focus': {
        outlineStyle: 'none',
        boxShadow: style.selectedButtonFocusedBoxShadow,
      },
    },
    body: {
      padding: '1.25rem',
    },
  };
});

export const useCheckboxStyles = createUseFormThemeStyles((style: FormTheme) => ({
  formControl: {
    '& label': {
      display: 'flex',
      margin: 0,
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  twoOptionGroup: {
    display: 'flex',
    flexDirection: style.radioButtonTwoItemGroupFlexDirection,
    '@media screen and (max-width:30em)': {
      flexDirection: 'column',
    },
    '& > *': {
      '& label': {
        marginRight: '1rem',
      },
      paddingRight: '1rem',
    },
  },
  labelChecked: {
    backgroundColor: style.checkboxSelectedLabelBackgroundColor,
    fontWeight: 'bold',
  },
  labelText: {
    padding: style.checkboxLabelPadding,
  },
  root: {
    zIndex: 0,
    color: style.checkboxFillColor,
    padding: `${style.checkboxPadding} !important`,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&.Mui-focusVisible .MuiIconButton-label': {
      boxShadow: style.checkboxFocusBoxShadow,
    },
    '& .MuiSvgIcon-root': {
      fontSize: style.checkboxSize,
    },
  },
  checked: {
    color: `${style.checkboxCheckedFillColor} !important`,
  },
}));
