import React from 'react';
import FormsTable from './FormsTable';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NavLinkButton from '../../Components/Buttons/NavLinkButton';
import { Can } from '../../AppContext/AuthenticatedUser/AbilityContext';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const Page = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <>
      <Can I="create" a="Form">
        <NavLinkButton
          variant="contained"
          color="primary"
          className={classes.button}
          to={`${path}/new`}
        >
          Create new
        </NavLinkButton>
      </Can>

      <FormsTable></FormsTable>
    </>
  );
};

export default Page;
