import { observer } from 'mobx-react';
import * as React from 'react';
import { InstanceStore } from '../../Components/InstanceStore';
import './Debug.css';
import InstanceRenderComponent from './InstanceRender.Component';
import LanguageSelector from './LanguageSelector';
import FontoProfilingTools from './FontoProfilingTools';
import { profiler } from '@airelogic/fontoxpath';

interface IProps {
  instanceStore: InstanceStore;
}

interface IState {
  isDisplayed: boolean;
  hasBeenClicked: boolean;
}

class DebugComponent extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isDisplayed:
        new URLSearchParams(window.location.search).get('xpathPerformance') === 'true'
          ? true
          : false,
      hasBeenClicked: false,
    };
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'F2') {
      event.preventDefault();
      this.setState((previous) => ({
        isDisplayed: !previous.isDisplayed,
        hasBeenClicked: true,
      }));
      if (!this.state.hasBeenClicked) {
        profiler.startProfiling();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  renderInstances() {
    return this.props.instanceStore.instances.map((instance) => (
      <InstanceRenderComponent key={instance.id} instance={instance} />
    ));
  }

  render() {
    return this.state.isDisplayed ? (
      <div id="debug">
        <div>Debug Tools</div>
        <LanguageSelector />
        <FontoProfilingTools />
        {this.renderInstances()}
      </div>
    ) : null;
  }
}

export default observer(DebugComponent);
