import axios, { AxiosError } from 'axios';
import { configure } from 'axios-hooks';
import axiosRetry from 'axios-retry';
import { history } from './App';

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      history.push('/accessdenied');
    }

    if (error.response?.status === 401) {
      const currentPath = window.location.pathname;
      const params = window.location.search;
      if (currentPath.startsWith('/auth/resetpassword')) {
        history.push(currentPath + params);
      } else if (currentPath !== '/auth/login') {
        history.push(`/auth/login?returnurl=${encodeURI(currentPath)}`);
      } else {
        history.push('/auth/login');
      }
    }

    if (error.response?.status === 500) {
      history.push('/error');
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request) => {
  request.withCredentials = true;
  return request;
});

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

// https://github.com/softonic/axios-retry/issues/138
const simpleNetworkErrorCheck = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return true;
  } else {
    return axiosRetry.isNetworkError(error);
  }
};

const configureAxios = () => {
  configure({
    cache: false,
    axios: axios,
  });

  axiosRetry(axios, {
    retries: 3,
    retryDelay,
    retryCondition: simpleNetworkErrorCheck,
  });
};

export default configureAxios;
