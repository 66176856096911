export const greaterThanSmall = '(min-width: 576px)';
export const greaterThanMedium = '(min-width: 768px)';
export const greaterThanLarge = '(min-width: 992px)';
export const greaterThanExtraLarge = '(min-width: 1200px)';

export const lessThanSmall = '(max-width: 575.98px)';
export const lessThanMedium = '(max-width: 767.98px)';
export const lessThanLarge = '(max-width: 991.98px)';
export const lessThanExtraLarge = '(max-width: 1199.98px)';

export const mediaBreakPoints = {
  greaterThanSmall: greaterThanSmall,
  greaterThanMedium: greaterThanMedium,
  greaterThanLarge: greaterThanLarge,
  greaterThanExtraLarge: greaterThanExtraLarge,
  lessThanSmall: lessThanSmall,
  lessThanMedium: lessThanMedium,
  lessThanLarge: lessThanLarge,
  lessThanExtraLarge: lessThanExtraLarge,
};
