import { Route, RouteProps } from 'react-router-dom';
import { Can } from './AppContext/AuthenticatedUser/AbilityContext';
import { FormManagementAbility } from './AppContext/AuthenticatedUser/AbilityBuilder';
import AccessDenied from './AccessDenied';
import { BoundCanProps } from '@casl/react';

type Props = Pick<RouteProps, 'children' | 'exact' | 'path'> & {
  can: BoundCanProps<FormManagementAbility>; //If you try to omit not, TS starts to complain in the component.
};

export const AuthorisedRoute = (props: Props) => {
  const { exact, path, children } = props;
  const { not, ...can } = props.can;

  return (
    <Route path={path} exact={exact}>
      <Can {...can}>{children}</Can>
      <Can not {...can}>
        <AccessDenied />
      </Can>
    </Route>
  );
};
