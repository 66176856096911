import useAxios from 'axios-hooks';
import { useCallback } from 'react';
import { BuildingBlock } from './BuildingBlock';

interface ImportRequest {
  orbeonId: string;
  newVersion: boolean;
}

interface PreviewRequest {
  orbeonId: string;
  buildingBlock: BuildingBlock;
}

const useRefetchForms = () => {
  return useAxios<BuildingBlock[]>('/internalapi/formbuilding/buildingblocks/available');
};

const usePostImport = () => {
  const [, postImport] = useAxios(
    {
      url: '/internalapi/formbuilding/buildingblocks/import',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  return useCallback(
    async (importRequest: ImportRequest) => {
      await postImport({
        data: importRequest,
      });
    },
    [postImport]
  );
};

const usePostPreview = () => {
  const [, postPreview] = useAxios(
    {
      url: '/internalapi/formbuilding/buildingblocks/preview',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  return useCallback(
    async (previewRequest: PreviewRequest) => {
      await postPreview({
        data: previewRequest,
      });
    },
    [postPreview]
  );
};

export { usePostImport, useRefetchForms, usePostPreview };
