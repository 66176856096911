import React from 'react';
import PreviewErrorBoundary from './PreviewErrorBoundary';
import { lazyRetry } from '@airelogic/forms';
const SinglePass = lazyRetry(() => import('./SinglePass/SinglePass'));
const ClinicianFacing = lazyRetry(() => import('./ClinicianFacing/ClinicianFacing'));

export const ClinicianPreview = () => {
  return (
    <PreviewErrorBoundary>
      <React.Suspense fallback={<div></div>}>
        <ClinicianFacing />
      </React.Suspense>
    </PreviewErrorBoundary>
  );
};

export const SinglePassPreview = () => {
  return (
    <PreviewErrorBoundary>
      <React.Suspense fallback={<div></div>}>
        <SinglePass />
      </React.Suspense>
    </PreviewErrorBoundary>
  );
};
