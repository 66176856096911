import React from 'react';
import { MenuItem, MenuItemProps, Menu, MenuProps, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuText: {
    color: 'white',
    fontSize: '16px',
    marginRight: 'auto',
  },
  menuIcon: {
    marginLeft: '10px',
  },
  menuOpenIcon: {
    color: '#33444E',
    '&:hover': {
      color: 'black',
    },
  },
  menuItemIcon: {
    color: 'white',
    marginLeft: '10px',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#3463FD',
    },
    borderRadius: '5px',
    color: 'white',
    margin: '2px',
  },
}));

export const ActionMenu = withStyles({
  paper: {
    background: '#33444E',
    padding: '3px',
    boxShadow: '0px 3px 6px #00000029',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    opacity: '1',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

interface ActionMenuItemProps {
  text: string;
  icon: object;
}

// MenuItemProps is being modified because of https://github.com/mui-org/material-ui/issues/16245
export const ActionMenuItem = React.forwardRef(
  (
    props: ActionMenuItemProps & MenuItemProps<'li', { button?: true }>,
    ref: React.Ref<HTMLLIElement>
  ): React.ReactElement => {
    const classes = useStyles();
    const { text, icon, button, ...rest } = props;

    return (
      <MenuItem className={classes.menuItem} {...rest}>
        <Box display="flex" flexDirection="row" width="100%">
          <Typography variant="h6" className={classes.menuText}>
            {text}
          </Typography>
          <Box className={classes.menuItemIcon}>{icon}</Box>
        </Box>
      </MenuItem>
    );
  }
);
