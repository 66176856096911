import { ButtonBase, Typography, Tooltip } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab';
import React, { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextInput from '../../../../Components/TextInput/TextInput';
import { FormSchema } from '../FormBuildingInterfaces';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import PageEditMenu from './PageEditMenu';
import { Row, Column } from '../../../../Components/FlexBoxes/FlexBoxes';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: '#3463FD',
    fontSize: 20,
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  verticalBox: {
    width: '100%',
    border: '1.5px solid',
    borderColor: '#EDF0F2',
    '&:hover': {
      background: '#EDF0F2',
    },
  },
  horizontalBox: {},
  tabText: {
    color: '#33444E',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '16px',
    whiteSpace: 'normal',
    overflow: 'hidden',
    height: '100%',
    textAlign: 'initial',
  },
  selected: {
    borderLeft: '3px solid',
    borderLeftColor: '#3463FD',
    background: '#EDF0F2',
  },
  unselected: {
    borderLeft: '3px solid',
    borderLeftColor: '#FFFFFF',
  },
  invisibleBox: {
    display: 'none',
  },
  errorIcon: {
    color: 'red',
    fontSize: 20,
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

export type FormsTabProps = TabProps & {
  index: number;
  onRemove: (index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  isLastTab: boolean;
};

export const FormsTab = React.forwardRef<HTMLButtonElement, FormsTabProps>((props, ref) => {
  const { selected, onChange, onClick, value, index, onRemove, onMoveUp, onMoveDown, isLastTab } =
    props;
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<FormSchema>();

  const pageTitleWatch = useWatch({
    control,
    name: `pages.${index}.title`,
  });

  const formNameWatch = useWatch({
    control,
    name: 'metadata.title',
    defaultValue: getValues('metadata.title'),
  });

  const [renderMode, setRenderMode] = React.useState<'input' | 'typography'>(
    pageTitleWatch === '' ? 'input' : 'typography'
  );
  const [firstRender, setFirstRender] = React.useState(true);

  const [triggerCurrentlyEditingTabName, setTriggerCurrentlyEditingTabName] = React.useState(false);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    setRenderMode('input');
  }, [triggerCurrentlyEditingTabName]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditPage = () => {
    setTriggerCurrentlyEditingTabName(!triggerCurrentlyEditingTabName);
  };

  return (
    <>
      <ButtonBase
        onClick={(event: any) => {
          if (!selected && onChange) {
            onChange(event, value);
          }

          if (onClick) {
            onClick(event);
          }
        }}
        ref={ref}
        {...(selected ? { className: classes.selected } : { className: classes.unselected })}
      >
        <Column justifyContent="center" width="100%" className={classes.verticalBox}>
          <Row height="100%" className={classes.horizontalBox}>
            <PageEditMenu
              index={index}
              onRemove={onRemove}
              disableRemove={isLastTab}
              onMoveUp={onMoveUp}
              onMoveDown={onMoveDown}
              onEditPage={onEditPage}
            ></PageEditMenu>
            <Controller
              control={control}
              name={`pages.${index}.title`}
              defaultValue={pageTitleWatch}
              render={({ field }) => (
                <>
                  {renderMode === 'input' ? (
                    <TextInput
                      id={`pages.${index}.title`}
                      autoFocus={formNameWatch !== ''}
                      onChange={field.onChange}
                      onBlur={(e) => {
                        field.onBlur();
                        setRenderMode('typography');
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          field.onBlur();
                          setRenderMode('typography');
                        }
                      }}
                      ref={field.ref}
                      defaultValue={pageTitleWatch}
                      required
                      label="Page title"
                      type="text"
                    />
                  ) : (
                    <Typography className={classes.tabText}>{pageTitleWatch}</Typography>
                  )}
                </>
              )}
            />

            {errors?.pages?.[index]?.title !== undefined ? (
              <Tooltip title={'The page title must not be empty'}>
                <ErrorIcon className={classes.errorIcon}></ErrorIcon>
              </Tooltip>
            ) : errors?.pages?.[index] !== undefined ? (
              <Tooltip title={'There is a validation error on this page'}>
                <ErrorIcon className={classes.errorIcon}></ErrorIcon>
              </Tooltip>
            ) : selected ? (
              <ArrowForwardIcon className={classes.icon}></ArrowForwardIcon>
            ) : (
              <></>
            )}
          </Row>
        </Column>
      </ButtonBase>
    </>
  );
});
export default FormsTab;
