import { FormTheme } from './FormTheme';
import AireLogicTheme from './AireLogic';
import { darken } from '@material-ui/core/styles';

const AireLogicCompactTheme = (primaryColor: string, primaryTextColor: string): FormTheme => {
  const baseTheme = AireLogicTheme(primaryColor, primaryTextColor);

  const highlightBorderColor = darken(baseTheme.highlightBackgroundColor, 0.2);
  const warningBorderColor = darken(baseTheme.warningBackgroundColor, 0.2);
  const dangerBorderColor = darken(baseTheme.dangerBackgroundColor, 0.2);
  const successBorderColor = darken(baseTheme.successBackgroundColor, 0.2);

  baseTheme.name = 'DefaultCompact';
  baseTheme.checkboxSize = '20px';
  baseTheme.checkboxLabelPadding = '5px 0';
  baseTheme.checkboxPadding = '4px 4px 6px 0';

  baseTheme.section = {
    ...baseTheme.section,
    boxShadow: '0 .125rem .25rem rgba(0,0,0,.15)',
    border: '1px solid #ddd',
    heading: {
      backgroundColor: '#eee',
      padding: '10px 0px 10px 15px',
      margin: 0,
      borderBottom: '1px solid #ddd',
    },
    content: {
      ...baseTheme.section.content,
      backgroundColor: '#F5F7F8',
    },
    strokes: {
      highlight: {
        borderColor: highlightBorderColor,
        heading: {
          backgroundColor: baseTheme.highlightBackgroundColor,
          borderBottomColor: highlightBorderColor,
        },
      },
      warning: {
        borderColor: warningBorderColor,
        heading: {
          backgroundColor: baseTheme.warningBackgroundColor,
          borderBottomColor: warningBorderColor,
        },
      },
      danger: {
        borderColor: dangerBorderColor,
        heading: {
          backgroundColor: baseTheme.dangerBackgroundColor,
          borderBottomColor: dangerBorderColor,
        },
      },
      success: {
        borderColor: successBorderColor,
        heading: {
          backgroundColor: baseTheme.successBackgroundColor,
          borderBottomColor: successBorderColor,
        },
      },
    },
  };

  return baseTheme;
};

export default AireLogicCompactTheme;
