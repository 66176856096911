import React from 'react';
import useAxios from 'axios-hooks';
import { LoadingSpinner } from '@airelogic/forms';
import { BuildingBlockVersionData, createEmptyForm } from '../FormBuildingInterfaces';
import { FormEdit } from '../FormEdit/FormEdit';

const CreateNewPage = () => {
  const [{ data, loading }] = useAxios<BuildingBlockVersionData[]>({
    url: '/internalapi/FormBuilding/forms/buildingblocks',
  });

  if (loading) {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (data) {
    if (!data.find((x) => x.key === '')) {
      const blankBuildingBlock: BuildingBlockVersionData = {
        key: '',
        versions: [],
      };
      data.unshift(blankBuildingBlock);
    }
  }

  return (
    <FormEdit blockVersions={data ?? []} editorMode={'new'} currentValues={createEmptyForm()} />
  );
};

export default CreateNewPage;
