import { Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    strokeButton: {
      marginLeft: '4px',
      marginRight: '4px',
      height: '35px',
      width: '35px',
      padding: theme.spacing(0.5),
      marginTop: 'auto',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0)',
        '& .hoverState': { stroke: theme.palette.info.light },
      },
      '& .hoverState': { stroke: theme.palette.primary.main },
    },
    fillButton: {
      marginLeft: '4px',
      marginRight: '4px',
      height: '35px',
      width: '35px',
      padding: theme.spacing(0.5),
      marginTop: 'auto',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0)',
        '& .hoverState': { fill: theme.palette.info.light },
      },
      '& .hoverState': { fill: theme.palette.primary.main },
    },
    icon: {
      fontSize: '30px',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.info.light,
      },
    },
  };
});
