import Control from './Control';
import { ICellDefinition } from '../Definitions/ICellDefinition';

export default class Cell {
  constructor(private readonly definition: ICellDefinition, public readonly control?: Control) {}

  public get cellSpan(): number {
    return this.definition.cellSpan;
  }
}
