import * as React from 'react';
import { ForgotPassword } from './ForgotPassword';

export default function ForgotPasswordPage() {
  //const classes = useStyles();
  return (
    <>
      <ForgotPassword />
    </>
  );
}
