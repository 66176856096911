import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { CardContent, Card, Button, Grid } from '@material-ui/core';
import { CallToActionSettings, ExistingData, Form } from './Types';
import Skeleton from '@material-ui/lab/Skeleton';
import TextInput from '../Components/TextInput/TextInput';
import SelectInput from '../Components/SelectInput/SelectInput';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useStyles } from './SubmissionSettings.styles';
import { validate as emailValidator } from 'email-validator';
import { When } from '../Components/When/When';
import { uniqBy } from 'lodash';

type Settings = Pick<ExistingData, 'destinationEmail' | 'callToActionSettings'>;

interface Props {
  publishedForms: Form[];
  updateSettings: (callToActionSettings: CallToActionSettings, deliveryEmail: string) => void;
  loading: boolean;
  existingData?: Settings;
}

interface FormValues {
  fieldDescription: string;
  fieldDeliveryEmail: string;
  fieldSelectedForm: string;
}
const validateEmail = (email: string) => {
  if (emailValidator(email) === false) {
    return 'E-mail is not in a valid format';
  }

  return true;
};

const createFormValues = (existingData?: Settings): FormValues => {
  return existingData
    ? {
        fieldDescription: existingData.callToActionSettings?.description ?? '',
        fieldSelectedForm: existingData.callToActionSettings
          ? existingData.callToActionSettings.formKey
          : 'none',
        fieldDeliveryEmail: existingData.destinationEmail,
      }
    : {
        fieldDescription: '',
        fieldDeliveryEmail: '',
        fieldSelectedForm: 'none',
      };
};

const SubmissionSettings = ({ publishedForms, existingData, updateSettings, loading }: Props) => {
  const classes = useStyles();

  const defaultValues: FormValues = createFormValues(existingData);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({ defaultValues: defaultValues });

  useEffect(() => {
    reset(createFormValues(existingData));
  }, [reset, existingData]);

  const onSubmit = (data: FormValues) => {
    const callToActionSettings: CallToActionSettings = {
      formKey: data.fieldSelectedForm,
      description: data.fieldDescription,
    };

    updateSettings(callToActionSettings, data.fieldDeliveryEmail);
  };

  const selectedFormWatch = useWatch({
    control,
    name: `fieldSelectedForm`,
  });

  const getDescriptionFromKey = (key: string): string => {
    if (existingData && existingData.callToActionSettings) {
      return key === existingData.callToActionSettings.formKey
        ? existingData.callToActionSettings.description
        : '';
    }
    return '';
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" className={classes.headerText}>
          Submission Settings
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              {!loading ? (
                <TextInput
                  id="deliveryEmail"
                  type="email"
                  required
                  error={errors.fieldDeliveryEmail !== undefined}
                  helperText={
                    errors.fieldDeliveryEmail?.message ??
                    'The email address of the inbox to receive submitted forms.'
                  }
                  {...register('fieldDeliveryEmail', {
                    required: { value: true, message: 'Must not be empty' },
                    validate: (value) => validateEmail(value),
                  })}
                  label="Delivery email address"
                />
              ) : (
                <Skeleton variant="rect" className={classes.textFieldSkeleton} />
              )}
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.headerText}>
            Form launcher settings
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              {!loading ? (
                <Controller
                  control={control}
                  name={'fieldSelectedForm'}
                  render={({ field }) => (
                    <SelectInput
                      id="fieldSelectedForm"
                      label={'Select call to action form'}
                      required
                      helperText={
                        'The call to action form will display at the top of the form launcher.'
                      }
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(
                          'fieldDescription',
                          getDescriptionFromKey(e.target.value as string)
                        );
                      }}
                    >
                      <option value="none">None</option>
                      {uniqBy(publishedForms, (form) => form.key).map(({ key }) => (
                        <option value={key} key={`${key}`}>
                          {key}
                        </option>
                      ))}
                    </SelectInput>
                  )}
                />
              ) : (
                <Skeleton variant="rect" className={classes.textFieldSkeleton} />
              )}
            </Grid>
            <Grid item sm={6}>
              <When condition={selectedFormWatch !== 'none'}>
                <TextInput
                  id={'fieldDescription'}
                  label="Enter a description for your call to action form"
                  error={errors.fieldDescription !== undefined}
                  helperText={errors.fieldDescription?.message}
                  defaultValue={getDescriptionFromKey(selectedFormWatch)}
                  {...register('fieldDescription', { required: false })}
                />
              </When>
            </Grid>
          </Grid>
          <Button variant="outlined" className={classes.button} type="submit">
            Save
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default SubmissionSettings;
