import { observer } from 'mobx-react';
import React from 'react';
import { useInputStyles } from '../../Styles';
import { IControlProps } from './IControlProps';
import { useTranslation } from 'react-i18next';

export const otherValueIdentifier: string = 'other-value-AC26B535-7C5F-4510-912D-F270E3B801A4';

const OtherControl: React.FC<IControlProps> = observer(({ control }) => {
  const classes = useInputStyles();
  const { t } = useTranslation();

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => control.markAsTouched();
  const otherInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    control.updateOtherValue(e.target.value);

  const getOtherInput = () => {
    return (
      <div className="row">
        <div className="col-sm">
          <input
            aria-label={t('otherControl.other')}
            className={`${classes.formControl} form-control other-input`}
            onChange={otherInputChange}
            onBlur={onBlur}
            value={control.otherValue || ''}
          />
        </div>
      </div>
    );
  };

  return control.isWithOther && control.itemset!.otherOptionIsSelected ? getOtherInput() : null;
});

export default OtherControl;
