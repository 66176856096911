import { createContext, useContext, useEffect, useState } from 'react';
import { AuthenticatedUser } from './AuthenticatedUser/AuthenticatedUser';
import useAxios from 'axios-hooks';

interface Context {
  readonly orbeonHost: string;
  readonly authenticatedUser: AuthenticatedUser;
}

interface IAppDetails {
  readonly orbeonHost: string;
  readonly authenticatedUser?: AuthenticatedUser;
}
interface IAppContext extends IAppDetails {
  reloadContext: () => Promise<void>;
  clearContext: () => void;
}

const defaultAppDetails: IAppDetails = {
  orbeonHost: '',
};

const AppContext = createContext<IAppContext>({
  ...defaultAppDetails,
  reloadContext: () => Promise.reject(),
  clearContext: () => {},
});

export const useApplicationContext = () => {
  return useContext(AppContext);
};

export const ApplicationContextProvider: React.FC = ({ children }) => {
  const [{ data }, refetch] = useAxios<Context>({
    baseURL: '/internalapi/context',
  });

  const [appDetails, setAppDetails] = useState<IAppDetails>(defaultAppDetails);

  useEffect(() => {
    if (data) {
      setAppDetails(data);
    }
  }, [data]);

  const reloadContext = async () => {
    await refetch();
  };

  const clear = () => {
    setAppDetails(defaultAppDetails);
  };

  return (
    <AppContext.Provider
      value={{
        ...appDetails,
        reloadContext: reloadContext,
        clearContext: clear,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
