import MaterialTable, { Column } from '@material-table/core';
import MaterialTableIcons from '../../MaterialTableIcons';
import { ExistingReferenceData } from './Types';
import useAxios from 'axios-hooks';
import EditIcon from '../../Components/IconButtons/EditIcon';
import NewVersionIcon from '../../Components/IconButtons/NewVersionIcon';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { When } from '../../Components/When/When';
import { useCallback } from 'react';

const Table = () => {
  const [{ data, loading }] = useAxios<ExistingReferenceData[]>(
    '/internalapi/formbuilding/referencedata'
  );
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleEditClick = useCallback(
    (referenceData: ExistingReferenceData) => {
      history.push(`${path}/edit/${referenceData.key}/${referenceData.version}`);
    },
    [history, path]
  );

  const handleNewVersionClick = useCallback(
    (referenceData: ExistingReferenceData) => {
      history.push(`${path}/new/${referenceData.key}`);
    },
    [history, path]
  );

  const actionButtons = useCallback(
    (rowData) => {
      return (
        <>
          <EditIcon
            aria-label={`edit-${rowData.key}`}
            tooltipText={`Edit version ${rowData.version}`}
            onClick={() => {
              handleEditClick(rowData);
            }}
          ></EditIcon>
          <When condition={rowData.isLatest}>
            <NewVersionIcon
              aria-label={`new-version-of-${rowData.key}`}
              tooltipText={'Create new version'}
              onClick={() => {
                handleNewVersionClick(rowData);
              }}
            ></NewVersionIcon>
          </When>
        </>
      );
    },
    [handleEditClick, handleNewVersionClick]
  );

  const columns: Column<ExistingReferenceData>[] = [
    {
      title: 'Key',
      field: 'key',
    },
    { title: 'Version', field: 'version' },
    {
      title: 'Actions',
      sorting: false,
      render: actionButtons,
    },
  ];

  return (
    <MaterialTable<ExistingReferenceData>
      icons={MaterialTableIcons}
      isLoading={loading}
      title="Reference data"
      columns={columns}
      data={data ?? []}
      options={{
        search: true,
        pageSize: 20,
        pageSizeOptions: [10, 20, 50],
      }}
    />
  );
};

export default Table;
