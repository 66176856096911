import ErrorPage from './Page';
import { Route, useRouteMatch } from 'react-router';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route path={`${match.path}`} component={ErrorPage} />
    </>
  );
};

export default Routing;
