import { Node, Element, Attr, CharacterData } from 'slimdom';
import { IDomFacade } from '@airelogic/fontoxpath';

const extractName = (bucket?: string | null) => {
  return bucket?.substring('name-'.length); //name-{nodename}
};

//This essentially creates the requested nodes/attributes on the fly as we dont have a document to run the xpath against
export const validationDomFacade: IDomFacade = {
  getAllAttributes: function (node: Element, bucket?: string | null): Attr[] {
    const newName = extractName(bucket);

    if (newName === undefined || node.nodeName === newName) return [];

    return [new Attr(null, null, newName, '', null)];
  },
  getAttribute: function (node: Element, attributeName: string): string | null {
    return null;
  },
  getChildNodes: function (node: Node, bucket?: string | null): Node[] {
    const newName = extractName(bucket);
    if (newName === undefined || node.nodeName === newName) return [];

    return [new Element(null, null, newName)];
  },
  getData: function (node: Attr | CharacterData): string {
    return '';
  },
  getFirstChild: function (node: Node, bucket?: string | null): Node | null {
    return null;
  },
  getLastChild: function (node: Node, bucket?: string | null): Node | null {
    return node;
  },
  getNextSibling: function (node: Node, bucket?: string | null): Node | null {
    return null;
  },
  getParentNode: function (node: Node, bucket?: string | null): Node | null {
    const newName = extractName(bucket);

    if (newName === undefined || node.nodeName === newName) return null;

    return new Element(null, null, newName);
  },
  getPreviousSibling: function (node: Node, bucket?: string | null): Node | null {
    return null;
  },
};
