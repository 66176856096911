import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      margin: theme.spacing(2),
      width: '40%',
    },
    addButton: {
      color: '#3463FD',
    },
    actionButton: {
      color: 'white',
      background: '#3463FD',
      margin: '3px',
    },
    deleteButton: {
      color: 'black',
      margin: '3px',
    },
    addBBPaper: {
      margin: theme.spacing(3),
      minHeight: 50,
    },
    scrollingPaper: {
      margin: theme.spacing(3),
      paddingBottom: theme.spacing(0),
      background: '#EDF0F2',
      borderColor: '#EDF0F2',
      border: '10px solid',
      maxHeight: 700,
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    helpIcon: {
      color: '#889AA5',
    },
    paperHeader: {
      borderBottom: 'solid 1px #33444E1A',
      paddingLeft: '10px',
    },
    headerText: {
      marginRight: 'auto',
    },
    blockList: {
      listStyle: 'none',
      paddingLeft: 0,
    },
  })
);
