import { BuildingBlock, ImportStatus } from './BuildingBlock';
import MaterialTable, { Column } from '@material-table/core';
import MaterialTableIcons from '../../MaterialTableIcons';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '../../Components/IconButtons/EditIcon';
import NewVersionIcon from '../../Components/IconButtons/NewVersionIcon';
import PreviewIcon from '../../Components/IconButtons/PreviewIcon';
import ImportIcon from '../../Components/IconButtons/ImportIcon';
import { createPreviewUrl } from '../../UrlHelper';
import { When } from '../../Components/When/When';
import { usePostImport, usePostPreview, useRefetchForms } from './Service';
import { useCallback } from 'react';

interface Props {
  orbeonHost: string;
  onImportComplete: (buildingBlock: BuildingBlock) => void;
}

const importStatusLookup: Record<ImportStatus, string> = {
  UNKNOWN: 'Unable to determine status',
  NOT_IMPORTED: 'New',
  UPDATE_AVAILABLE: 'Update available',
  UP_TO_DATE: 'Up to date',
};

const Table = ({ orbeonHost, onImportComplete }: Props) => {
  const [{ data, loading }, refetchAvailable] = useRefetchForms();

  const postImport = usePostImport();
  const postPreview = usePostPreview();

  const onRefresh = async () => {
    await refetchAvailable();
  };

  const handlePreviewClick = useCallback(
    async (buildingBlock: BuildingBlock) => {
      await postPreview({
        orbeonId: buildingBlock.orbeonId,
        buildingBlock: buildingBlock,
      });

      const previewUrl = createPreviewUrl('document', 'preview-form', 1);
      window.open(previewUrl, '_blank');
    },
    [postPreview]
  );

  const handleEditClick = useCallback(
    async (buildingBlock: BuildingBlock) => {
      const host = new URL(orbeonHost);
      host.pathname =
        '/orbeon/fr/orbeon/builder/edit/' + encodeURIComponent(buildingBlock.orbeonId);
      window.open(host.href, '_blank');
    },
    [orbeonHost]
  );

  const HandleImport = useCallback(
    async (buildingBlock: BuildingBlock, isNewVersion: boolean) => {
      await postImport({
        orbeonId: buildingBlock.orbeonId,
        newVersion: isNewVersion,
      });
      onImportComplete(buildingBlock);
      await refetchAvailable();
    },
    [postImport, refetchAvailable, onImportComplete]
  );

  const handleImportClick = useCallback(
    async (buildingBlock: BuildingBlock) => {
      await HandleImport(buildingBlock, true);
    },
    [HandleImport]
  );

  const handleImportOverrideClick = useCallback(
    async (buildingBlock: BuildingBlock) => {
      await HandleImport(buildingBlock, false);
    },
    [HandleImport]
  );

  const actionButtons = useCallback(
    (rowData: BuildingBlock) => (
      <>
        <PreviewIcon
          aria-label={`preview-${rowData.blockKey}`}
          tooltipText={
            rowData.latestVersion
              ? `Preview '${rowData.title}' version ${rowData.latestVersion}`
              : `Preview '${rowData.title}'`
          }
          onClick={() => {
            handlePreviewClick(rowData);
          }}
        ></PreviewIcon>
        <EditIcon
          aria-label={`edit-${rowData.blockKey}`}
          tooltipText={rowData.latestVersion ? `Edit version ${rowData.latestVersion}` : `Edit`}
          onClick={() => {
            handleEditClick(rowData);
          }}
        ></EditIcon>
        <When condition={rowData.importStatus === 'NOT_IMPORTED'}>
          <ImportIcon
            aria-label={`import-${rowData.blockKey}`}
            tooltipText={'Import'}
            onClick={() => {
              handleImportClick(rowData);
            }}
          ></ImportIcon>
        </When>
        <When
          condition={
            rowData.importStatus === 'UNKNOWN' || rowData.importStatus === 'UPDATE_AVAILABLE'
          }
        >
          <NewVersionIcon
            aria-label={`new-version-of-${rowData.blockKey}`}
            tooltipText={`Create new version`}
            onClick={() => {
              handleImportClick(rowData);
            }}
          ></NewVersionIcon>
          <ImportIcon
            aria-label={`overwrite-${rowData.blockKey}`}
            tooltipText={`Overwrite current version`}
            onClick={() => {
              handleImportOverrideClick(rowData);
            }}
          ></ImportIcon>
        </When>
      </>
    ),
    [handleEditClick, handleImportClick, handleImportOverrideClick, handlePreviewClick]
  );

  const columns: Column<BuildingBlock>[] = [
    {
      title: 'Key',
      field: 'blockKey',
      filtering: false,
      width: '20%',
    },
    {
      title: 'Version',
      field: 'latestVersion',
      filtering: false,
      width: '5%',
    },
    {
      title: 'Title',
      field: 'title',
      filtering: false,
      width: '20%',
    },
    {
      title: 'Application',
      field: 'applicationName',
      filtering: true,
      width: '20%',
    },
    {
      title: 'Last modified',
      field: 'lastModifiedDate',
      type: 'date',
      defaultSort: 'desc',
      filtering: false,
      width: '5%',
    },
    {
      title: 'Status',
      field: 'importStatus',
      lookup: importStatusLookup,
      width: '10%',
    },
    {
      title: 'Actions',
      width: '20%',
      sorting: false,
      render: actionButtons,
    },
  ];

  return (
    <>
      <MaterialTable<BuildingBlock>
        icons={MaterialTableIcons}
        title="Building Blocks"
        isLoading={loading}
        columns={columns}
        data={data ?? []}
        options={{
          search: true,
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
        }}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: onRefresh,
          },
        ]}
      />
    </>
  );
};

export default Table;
