import { ButtonProps, Button } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './Styles';

const ExternalLinkButton = (
  props: Pick<ButtonProps<'a'>, 'color' | 'children' | 'className' | 'href' | 'variant'>
) => {
  const classes = useStyles({ color: props.color });

  return (
    <Button
      component={'a'}
      {...props}
      className={classNames(props.className, classes.link)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Button>
  );
};

export default ExternalLinkButton;
