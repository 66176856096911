import queryString, { ParsedQuery } from 'query-string';

export default class QueryConfigParams {
  private readonly parsedParams: ParsedQuery;
  constructor(search: string) {
    this.parsedParams = queryString.parse(search);
  }

  public get hideBanner(): boolean {
    return this.parsedParams['hideBanner'] === 'true';
  }

  public get hideFooter(): boolean {
    return this.parsedParams['hideFooter'] === 'true';
  }

  public get hideNavigation(): boolean {
    return this.parsedParams['hideNavigation'] === 'true';
  }
}
