import * as React from 'react';
import { Login } from './Login';

export default function LoginPage() {
  return (
    <>
      <Login />
    </>
  );
}
