import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      borderRadius: 4,
      marginBottom: '20px',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    headerText: {
      fontSize: '20px',
      marginRight: 'auto',
      marginBottom: '10px',
      marginTop: '10px',
    },
    button: {
      marginTop: '10px',
      color: 'white',
      borderColor: '#3463FD;',
      background: '#3463FD',
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        color: '#FFFFFF',
        background: 'rgb(36, 69, 177)',
        borderColor: 'rgb(36, 69, 177)',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    textFieldSkeleton: {
      height: '56px',
      borderRadius: '4px',
    },
  })
);
