/*
 * This file was taken from https://github.com/wvbe/slimdom-sax-parser
 * The current package does not work on IE11....
 */

import * as sax from 'sax';
import { Document, Node } from 'slimdom';

const defaultNamespaceMapping = {
  '': null,
  xml: 'http://www.w3.org/XML/1998/namespace',
};

function createHandler() {
  const doc = new Document();

  // Is rewritten as the handler traverses in and out of nodes
  let dom: any = doc;

  // Rewritten to accumulate a text stream
  let cdata: any = null;

  const namespaces = [defaultNamespaceMapping];
  let currentNamespaces = Object.create(defaultNamespaceMapping);

  return {
    onText: (text: string) => {
      if (dom.nodeType === Node.DOCUMENT_NODE) {
        // Do not add text directly to document node (aka. outside document element)
        return;
      }
      dom.appendChild(doc.createTextNode(text));
    },

    onOpenTag: (node: any) => {
      namespaces.push(node.ns);
      currentNamespaces = Object.assign(currentNamespaces, node.ns);

      if (currentNamespaces[node.prefix] === undefined) {
        throw new Error(`Namespace prefix "${node.prefix}" not known for element "${node.name}"`);
      }

      dom = dom.appendChild(doc.createElementNS(currentNamespaces[node.prefix], node.name));

      // Set attributes, taking the accumulated namespace information into account
      Object.keys(node.attributes)
        .map((name) => node.attributes[name])
        .filter((attr) => attr.prefix !== 'xmlns' && !(attr.prefix === '' && attr.name === 'xmlns'))
        .forEach((attr) => {
          if (currentNamespaces[attr.prefix] === undefined) {
            throw new Error(
              `Namespace prefix "${attr.prefix}" not known for attribute "${attr.name}"`
            );
          }

          dom.setAttributeNS(
            attr.prefix === '' ? null : currentNamespaces[attr.prefix],
            attr.name,
            attr.value
          );
        });
    },

    onCloseTag: () => {
      dom = dom.parentNode;

      if (!namespaces.pop()) {
        // The namespace info for the level that is popped was empty, so exit early
        return;
      }

      // Recalculate the (subset) portion of known namespace information
      currentNamespaces = namespaces.reduce((accum, ns) => Object.assign(accum, ns), {});
    },

    onProcessingInstruction: (pi: any) => {
      dom.appendChild(doc.createProcessingInstruction(pi.name, pi.body));
    },

    onComment: (comment: any) => {
      dom.appendChild(doc.createComment(comment));
    },

    onDocType: (data: any) => {
      const [qualifiedName, publicId, systemId] = data.match(/(?:[^\s"]+|"[^"]*")+/g);

      dom.appendChild(
        doc.implementation.createDocumentType(
          qualifiedName,
          // eslint-disable-next-line
          (publicId && publicId.replace(/^"(.*)"$/, '$1')) || '',
          // eslint-disable-next-line
          (systemId && systemId.replace(/^"(.*)"$/, '$1')) || ''
        )
      );
    },

    onOpenCdata: () => {
      cdata = '';
    },

    onCdata: (string: any) => {
      cdata += string;
    },

    onCloseCdata: () => {
      dom.appendChild(doc.createCDATASection(cdata));
      cdata = null;
    },

    getDocument: () => {
      return doc;
    },
  };
}

export default function parseXml(xml: string): Document {
  const handler = createHandler();

  // Set up the sax parser
  const parser = sax.parser(true, {
    xmlns: true,
  });

  parser.ontext = handler.onText;
  parser.onopentag = handler.onOpenTag;
  parser.onclosetag = handler.onCloseTag;
  parser.onprocessinginstruction = handler.onProcessingInstruction;
  parser.oncomment = handler.onComment;
  parser.ondoctype = handler.onDocType;
  parser.onopencdata = handler.onOpenCdata;
  parser.oncdata = handler.onCdata;
  parser.onclosecdata = handler.onCloseCdata;

  parser.write(xml).close();

  return handler.getDocument();
}
