import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './Styles';

const StyledButton = React.forwardRef(
  (props: Omit<ButtonProps, 'variant'>, ref): React.ReactElement => {
    const { ...rest } = props;

    const classes = useStyles({
      color: props.color,
    });

    return (
      <Button variant="outlined" className={classes.button} {...rest}>
        {props.children}
      </Button>
    );
  }
);

export default StyledButton;
