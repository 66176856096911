import MaterialTable, { Column } from '@material-table/core';
import MaterialTableIcons from '../MaterialTableIcons';
import { Button } from '@material-ui/core';
import { Form } from './Types';
import Checkbox from '@material-ui/core/Checkbox';
import TextInput from '../Components/TextInput/TextInput';
import { useForm, useFieldArray } from 'react-hook-form';
import { useStyles } from './FormsTable.styles';
import { validate as validateEmail } from 'email-validator';
import { useCallback, useEffect } from 'react';

interface Props {
  loading: boolean;
  existingForms: Form[];
  updateTableData: (
    formKey: string,
    formVersion: number,
    overrideEmail: string | null,
    publish: boolean | null
  ) => void;
}

type IFormData = {
  availableForms: Form[];
};
interface TableAvailableForm extends Form {
  id: string;
  tableData: {
    id: number;
  };
}

const Table = ({ loading, existingForms, updateTableData }: Props) => {
  const classes = useStyles();
  const {
    control,
    register,
    formState: { errors },
    setError,
    reset,
    getValues,
  } = useForm<IFormData>({
    defaultValues: {
      availableForms: existingForms,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'availableForms',
    keyName: 'uuid',
  });

  useEffect(() => {
    reset({
      availableForms: existingForms,
    });
  }, [reset, existingForms]);

  const validateOverrideEmails = useCallback(
    (previousVal: string, index: number) => {
      const availableForms = getValues('availableForms');
      if (availableForms !== undefined) {
        const currentFormOverrideEmail = availableForms[index]['deliveryOverride'];
        const formKey = availableForms[index]['key'];
        const formVersion = availableForms[index]['version'];
        if (!validateEmail(currentFormOverrideEmail) && currentFormOverrideEmail !== '') {
          setError(`availableForms.${index}.deliveryOverride` as const, {
            type: 'server',
            message: 'Email not valid',
          });
        } else {
          if (currentFormOverrideEmail !== previousVal) {
            updateTableData(formKey, formVersion, currentFormOverrideEmail, null);
          }
        }
      }
    },
    [getValues, setError, updateTableData]
  );

  const renderCheckbox = useCallback(
    (form: TableAvailableForm) => (
      <Checkbox
        key={form.id}
        {...register(`availableForms.${form.tableData.id}.published`)}
        defaultChecked={form.published}
        onChange={(_, checked) =>
          updateTableData(form.key, form.version, form.deliveryOverride, checked)
        }
        data-testid={`${form.key} ${form.version}`}
        color="primary"
      />
    ),
    [updateTableData, register]
  );

  const renderDeliveryAddress = useCallback(
    (form: TableAvailableForm) => (
      <TextInput
        type="email"
        error={errors?.availableForms?.[form.tableData.id]?.deliveryOverride !== undefined}
        helperText={errors?.availableForms?.[form.tableData.id]?.deliveryOverride?.message}
        key={form.id}
        {...register(`availableForms.${form.tableData.id}.deliveryOverride`)}
        onBlur={() => {
          validateOverrideEmails(form.deliveryOverride, form.tableData.id);
        }}
        defaultValue={form.deliveryOverride} //This shouldn't be required since 7.10 of RHF but for some reason it wont work without it
        label="Delivery email address"
        disabled={!form.published}
        tooltip={!form.published && 'Form is not published'}
      />
    ),
    [validateOverrideEmails, register, errors?.availableForms]
  );

  const previewButton = useCallback(
    (rowData: TableAvailableForm) => (
      <Button
        variant="outlined"
        className={classes.button}
        onClick={() => window.open(`/SinglePassDemo/${rowData.key}/${rowData.version}`, '_blank')}
      >
        Preview
      </Button>
    ),
    [classes.button]
  );

  const columns: Column<Form>[] = [
    {
      title: 'Key',
      field: 'key',
    },
    { title: 'Version', field: 'version' },
    { title: 'Type', field: 'classification' },
    {
      title: 'Published',
      render: renderCheckbox,
    },
    {
      title: 'Delivery address override',
      sorting: false,
      render: renderDeliveryAddress,
    },
    {
      title: 'Actions',
      sorting: false,
      render: previewButton,
    },
  ];

  return (
    <form noValidate>
      <MaterialTable<Form>
        isLoading={loading}
        icons={MaterialTableIcons}
        title="Forms"
        columns={columns}
        data={fields ?? []}
        options={{
          search: true,
          pageSize: 20,
          pageSizeOptions: [10, 20, 50],
        }}
      />
    </form>
  );
};

export default Table;
