import { observer } from 'mobx-react';
import { LiveAnnouncer } from 'react-aria-live';
import { createBrowserHistory } from 'history';
import './App.css';
import { UserAbilityProvider } from './AppContext/AuthenticatedUser/AbilityContext';
import { ApplicationContextProvider } from './AppContext/AppContext';
import { theme } from './Theme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Routing from './Routing';

export const history = createBrowserHistory();

const App = observer(() => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApplicationContextProvider>
        <UserAbilityProvider>
          <LiveAnnouncer>
            <Routing history={history} />
          </LiveAnnouncer>
        </UserAbilityProvider>
      </ApplicationContextProvider>
    </ThemeProvider>
  );
});

export default App;
