import { useStyles } from './FormEdit.styles';
import { Prompt } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Box, Typography, Grid, Tabs } from '@material-ui/core';
import { SubmitHandler, useFieldArray, useForm, FormProvider, useFormState } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { BuildingBlockVersionData, schema, FormSchema } from '../FormBuildingInterfaces';
import PageEdit from '../PageEdit/PageEdit';
import MetadataEdit from '../MetadataEdit/MetadataEdit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormsTab } from '../Tabs/FormsTab';
import { TabPanel, a11yProps } from '../../../../Components/Tabs/TabPanel';
import { buildForm, buildFormPreview } from '../FormBuilding.Service';
import StyledButton from '../../../../Components/Buttons/StyledButton';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from '../../../../Components/Dialogs/ConfirmationDialog';
import { createPreviewUrl } from '../../../../UrlHelper';
import { zodResolver } from '@hookform/resolvers/zod';

export type EditorMode = 'new' | 'edit' | 'newversion';

type Props = {
  blockVersions: BuildingBlockVersionData[];
  editorMode: EditorMode;
  currentValues: FormSchema;
};

export const FormEdit = ({ blockVersions, editorMode, currentValues }: Props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [headerText] = React.useState(() => {
    switch (editorMode) {
      case 'new':
        return 'Create Form';
      case 'edit':
        return 'Edit Form';
      case 'newversion':
        return 'New Version';
    }
  });
  const history = useHistory();
  const formMethods = useForm<FormSchema>({
    defaultValues: currentValues,
    resolver: zodResolver(schema),
  });
  const [showDiscardFormConfirmation, setShowDiscardFormConfirmation] = useState<boolean>(false);
  const [canNavigateAway, setCanNavigateAway] = useState(false);
  const { control, handleSubmit, getValues, setError, trigger } = formMethods;

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'pages',
  });

  const { isSubmitting, isDirty } = useFormState({
    control,
  });

  // if new Form, post without key or version
  // if updating version, post with key and version
  // if adding new version, post with only key
  const generatePostUrl = (): string => {
    switch (editorMode) {
      case 'new':
        return `/internalapi/FormBuilding/forms`;
      case 'edit':
        return `/internalapi/FormBuilding/forms/${currentValues.metadata.key}/${currentValues.metadata.version}`;
      case 'newversion':
        return `/internalapi/FormBuilding/forms/${currentValues.metadata.key}`;
    }
  };

  const [, executeSave] = useAxios(
    {
      url: generatePostUrl(),
      method: editorMode === 'edit' ? 'PUT' : 'POST',
    },
    {
      manual: true,
    }
  );

  const onSubmit: SubmitHandler<FormSchema> = async (formData) => {
    const response = await executeSave({
      data: buildForm(formData),
      validateStatus: (statusCode) => {
        return statusCode === 409 || statusCode === 200;
      },
    });

    if (response.status === 409) {
      setError('metadata.key', {
        type: 'manual',
        message: 'This form key is already in use',
      });
    }

    if (response.status === 200) {
      setCanNavigateAway(true);
    }
  };

  const [tabsToBeDeleted, setTabsToBeDeleted] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (tabsToBeDeleted !== undefined) {
      if (tabsToBeDeleted > 0) {
        setTabValue(tabsToBeDeleted - 1);
      } else {
        setTabValue(0);
      }
      remove(tabsToBeDeleted);
      setTabsToBeDeleted(undefined);
    }
  }, [tabsToBeDeleted, remove]);

  const generatePreview = async () => {
    if (!(await trigger())) {
      return;
    }

    const formInput = getValues();

    const response = await executePreview({
      data: buildFormPreview(formInput),
    });

    if (response.status === 200) {
      const previewLink = createPreviewUrl(
        formInput.metadata.layout === 'clinician' ? 'document' : 'wizard',
        'preview-form',
        1
      );
      window.open(previewLink, '_blank');
    }
  };

  const [, executePreview] = useAxios(
    {
      url: `/internalapi/FormBuilding/forms/generatepreview`,
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  const handleChange = (_event: ChangeEvent<{ checked: boolean }>, value: any) => {
    setTabValue(value);
  };

  const handleCreateNewPage = () => {
    append({
      title: '',
      buildingBlocks: [
        {
          key: '',
          version: 0,
          view: 'inherit',
          pdfReadVisibility: 'alwaysvisible',
          relevance: 'alwaysdisplay',
          customRule: '',
          prePopulationRule: '',
        },
      ],
    });

    setTabValue(fields.length);
  };

  const handleRemovePage = (pageIndex: number) => {
    if (fields.length > 1) {
      setTabsToBeDeleted(pageIndex);
    }
  };

  const handleMovePagePositionUp = (index: number) => {
    if (fields.length > 1) {
      if (index > 0) {
        move(index, index - 1);
        setTabValue(index - 1);
      }
    }
  };

  const handleMovePagePositionDown = (index: number) => {
    if (fields.length > 1) {
      if (index < fields.length - 1) {
        move(index, index + 1);
        setTabValue(index + 1);
      }
    }
  };

  const navBackToForms = () => {
    setShowDiscardFormConfirmation(false);
    setCanNavigateAway(true);
  };

  const hideConfirmation = () => {
    setShowDiscardFormConfirmation(false);
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setShowDiscardFormConfirmation(true);
    } else {
      navBackToForms();
    }
  };

  useEffect(() => {
    if (canNavigateAway) {
      history.push('/formbuilding/forms');
    }
  }, [canNavigateAway, history]);

  const onHandleNavigate = () => {
    if (canNavigateAway) {
      return true;
    }

    setShowDiscardFormConfirmation(true);
    return false;
  };

  return (
    <>
      <Prompt when={isDirty} message={onHandleNavigate} />
      <FormProvider<FormSchema> {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={2}>
              <Box display="flex" flexDirection="column" paddingTop="40px">
                <Typography variant="h5" component="h1" className={classes.headerText}>
                  {headerText}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} className={classes.surface}>
              <MetadataEdit formVersion={currentValues.metadata.version} editorMode={editorMode} />
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.outerGrid}>
            <Grid item xs={2}>
              <Tabs
                orientation="vertical"
                value={tabValue}
                onChange={handleChange}
                className={classes.tabs}
              >
                {fields.map((item, index) => (
                  <FormsTab
                    onRemove={handleRemovePage}
                    onMoveUp={handleMovePagePositionUp}
                    onMoveDown={handleMovePagePositionDown}
                    index={index}
                    title={item.title}
                    key={item.id}
                    label={item.title}
                    {...a11yProps(index)}
                    isLastTab={fields.length === 1}
                  ></FormsTab>
                ))}
              </Tabs>
              <Button
                startIcon={<AddCircleIcon />}
                aria-label="Add a page to the form"
                onClick={handleCreateNewPage}
                className={classes.addButton}
              >
                Add Page
              </Button>
            </Grid>
            <Grid item xs={10} className={classes.surface}>
              <div>
                {fields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <TabPanel value={tabValue} index={index}>
                        <PageEdit
                          index={index}
                          buildingBlocksData={blockVersions as BuildingBlockVersionData[]}
                        />
                      </TabPanel>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0} alignItems="center" className={classes.outerGrid}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} className={classes.surface}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" height="100%">
                <Box display="flex" flexDirection="row" justifyContent="center" marginRight="auto">
                  <StyledButton onClick={generatePreview}>Preview</StyledButton>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <StyledButton onClick={handleCancelClick}>Cancel</StyledButton>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Save and Exit
                  </StyledButton>
                  <ConfirmationDialog
                    confirmationText={'Are you sure you want to discard changes to this form?'}
                    negativeChoice={'No, keep building'}
                    open={showDiscardFormConfirmation}
                    handleCancel={hideConfirmation}
                    handleConfirm={navBackToForms}
                  ></ConfirmationDialog>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
