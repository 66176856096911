import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      margin: 'auto 0',
    },
    paper: {
      marginBottom: theme.spacing(2),
      borderLeft: '7px solid #ffffff00',
    },
    blockError: {
      borderLeft: 'red 7px solid',
      marginBottom: theme.spacing(2),
    },
  })
);
