import axios from 'axios';

export interface LoginModel {
  email: string;
  password: string;
}

export interface RegistrationModel {
  email: string;
  firstName: string;
  familyName: string;
  password: string;
  confirmedPassword: string;
}

export interface RequestPasswordResetModel {
  email: string;
}

export interface PerformPasswordResetModel {
  email: string;
  password: string;
  confirmedPassword: string;
  code: string;
}

interface ValidationErrors {
  [key: string]: string[];
}
interface ValidationProblem {
  errors: ValidationErrors;
}

export async function login(loginData: LoginModel): Promise<boolean | ValidationErrors> {
  try {
    const response = await axios.post<ValidationProblem>(`/internalapi/auth/login`, loginData, {
      validateStatus: (statusCode) => statusCode === 204 || statusCode === 400,
    });

    return response?.status === 204 ? true : response.data.errors;
  } catch (error) {
    return false;
  }
}

export async function logout() {
  try {
    const response = await axios.post<ValidationProblem>(
      '/internalapi/auth/logout',
      {},
      {
        validateStatus: (statusCode) => statusCode === 204 || statusCode === 400,
      }
    );

    return response.status === 204 ? true : response.data.errors;
  } catch (error) {
    return error;
  }
}

export async function registerUser({
  email,
  firstName,
  familyName,
  password,
  confirmedPassword,
}: RegistrationModel): Promise<boolean | ValidationErrors> {
  const registrationData = {
    email: email,
    firstname: firstName,
    familyName: familyName,
    password: password,
    confirmPassword: confirmedPassword,
  };

  try {
    const response = await axios.post<ValidationProblem>(
      `/internalapi/auth/register`,
      registrationData,
      {
        validateStatus: (statusCode) => statusCode === 204 || statusCode === 400,
      }
    );

    return response?.status === 204 ? true : response.data.errors;
  } catch (error) {
    return false;
  }
}

export async function requestPasswordReset({
  email,
}: RequestPasswordResetModel): Promise<boolean | ValidationErrors> {
  const requestPasswordResetData = {
    email: email,
  };

  try {
    const response = await axios.post<ValidationProblem>(
      `/internalapi/auth/request-password-reset`,
      requestPasswordResetData,
      {
        validateStatus: (statusCode) => statusCode === 204 || statusCode === 400,
      }
    );

    return response?.status === 204 ? true : response.data.errors;
  } catch (error) {
    return false;
  }
}

export async function performPasswordReset({
  email,
  password,
  confirmedPassword,
  code,
}: PerformPasswordResetModel): Promise<boolean | ValidationErrors> {
  const performPasswordResetData = {
    email: email,
    password: password,
    confirmPassword: confirmedPassword,
    code: code,
  };

  try {
    const response = await axios.post<ValidationProblem>(
      `/internalapi/auth/perform-password-reset`,
      performPasswordResetData,
      {
        validateStatus: (statusCode) => statusCode === 204 || statusCode === 400,
      }
    );

    return response?.status === 204 ? true : response.data.errors;
  } catch (error) {
    return false;
  }
}
