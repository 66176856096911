import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, IconButtonProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    color: '#33444E',
    '&:hover': {
      color: 'black',
    },
  },
}));

interface Props extends Omit<IconButtonProps, 'component' | 'className'> {}

const MenuIcon = (props: Props) => {
  const classes = useStyles();

  return (
    <IconButton component="span" className={classes.menuButton} {...props}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default MenuIcon;
