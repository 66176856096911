import { AppBar, Toolbar, Typography, Button, ButtonProps, Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Can } from '../AppContext/AuthenticatedUser/AbilityContext';
import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { logout } from '../Services/Auth.Service';
import { useApplicationContext } from '../AppContext/AppContext';
import { When } from '../Components/When/When';
import formsLogo from '../Forms4Health_Logo_Main.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import { Container } from '@material-ui/core';

const useNavLinkStyles = makeStyles((theme: Theme) => {
  return {
    link: {
      color: theme.palette.grey.A100,
      textTransform: 'none',
      borderRadius: '10px',
      border: '4px solid',
      borderColor: '#251430',
      '&:hover': {
        color: theme.palette.common.white,
        background: 'none',
      },
      '&.active': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      fontSize: 'large',
    },
  };
});

const NavLink = (props: Omit<NavLinkProps, 'color' | 'className' | 'style'>) => {
  const classes = useNavLinkStyles();

  return (
    <Button
      disableRipple
      disableFocusRipple
      disableElevation
      disableTouchRipple
      className={classes.link}
      component={RouterLink}
      {...props}
    >
      {props.children}
    </Button>
  );
};

const ExternalNavLink = (props: Pick<ButtonProps<'a'>, 'children' | 'href'>) => {
  const classes = useNavLinkStyles();

  return (
    <Button
      disableRipple
      disableFocusRipple
      disableElevation
      disableTouchRipple
      component={'a'}
      className={classes.link}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    flexGrow: 1,
    paddingLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
    paddingLeft: 0,
  },
  menuIcon: {
    fontSize: '40px',
  },
  accountIcon: {
    fontSize: '25px',
  },
  menuTypography: {
    margin: '5px',
    alignContent: 'center',
  },
  menuLogo: {
    height: 35,
    marginRight: '5px',
  },
  accountButton: {
    color: 'white',
    background: '#33444E',
    margin: '5px',
  },
}));

const BASE_PATH = '/formbuilding';

// TODO: Extract these paths
const usePaths = (basePath?: string) => ({
  formBuilding: basePath,
  buildingBlocks: `${basePath}/buildingblocks`,
  forms: `${basePath}/forms`,
  referenceData: `${basePath}/referencedata`,
  users: `/admin/usermanagement`,
  publishing: `/publishing`,
});

const NavMenu = () => {
  const classes = useStyles();
  const paths = usePaths(BASE_PATH);
  const history = useHistory();

  const { authenticatedUser, clearContext } = useApplicationContext();

  const onClickLogout = async () => {
    const response = await logout();
    handleAccountClose();
    if (response === true) {
      history.push('/auth/login');
      clearContext();
    }
  };

  const [anchorAccountEl, setAnchorAccountEl] = React.useState(null);

  const handleAccountMenu = (event: any) => {
    setAnchorAccountEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorAccountEl(null);
  };

  return (
    <AppBar position="sticky">
      <Container>
        <Toolbar className={classes.toolBar} role="navigation" component="nav">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <img src={formsLogo} alt={'The Forms4Health logo'} className={classes.menuLogo} />
            </Grid>
            <When condition={!!authenticatedUser}>
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Can I="create" a="BuildingBlock">
                      <NavLink to={paths.buildingBlocks}>Building Blocks</NavLink>
                    </Can>
                  </Grid>
                  <Grid item>
                    <Can I="preview" a="Form">
                      <NavLink to={paths.forms}>Forms</NavLink>
                    </Can>
                  </Grid>
                  <Grid item>
                    <Can I="create" a="ReferenceDataSet">
                      <NavLink to={paths.referenceData}>Reference Data</NavLink>
                    </Can>
                  </Grid>
                  <Grid item>
                    <Can I="publish" a="Form">
                      <NavLink to={paths.publishing}>Publish</NavLink>
                    </Can>
                  </Grid>
                  <Grid item>
                    <ExternalNavLink href="https://docs.forms4health.com/">Docs</ExternalNavLink>
                  </Grid>
                  <Grid item>
                    <Can I="update" a="User">
                      <NavLink to={paths.users}>Users</NavLink>
                    </Can>
                  </Grid>
                </Grid>
              </Grid>
            </When>
            <Grid item>
              {authenticatedUser && (
                <>
                  <Typography
                    component="h5"
                    color="inherit"
                    display="inline"
                    align="center"
                    className={classes.menuTypography}
                  >
                    {authenticatedUser.firstName + ' ' + authenticatedUser.lastName}
                  </Typography>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="small"
                    onClick={handleAccountMenu}
                    className={classes.accountButton}
                  >
                    <PersonIcon className={classes.accountIcon} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorAccountEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={Boolean(anchorAccountEl)}
                    onClose={handleAccountClose}
                  >
                    <MenuItem onClick={onClickLogout}>Log Out</MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavMenu;
