import * as React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import InputTooltip from '../Tooltips/InputTooltip';

interface Props
  extends Omit<TextFieldProps, 'variant' | 'margin' | 'ref' | 'inputRef' | 'size' | 'InputProps'> {
  tooltip?: NonNullable<React.ReactNode>;
  readOnly?: boolean;
}

const TextInput = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>): React.ReactElement => {
    const { tooltip, readOnly = false, ...rest } = props;

    return (
      <TextField
        id={props.id ?? props.name}
        fullWidth
        {...rest}
        inputRef={ref}
        variant="outlined"
        margin="normal"
        size="small"
        InputProps={{
          startAdornment: tooltip ? <InputTooltip title={tooltip} /> : undefined,
          readOnly: readOnly,
        }}
        InputLabelProps={{ shrink: true }}
      />
    );
  }
);

export default TextInput;
