import UserManagement from './UserManagement/Page';
import { Route, useRouteMatch } from 'react-router';

const Routing = () => {
  const match = useRouteMatch();

  return <Route path={`${match.path}/usermanagement`} component={UserManagement} />;
};

export default Routing;
