import { NavLink as RouterLink, NavLinkProps } from 'react-router-dom';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './Styles';
import classNames from 'classnames';

type Props = ButtonProps<'a'> & Omit<NavLinkProps, 'color'>;

const NavLinkButton = (props: Props) => {
  const classes = useStyles({
    color: props.color,
  });

  return (
    <Button component={RouterLink} {...props} className={classNames(props.className, classes.link)}>
      {props.children}
    </Button>
  );
};

export default NavLinkButton;
