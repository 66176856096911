import ErrorTemplate from './Error/ErrorTemplate';

const AccessDenied = () => {
  return (
    <ErrorTemplate
      header={'Access Denied'}
      message={'You do not have permission to access this resource'}
    />
  );
};

export default AccessDenied;
