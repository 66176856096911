import { render } from 'react-dom';
import { logConsoleError } from './Services/Logging.Service';
import ErrorBoundary from './ErrorBoundary';
import Modal from 'react-modal';
import App from './App';
import configureAxios from './Axios';

window.onerror = logConsoleError;

const root = document.getElementById('root') as HTMLElement;
Modal.setAppElement(root);
configureAxios();

render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  root
);
