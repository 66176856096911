import * as React from 'react';
import { Card, Typography, Button, CardContent } from '@material-ui/core';
import { registerUser } from '../../Services/Auth.Service';
import { useForm } from 'react-hook-form';
import TextInput from '../../Components/TextInput/TextInput';
import { sortResponseErrors } from '../Account';

interface RegistrationFormValues {
  email: string;
  firstName: string;
  familyName: string;
  password: string;
  confirmedPassword: string;
}

export const Register = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<RegistrationFormValues>();
  const [formSubmitted, setFormSubmitted] = React.useState<Boolean>(false);

  const onSubmit = async ({
    email,
    firstName,
    familyName,
    password,
    confirmedPassword,
  }: RegistrationFormValues) => {
    const response = await registerUser({
      email,
      firstName,
      familyName,
      password,
      confirmedPassword,
    });

    if (response === true) {
      setFormSubmitted(true);
    } else {
      const sortedErrors = sortResponseErrors(Object.entries(response));
      sortedErrors.forEach((error) => {
        // List of errors found at https://github.com/aspnet/Identity/blob/master/src/Core/Resources.resx
        if (error.type === 'PasswordMismatch' || error.type === 'ConfirmPassword') {
          setError('confirmedPassword', {
            type: 'server',
            message: error.message,
          });
        } else if (error.type.toLowerCase().includes('password')) {
          // e.g PasswordRequiresNonAlphanumeric, PasswordRequiresUpper etc.
          setError('password', {
            type: 'server',
            message: error.message,
          });
        } else {
          setError('email', {
            type: 'server',
            message: error.message,
          });
        }
      });
    }
  };

  if (formSubmitted === false) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h3" component="h1" align="center">
            Create an account
          </Typography>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              error={errors.email !== undefined}
              helperText={errors.email?.message}
              required
              id="email"
              {...register('email', { required: true })}
              label="Email address"
              type="email"
            />

            <TextInput
              required
              id="firstName"
              {...register('firstName', { required: true })}
              label="First Name"
            />

            <TextInput
              required
              id="familyName"
              {...register('familyName', { required: true })}
              label="Family Name"
            />

            <TextInput
              error={errors.password !== undefined}
              helperText={errors.password?.message}
              required
              id="password"
              {...register('password', { required: true })}
              label="Password"
              type="password"
            />

            <TextInput
              error={errors.confirmedPassword !== undefined}
              helperText={errors.confirmedPassword?.message}
              required
              id="confirmedPassword"
              {...register('confirmedPassword', { required: true })}
              label="Confirm Password"
              type="password"
            />

            <Button variant="contained" color="primary" type="submit">
              Register
            </Button>
          </form>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Typography variant="h3" component="h1" align="center">
            Account created
          </Typography>

          <p>
            Your account requires activation by an admin before you will be able to access the site.
          </p>
        </CardContent>
      </Card>
    );
  }
};
