import * as React from 'react';
import User from './User';
import Organisation from './Organisation';
import Role from './Role';
import MaterialTable from '@material-table/core';
import MaterialTableIcons from '../../MaterialTableIcons';

export interface UserUpdate {
  identifier: string;
  userRoleIdentifier: string;
  organisationIdentifier: string | null;
  isEnabled: boolean;
}

const UsersTable = (props: {
  users: User[];
  organisations: Organisation[];
  roles: Role[];
  updateUser: (user: UserUpdate) => Promise<void>;
}) => {
  const { users, organisations, roles, updateUser } = props;

  const flattenUsers = users.map((user) => ({
    identifier: user.identifier,
    name: user.firstName,
    surname: user.surname,
    email: user.email,
    userRoleIdentifier: user.role.identifier,
    organisationIdentifier: user.organisation?.identifier ?? '',
    isEnabled: user.isEnabled,
  }));

  const organisationLookup = {};
  organisationLookup[''] = '\u00a0';
  organisations.forEach((organisation) => {
    const { identifier, name } = organisation;
    organisationLookup[identifier] = name;
  });

  const roleLookup = {};

  roles.forEach((role) => {
    const { identifier } = role;
    roleLookup[identifier] = identifier;
  });

  return (
    <MaterialTable
      icons={MaterialTableIcons}
      title="Users"
      columns={[
        { title: 'Name', field: 'name', editable: 'never' },
        { title: 'Surname', field: 'surname', editable: 'never' },
        { title: 'E-mail', field: 'email', editable: 'never' },
        { title: 'User role', field: 'userRoleIdentifier', lookup: roleLookup },
        {
          title: 'Organisation',
          field: 'organisationIdentifier',
          lookup: organisationLookup,
        },
        { title: 'Activated', field: 'isEnabled', type: 'boolean' },
      ]}
      data={flattenUsers}
      options={{
        search: true,
        actionsColumnIndex: -1,
      }}
      editable={{
        isDeletable: () => false,
        onRowUpdate: (user) => updateUser(user),
      }}
    />
  );
};

export default UsersTable;
