import ReferenceDataList from './List';
import { useRouteMatch } from 'react-router';
import { AuthorisedRoute } from '../../AuthorisedRoute';
import ReferenceDataPage from './ReferenceDataPage/ReferenceDataPage';
import Switch from '../../ErrorHandlingSwitch';

const Routing = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute path={`${match.path}`} exact can={{ I: 'create', a: 'ReferenceDataSet' }}>
        <ReferenceDataList />
      </AuthorisedRoute>

      <AuthorisedRoute
        path={`${match.path}/new`}
        exact
        can={{ I: 'create', a: 'ReferenceDataSet' }}
      >
        <ReferenceDataPage isNew={true} />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${match.path}/new/:key`} can={{ I: 'create', a: 'ReferenceDataSet' }}>
        <ReferenceDataPage isNew={false} />
      </AuthorisedRoute>

      <AuthorisedRoute
        path={`${match.path}/edit/:key/:version`}
        can={{ I: 'create', a: 'ReferenceDataSet' }}
      >
        <ReferenceDataPage isNew={false} />
      </AuthorisedRoute>
    </Switch>
  );
};

export default Routing;
