import PublishingPage from './Page';
import { AuthorisedRoute } from '../AuthorisedRoute';

const Routing = () => {
  return (
    <AuthorisedRoute can={{ I: 'publish', a: 'Form' }}>
      <PublishingPage />
    </AuthorisedRoute>
  );
};

export default Routing;
