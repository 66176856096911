import useAxios from 'axios-hooks';
import SubmissionSettings from './SubmissionSettings';
import Table from './FormsTable';
import { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { LoadingSpinner } from '@airelogic/forms';
import Toast from '../Components/Toast/Toast';
import { Form, CallToActionSettings, SubmissionData, ExistingData } from './Types';

const PublishingPage = () => {
  const apiURl = '/internalapi/publishing/Organisation';

  const [{ data, loading }, refetch] = useAxios<ExistingData>(
    {
      url: apiURl,
    },
    { manual: false }
  );

  const [, updateData] = useAxios(
    {
      url: apiURl,
      method: 'POST',
    },
    { manual: true }
  );

  const [stateUpdatedAvailableForms, setUpdatedAvailableForms] = useState<Form[]>([]);
  const [stateCallToActionSettings, setCallToActionSettings] = useState<
    CallToActionSettings | null | undefined
  >(undefined);
  const [stateDestinationEmail, setDestinationEmail] = useState('');
  const [stateDataUpdated, setStateDataUpdated] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [failedToastOpen, setFailedToastOpen] = useState(false);
  const [publishedForms, setPublishedForms] = useState<Form[]>([]);

  const successToastClose = () => {
    setSuccessToastOpen(false);
  };

  const failedToastClose = () => {
    setFailedToastOpen(false);
  };

  const updateSettings = (
    callToActionSettings: CallToActionSettings,
    deliveryEmail: string
  ): void => {
    if (callToActionSettings.formKey !== 'none') {
      setCallToActionSettings(callToActionSettings);
    } else if (callToActionSettings.formKey === 'none') {
      setCallToActionSettings(undefined);
    }
    if (deliveryEmail !== '') {
      setDestinationEmail(deliveryEmail);
    }
    setStateDataUpdated(true);
  };

  useEffect(() => {
    if (data) {
      setPublishedForms(data.existingForms.filter((form) => form.published === true));
      setUpdatedAvailableForms(data.existingForms);
      setDestinationEmail(data.destinationEmail);
      setCallToActionSettings(data.callToActionSettings);
    }
  }, [data]);

  const updateTableData = useCallback(
    (
      formKey: string,
      formVersion: number,
      overrideEmail: string | null,
      published: boolean | null
    ): void => {
      const updatedAvailableForms: Form[] = data!.existingForms.map((form) => {
        if (form.key === formKey && form.version === formVersion) {
          if (published !== null) {
            form.published = published;
          }
          if (overrideEmail) {
            form.deliveryOverride = overrideEmail;
          }
        }
        return form;
      });
      setUpdatedAvailableForms(updatedAvailableForms);
      setStateDataUpdated(true);
    },
    [data]
  );

  const createPostData = async () => {
    const submissionData: SubmissionData = {
      destinationEmail: stateDestinationEmail,
      callToActionSettings: stateCallToActionSettings,
      availableForms: stateUpdatedAvailableForms,
    };

    try {
      await updateData({ data: submissionData });
      setStateDataUpdated(false);
      setSuccessToastOpen(true);
      await refetch();
    } catch {
      setFailedToastOpen(true);
    }
  };

  const createPostDataCallback = useCallback(createPostData, [
    refetch,
    stateCallToActionSettings,
    stateDestinationEmail,
    stateUpdatedAvailableForms,
    updateData,
  ]);

  useEffect(() => {
    if (stateDataUpdated) {
      createPostDataCallback();
    }
  }, [
    stateDataUpdated,
    stateDestinationEmail,
    stateUpdatedAvailableForms,
    stateCallToActionSettings,
    createPostDataCallback,
  ]);

  if (loading && !data) {
    return <LoadingSpinner fullscreen={true} />;
  }

  return (
    <div>
      <SubmissionSettings
        loading={loading}
        publishedForms={publishedForms ?? []}
        existingData={data}
        updateSettings={updateSettings}
      />
      <Table
        loading={loading}
        existingForms={data?.existingForms ?? []}
        updateTableData={updateTableData}
      />

      <Toast severity="success" open={successToastOpen} onClose={successToastClose}>
        Settings successfully updated.
      </Toast>

      <Toast severity="error" open={failedToastOpen} onClose={failedToastClose}>
        Failed to update settings.
      </Toast>
    </div>
  );
};
export default PublishingPage;
