import React, { useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import ReferenceDataRowEdit from '../ReferenceDataRowEdit/ReferenceDataRowEdit';
import { Paper, Box, Button, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStyles } from './ReferenceDataPageEdit.styles';
import { ResourceKeyRegex } from '../../ResourceKeyRegex';
import TextInput from '../../../Components/TextInput/TextInput';
import { ReferenceDataItem } from '../Types';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

interface Props {
  isNew: boolean;
}

interface Inputs {
  key: string;
  referenceData: ReferenceDataItem[];
}

const ReferenceDataPageEdit = ({ isNew }: Props) => {
  const classes = useStyles();

  const virtuoso = useRef<VirtuosoHandle>(null);

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<Inputs>();

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `referenceData`,
  });

  const onAppendDataRow = () => {
    append({
      isNew: true,
    });
    virtuoso.current?.scrollToIndex(fields.length);
  };

  const onMoveDataRowDown = (index: number) => {
    if (fields.length > 1 && index < fields.length - 1) {
      move(index, index + 1);
    }
  };

  const onMoveDataRowUp = (index: number) => {
    if (fields.length > 1 && index > 0) {
      move(index, index - 1);
    }
  };

  const onRemoveDataRow = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const getError = (key: keyof Inputs) => {
    return errors?.key;
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <Box display="flex" flexDirection="row" className={classes.paperHeader}>
        <Typography variant="h6" className={classes.headerText} aria-label="page title">
          Reference Data
        </Typography>
      </Box>

      <Box width="50%">
        <TextInput
          id="key"
          label="Key"
          fullWidth
          {...register('key', {
            required: { value: true, message: 'Must not be empty' },
            pattern: {
              value: ResourceKeyRegex,
              message: 'Key must not contain whitespace',
            },
          })}
          required
          error={getError('key') !== undefined}
          helperText={getError('key')?.message}
          disabled={!isNew}
          tooltip={
            !isNew
              ? 'The key cannot be changed once created.'
              : 'Identifies this specific reference data.'
          }
        />
      </Box>
      <Paper elevation={0} className={classes.scrollingPaper}>
        <ol className={classes.referenceList}>
          <Virtuoso
            ref={virtuoso}
            data={fields}
            itemContent={(index, data) => {
              return (
                <li key={data.id} className={classes.referenceDataItem}>
                  <ReferenceDataRowEdit
                    index={index}
                    referenceDataLength={fields.length}
                    onMoveDown={() => onMoveDataRowDown(index)}
                    onMoveUp={() => onMoveDataRowUp(index)}
                    onRemove={() => onRemoveDataRow(index)}
                  />
                </li>
              );
            }}
          />
        </ol>
      </Paper>
      <Box>
        <Button
          startIcon={<AddCircleIcon />}
          aria-label="Add reference data"
          onClick={() => onAppendDataRow()}
          className={classes.addButton}
        >
          Add Reference Data
        </Button>
      </Box>
    </Paper>
  );
};

export default ReferenceDataPageEdit;
