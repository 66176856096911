import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import TextInput from '../../../../Components/TextInput/TextInput';
import SelectInput from '../../../../Components/SelectInput/SelectInput';
import DropdownArrow from '../../../../Components/IconButtons/DropdownArrow';
import { FormSchema, deliveryStatuses } from '../FormBuildingInterfaces';
import BaseballCapSwitch from '../../../../Components/Switches/BaseballCapSwitch';
import { useStyles } from './MetadataEdit.styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useAxios from 'axios-hooks';
import { LoadingSpinner } from '@airelogic/forms';
import { suggestFormKey } from '../FormBuilding.Service';
import { EditorMode } from '../FormEdit/FormEdit';
import { When } from '../../../../Components/When/When';
import { FlexRow, FlexColumn, Row, Column } from '../../../../Components/FlexBoxes/FlexBoxes';
import DeliveryStatusTooltipContent from './DeliveryStatusTooltipContent';
import InputTooltip from '../../../../Components/Tooltips/InputTooltip';
import { Can } from '../../../../AppContext/AuthenticatedUser/AbilityContext';

interface Props {
  editorMode: EditorMode;
  formVersion: number | undefined;
}

const MetadataEdit: React.FC<Props> = ({ editorMode, formVersion }) => {
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<FormSchema>();
  const [expanded, setExpanded] = useState<boolean>(false);

  let formDocClasses = [{ value: '', label: '', inputValue: '' }];
  const [{ data, loading }] = useAxios<string[]>({
    url: '/internalapi/FormBuilding/forms/documentclassifications',
  });

  if (loading) {
    return <LoadingSpinner fullscreen={true} />;
  }

  if (data) {
    formDocClasses = data.map((c: string) => ({
      value: c,
      label: c,
      inputValue: c,
    }));
  }

  const docClassFilter =
    createFilterOptions<{ label: string; value: string; inputValue: string }>();

  const hasErrors = () => {
    if (
      !expanded &&
      (errors?.metadata?.classification !== undefined ||
        errors.persistenceSettings !== undefined ||
        errors.pdfSettings !== undefined ||
        errors?.metadata?.deliveryStatus !== undefined)
    ) {
      setExpanded(true);
    }
    if (
      errors.metadata !== undefined ||
      errors.persistenceSettings !== undefined ||
      errors.pdfSettings !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isExistingForm = () => {
    if (editorMode !== 'new') {
      return true;
    } else return false;
  };

  return (
    <Paper
      data-testid="form"
      className={hasErrors() ? classes.formError : classes.paperStyle}
      elevation={3}
    >
      <Row className={classes.paperHeader}>
        <Typography variant="h5" component="h2" className={classes.headerText}>
          Configuration
        </Typography>
        <When condition={editorMode === 'edit'}>
          <Typography
            noWrap
            display="inline"
            variant="h5"
            component="h2"
            className={classes.versionText}
          >
            You are currently editing version:{' '}
            <Typography noWrap display="inline" className={classes.versionTextBold}>
              {formVersion}
            </Typography>
          </Typography>
        </When>
      </Row>
      <FlexRow padding="10px">
        <Column height="100%" minWidth="50px" margin="0px 0 auto 0">
          <DropdownArrow
            expanded={expanded}
            handleChange={setExpanded}
            arialabel={'additional form configuration options'}
            tooltipText={'additional form configuration options'}
          />
        </Column>

        <FlexColumn margin="0px 10px auto 10px">
          <TextInput
            className={classes.formInput}
            error={errors?.metadata?.title !== undefined}
            helperText={errors?.metadata?.title ? errors?.metadata?.title.message : ''}
            required
            {...register('metadata.title')}
            label="Form name"
            autoFocus={getValues('metadata.title') === ''}
            onBlur={(e) => {
              const key = getValues('metadata.key');
              if (key === '' || key === undefined) {
                setValue('metadata.key', suggestFormKey(getValues('metadata.title')));
              }
            }}
            type="text"
          />
        </FlexColumn>
        <FlexColumn margin="0px 10px auto 10px">
          <SelectInput
            className={classes.formInput}
            required
            {...register('metadata.layout')}
            label="Form type"
            disabled={isExistingForm()}
            tooltip={
              isExistingForm()
                ? 'The type of the form cannot be changed.'
                : 'Single-Pass forms are stateless and only designed to be used once. The user cannot save their progress and return later.' +
                  'Clinician forms are more densely presented and contain a save button; they are more geared towards use by NHS staff. '
            }
          >
            <option key={'clinician'} value={'clinician'}>
              Clinician
            </option>
            <option key={'single-pass'} value={'single-pass'}>
              Single-Pass
            </option>
          </SelectInput>
        </FlexColumn>
        <FlexColumn margin="0px 0px auto 10px">
          <TextInput
            className={classes.formInput}
            required
            error={Boolean(errors?.metadata?.key)}
            helperText={errors?.metadata?.key?.message ?? ''}
            {...register('metadata.key')}
            disabled={isExistingForm()}
            label="Form key"
            type="text"
            tooltip={
              isExistingForm()
                ? 'The key cannot be changed once created.'
                : 'Identifies this specific form (e.g.  "asthma-review" or "claim"). '
            }
          />
        </FlexColumn>
      </FlexRow>
      <Row padding="10px" width="100%" borderTop="solid 1px #33444E1A">
        <Controller
          control={control}
          name={'metadata.classification'}
          render={({ field }) => (
            <Autocomplete
              {...field}
              freeSolo
              onChange={(e, data) => {
                field.onChange(data ?? { label: '', value: '', inputValue: '' });
              }}
              clearOnBlur
              handleHomeEndKeys
              options={formDocClasses}
              filterOptions={(options, params) => {
                const filtered = docClassFilter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    value: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                    inputValue: params.inputValue,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.label;
              }}
              renderOption={(option) => option.label}
              style={{ width: '100%', margin: 'auto 10px auto 10px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={Boolean(errors.metadata?.classification)}
                  helperText={errors.metadata?.classification?.inputValue?.message ?? ''}
                  size="small"
                  label="Classification"
                  fullWidth={true}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputTooltip
                          className={classes.autoCompleteTooltip}
                          title={
                            'The category this form falls into. Can be used to organise and more easily find your form.'
                          }
                        />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
        <Can I="changeDeliveryStatus" a="Form">
          <SelectInput
            className={classes.formInputSpaced}
            required
            {...register('metadata.deliveryStatus')}
            label="Delivery status"
            tooltip={<DeliveryStatusTooltipContent />}
          >
            {deliveryStatuses.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SelectInput>{' '}
        </Can>
      </Row>
      <When condition={expanded}>
        <Row padding="10px" width="100%">
          <SelectInput
            className={classes.formInputSpaced}
            {...register('pdfSettings.style')}
            label="PDF style"
            required
          >
            <option value="none">None</option>
            <option value="default">Default</option>
            <option value="faxable">Faxable</option>
          </SelectInput>{' '}
          <When condition={getValues('pdfSettings.style') !== 'none'}>
            <Row alignItems="center" margin="auto 10px auto 0px">
              <Controller
                control={control}
                name={'pdfSettings.displayPageTitles'}
                render={({ field }) => (
                  <BaseballCapSwitch label="PDF display page titles" {...field}></BaseballCapSwitch>
                )}
              />
            </Row>
            <Row alignItems="center" margin="auto 10px auto 0px">
              <Controller
                control={control}
                name={'pdfSettings.displaySectionTitles'}
                render={({ field }) => (
                  <BaseballCapSwitch
                    label="PDF display section titles"
                    {...field}
                  ></BaseballCapSwitch>
                )}
              />
            </Row>
          </When>
        </Row>
        <When condition={getValues('metadata.layout') === 'clinician'}>
          <Row padding="10px" width="100%" borderTop="solid 1px #33444E1A">
            <Row alignItems="center" margin="auto 10px auto 0px">
              <Controller
                control={control}
                name={'persistenceSettings.allowDrafts'}
                render={({ field }) => (
                  <BaseballCapSwitch label="Allow saving drafts" {...field}></BaseballCapSwitch>
                )}
              />
            </Row>
            <Row alignItems="center" margin="auto 0px auto 10px">
              {' '}
              <Controller
                control={control}
                name={'persistenceSettings.allowDiscard'}
                render={({ field }) => (
                  <BaseballCapSwitch label="Allow cancelling" {...field}></BaseballCapSwitch>
                )}
              />
            </Row>
            <Row alignItems="center" margin="auto 10px auto 10px">
              <Controller
                control={control}
                name={'persistenceSettings.allowAmend'}
                render={({ field }) => (
                  <BaseballCapSwitch label="Allow amendments" {...field}></BaseballCapSwitch>
                )}
              />
            </Row>
            <Row alignItems="center" margin="auto 0px auto 10px">
              <TextInput
                className={classes.formInputSpaced}
                required
                error={Boolean(errors.persistenceSettings?.autoSaveInterval)}
                helperText={
                  errors.persistenceSettings?.autoSaveInterval
                    ? errors.persistenceSettings.autoSaveInterval.message
                    : ''
                }
                {...register('persistenceSettings.autoSaveInterval', { valueAsNumber: true })}
                label="Auto save interval"
                type="number"
                tooltip={
                  'How often (in seconds) the form should autosave itself. A value of 0 will disable autosave.'
                }
              />
            </Row>
          </Row>
        </When>
      </When>
    </Paper>
  );
};

export default MetadataEdit;
