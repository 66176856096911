import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as PreviewIconSVG } from './SVGs/eye-line.svg';
import { useStyles } from './IconButton.styles';

interface Props extends IconButtonProps {
  tooltipText?: string;
}

const PreviewIcon = (props: Props) => {
  const classes = useStyles();
  const { tooltipText = '', ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.fillButton} {...rest}>
        <SvgIcon component={PreviewIconSVG} className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default PreviewIcon;
