import { useState } from 'react';
import { profiler } from '@airelogic/fontoxpath';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const FontoProfilingTools = () => {
  const [profilerVisible, setProfilerVisible] = useState(false);
  const [summary, setSummary] = useState(profiler.getPerformanceSummary());

  const onXpathRefreshClick = () => {
    setSummary(profiler.getPerformanceSummary());
  };

  const onClearClick = () => {
    profiler.startProfiling();
    setSummary(profiler.getPerformanceSummary());
  };

  const onXpathEnableClick = () => {
    if (new URLSearchParams(window.location.search).get('xpathPerformance') !== 'true') {
      const currentQueryParams = new URLSearchParams(window.location.search);
      currentQueryParams.append('xpathPerformance', 'true');
      window.location.href = window.location.href + `?${currentQueryParams.toString()}`;
    } else {
      window.location.href = window.location.href;
    }
  };

  return (
    <div className="xmlDebugContainer">
      <h3>Xpath Performance</h3>
      {new URLSearchParams(window.location.search).get('xpathPerformance') !== 'true' ? (
        <button type="button" onClick={onXpathEnableClick}>
          Enable
        </button>
      ) : null}
      <button type="button" onClick={onXpathRefreshClick}>
        Refresh
      </button>
      <button type="button" onClick={onClearClick}>
        Clear
      </button>
      <h6 className="xpathToolsExpand" onClick={() => setProfilerVisible(!profilerVisible)}>
        {summary.length} elements found{' '}
        {summary.length > 0 ? (
          profilerVisible ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )
        ) : null}
      </h6>
      {profilerVisible
        ? summary.map((element) => (
            <div className="xpathElementDetails">
              <p>
                <b>{element.xpath}</b>
              </p>
              <div>
                Times: {element.times}
                <br></br>
                Total duration: {element.totalDuration}
                <br></br>
                Average: {element.average}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default FontoProfilingTools;
