import { Ability, AbilityBuilder } from '@casl/ability';
import { AuthenticatedUser } from './AuthenticatedUser';

export type ManagementAbilities =
  | ['preview' | 'create' | 'publish' | 'changeDeliveryStatus', 'Form']
  | ['update', 'User']
  | ['create', 'BuildingBlock']
  | ['create', 'ReferenceDataSet'];

export type FormManagementAbility = Ability<ManagementAbilities>;

export const defineAbilitiesForUser = (user?: AuthenticatedUser) => {
  const { can, build } = new AbilityBuilder<FormManagementAbility>(Ability);

  if (!user) {
    return build();
  }

  for (const activity of user.activities) {
    switch (activity) {
      case 'OrganisationCode':
        can('publish', 'Form');
        break;
      case 'MANAGE_FORM_DEFINITION':
        can('create', 'Form');
        can('preview', 'Form');
        can('create', 'BuildingBlock');
        can('create', 'ReferenceDataSet');
        break;
      case 'MANAGE_USERS':
        can('update', 'User');
        break;
      case 'PREVIEW_FORM_DEFINITION':
        can('preview', 'Form');
        break;
      case 'MANAGE_PUBLICATION_STATUS':
        can('changeDeliveryStatus', 'Form');
        can('create', 'Form');
        can('preview', 'Form');
        can('create', 'BuildingBlock');
        can('create', 'ReferenceDataSet');
        break;
    }
  }

  return build();
};
