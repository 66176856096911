import { ITypeDefinition } from './TypeDefinitionFactory';
import moment from 'moment';

export default class DateDefinition implements ITypeDefinition {
  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return value === '' || moment(value, 'YYYY-MM-DD', true).isValid();
  }
}
