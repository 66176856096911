import React from 'react';
import AireLogicTheme from './AireLogic';
import { FormTheme } from './FormTheme';
import { createUseStyles, createTheming, Styles } from 'react-jss';

const context = React.createContext<FormTheme>(AireLogicTheme('#005eb8', '#fff'));
const theming = createTheming(context);

export const ThemeProvider = theming.ThemeProvider;
export const useFormTheme = theming.useTheme;
export const createUseFormThemeStyles = <C extends string, Props = unknown>(
  styles: Styles<C, Props, FormTheme> | ((theme: FormTheme) => Styles<C, Props, undefined>)
) => createUseStyles(styles, { theming: theming });
