import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { Paper, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import DeleteIcon from '../../../Components/IconButtons/DeleteIcon';
import UpIcon from '../../../Components/IconButtons/UpIcon';
import DownIcon from '../../../Components/IconButtons/DownIcon';
import TextInput from '../../../Components/TextInput/TextInput';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import { ResourceKeyRegex } from '../../ResourceKeyRegex';
import { useStyles } from './ReferenceDataRowEdit.styles';
import { ReferenceDataItem, ReferenceDataFields } from '../Types';
import { When } from '../../../Components/When/When';

interface Props {
  index: number;
  referenceDataLength: number;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
}

export const ReferenceDataRowEdit = ({
  index,
  referenceDataLength,
  onMoveUp,
  onMoveDown,
  onRemove,
}: Props) => {
  const classes = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<ReferenceDataFields>();

  const isNew = watch(`referenceData.${index}.isNew`);

  const confirmDelete = () => {
    setShowConfirmDelete(false);
    onRemove();
  };

  const hideConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const getError = (key: keyof ReferenceDataItem) => {
    return errors?.referenceData?.[index]?.[key];
  };

  return (
    <Paper elevation={2}>
      <Box display="flex" flexDirection="row" padding="10px" width="100%" height="100%">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          margin="0px 10px auto 10px"
        >
          <TextInput
            className={classes.formInput}
            id={`referenceData.${index}.displayName`}
            error={getError('displayName') !== undefined}
            helperText={getError('displayName')?.message}
            {...register(`referenceData.${index}.displayName`, {
              required: { value: true, message: 'Must not be empty' },
              maxLength: { value: 80, message: 'Too many characters (max 80)' },
            })}
            required
            label="Label"
            type="text"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          margin="0px 10px auto 10px"
        >
          <TextInput
            className={classes.formInput}
            id={`referenceData.${index}.code`}
            error={getError('code') !== undefined}
            helperText={getError('code')?.message}
            {...register(`referenceData.${index}.code`, {
              required: { value: true, message: 'Must not be empty' },
              pattern: isNew
                ? {
                    value: ResourceKeyRegex,
                    message: 'Key must not contain whitespace',
                  }
                : undefined,
            })}
            required
            label="Code"
            type="text"
            disabled={!isNew}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          margin="0px 0px auto 10px"
        >
          <TextInput
            className={classes.formInput}
            id={`referenceData.${index}.filterGroups`}
            error={getError('filterGroups') !== undefined}
            helperText={getError('filterGroups')?.message}
            {...register(`referenceData.${index}.filterGroups`)}
            label="Filter groups"
            type="text"
            tooltip={'Space seperated values e.g. reptile mammal'}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          margin="0px 0px auto 10px"
        >
          <When condition={!isNew}>
            <Controller
              control={control}
              name={`referenceData.${index}.deprecated`}
              render={({ field }) => (
                <FormControlLabel
                  id={`referenceData.${index}.deprecated`}
                  label={'Deprecated'}
                  {...field}
                  control={<Checkbox defaultChecked={field.value}></Checkbox>}
                />
              )}
            />
          </When>
        </Box>

        <Box display="flex" flexDirection="column" height="100%" margin="0 0px auto 0px">
          <Box
            display="flex"
            flexDirection="row"
            height="100%"
            justifyContent="flex-end"
            margin="auto 0px auto 0px"
          >
            <UpIcon
              tooltipText="Move item up"
              aria-label="move reference data up in order"
              onClick={onMoveUp}
            />

            <DownIcon
              tooltipText="Move item down"
              aria-label="move reference data down in order"
              onClick={onMoveDown}
            />

            <DeleteIcon
              disabled={!isNew || (index === 0 && referenceDataLength === 1)}
              aria-label="remove reference data from page"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
            />
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        confirmationText={'Are you sure you want to remove this reference data?'}
        open={showConfirmDelete}
        handleCancel={hideConfirmation}
        handleConfirm={confirmDelete}
      ></ConfirmationDialog>
    </Paper>
  );
};

export default ReferenceDataRowEdit;
