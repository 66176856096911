import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogProps } from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import StyledButton from '../Buttons/StyledButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeader: {
      backgroundColor: '#251430', //theme.palette.primary.main,
      color: '#FFFFFF', //theme.palette.primary.contrastText,
    },
  })
);

interface ConfirmationDialogRawProps {
  confirmationText?: string;
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  positiveChoice?: string;
  negativeChoice?: string;
}

export const ConfirmationDialog = React.forwardRef(
  (
    props: ConfirmationDialogRawProps &
      Omit<DialogProps, 'variant' | 'margin' | 'ref' | 'inputRef'>,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const {
      open,
      handleConfirm,
      handleCancel,
      confirmationText = 'Are you sure?',
      positiveChoice = 'Yes',
      negativeChoice = 'Cancel',
      ...rest
    } = props;
    const classes = useStyles();
    return (
      <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} {...rest}>
        <DialogTitle className={classes.dialogHeader}>Confirmation Required</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">{confirmationText}</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton autoFocus onClick={handleCancel}>
            {negativeChoice}
          </StyledButton>
          <StyledButton onClick={handleConfirm}>{positiveChoice}</StyledButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ConfirmationDialog;
