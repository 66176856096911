import { BuildingBlock } from './BuildingBlock';
import BuildingBlockTable from './BuildingBlockTable';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useCallback, useState } from 'react';
import ExternalLinkButton from '../../Components/Buttons/ExternalLinkButton';
import { useApplicationContext } from '../../AppContext/AppContext';
import Toast from '../../Components/Toast/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const BuildingBlocks = () => {
  const [successBarOpen, setSuccessBarOpen] = useState(false);
  const [importedBuildingBlock, setImportedBuildingBlock] = useState<BuildingBlock>();

  const { orbeonHost } = useApplicationContext();
  const host = new URL(orbeonHost);
  host.pathname = '/orbeon/fr/orbeon/builder/new';

  const styles = useStyles();

  const onImportCompleted = useCallback((buildingBlock: BuildingBlock) => {
    setImportedBuildingBlock(buildingBlock);
    setSuccessBarOpen(true);
  }, []);

  const successBarClose = () => {
    setSuccessBarOpen(false);
  };

  return (
    <>
      <ExternalLinkButton
        aria-label="create new building block"
        href={host.href}
        color="primary"
        variant="contained"
        className={styles.button}
      >
        Create new
      </ExternalLinkButton>
      <BuildingBlockTable onImportComplete={onImportCompleted} orbeonHost={orbeonHost} />
      <Toast severity="success" open={successBarOpen} onClose={successBarClose}>
        Import of <strong>{importedBuildingBlock ? importedBuildingBlock.blockKey : ''}</strong> was
        successful
      </Toast>
    </>
  );
};

export default BuildingBlocks;
