import { IXPathRegister } from './Registry';
import {
  getAgeInYears,
  formatShortDate,
  getCUIAge,
  formatDisplayName,
  formatNHSNumber,
  formatTime,
  formatShortDateTime,
} from '@airelogic/cui';

export const registerCUIFunctions = (xpathRegister: IXPathRegister) => {
  registerFormatDateFunction(xpathRegister);
  registerFormatAgeFunctions(xpathRegister);
  registerFormatNameFunction(xpathRegister);
  registerFormatNHSNumberFunction(xpathRegister);
  registerFormatTimeFunctions(xpathRegister);
  registerFormatShortDateTime(xpathRegister);
};

const registerFormatDateFunction = (register: IXPathRegister) =>
  register('nf:CUIFormatDate', ['xs:string'], 'xs:string?', (context: any, date: string) =>
    formatShortDate(date)
  );

const registerFormatTimeFunctions = (register: IXPathRegister) =>
  register('nf:CUIFormatTime', ['xs:string'], 'xs:string?', (context: any, date: string) =>
    formatTime(date)
  );

const registerFormatShortDateTime = (register: IXPathRegister) =>
  register('nf:CUIFormatDateTime', ['xs:string'], 'xs:string?', (contect: any, date: string) =>
    formatShortDateTime(date)
  );

const registerFormatAgeFunctions = (register: IXPathRegister) => {
  register('nf:CUIFormatAge', ['xs:string'], 'xs:string', (context: any, dateOfBirth: string) => {
    if (dateOfBirth === '') {
      return '';
    }
    return getCUIAge(dateOfBirth);
  });

  register(
    'nf:CUIFormatAge',
    ['xs:string', 'xs:string'],
    'xs:string',
    (context: any, dateOfBirth: string, comparisonDate: string) => {
      if (dateOfBirth === '') {
        return '';
      }
      return getCUIAge(dateOfBirth, comparisonDate === '' ? new Date() : comparisonDate);
    }
  );

  register('nf:AgeInYears', ['xs:string'], 'xs:integer', (context: any, dateOfBirth: string) => {
    if (dateOfBirth === '') {
      return -1;
    }
    return getAgeInYears(dateOfBirth);
  });

  register(
    'nf:AgeInYears',
    ['xs:string', 'xs:string'],
    'xs:integer',
    (context: any, dateOfBirth: string, comparisonDate?: string) => {
      if (dateOfBirth === '') {
        return -1;
      }
      return getAgeInYears(dateOfBirth, comparisonDate);
    }
  );
};

const registerFormatNameFunction = (register: IXPathRegister) =>
  register(
    'nf:CUIFormatName',
    ['xs:string', 'xs:string', 'xs:string'],
    'xs:string',
    (context: any, given: string, family: string, title: string) =>
      formatDisplayName(given, family, title)
  );

const registerFormatNHSNumberFunction = (register: IXPathRegister) =>
  register('nf:CUIFormatNHSNumber', ['xs:string'], 'xs:string', (context: any, NHSNumber: string) =>
    formatNHSNumber(NHSNumber)
  );
