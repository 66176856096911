import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

const i18nFormInstance = i18n.createInstance();

i18nFormInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'lang',
      caches: [],
    },
    // we init with resources, we could also load these from a server depending how large they become.
    resources: {
      en: {
        //english
        translations: {
          delete: 'Delete',
          cancel: 'Cancel',
          add: 'Add',
          clear: 'Clear',
          rotate: 'Rotate',
          wizard: {
            nextStep: 'Next step',
            previousStep: 'Previous step',
            submit: 'Submit',
          },
          validationPopup: {
            heading: 'Validation errors',
            message: 'The form contains validation errors. Please correct any invalid fields.',
          },
          submissionFailedPopup: {
            heading: 'Submission failed',
            message:
              'An error occurred while submitting the form. Check your connection and try again.',
          },
          booleanButtons: {
            yes: 'Yes',
            no: 'No',
          },
          otherControl: {
            other: 'Other',
          },
          splitDate: {
            day: 'Day',
            month: 'Month',
            year: 'Year',
          },
          formWarning: {
            FORM_WITHDRAWN: {
              banner:
                'This form has been withdrawn from use. The information it contains may not be accurate.',
              dialog: {
                title: 'Form withdrawn',
                content:
                  'This form has been withdrawn from use. The information it contains may not be accurate.',
              },
            },
          },
          annotatedImage: {
            noAnnotations: 'No annotations recorded',
          },
          repeat: {
            deleteRow: 'Delete row',
            deleteRowConfirmation: {
              title: 'Confirm deletion',
              message: 'Are you sure you want to delete the row? This action cannot be undone.',
            },
          },
        },
      },
      tr: {
        //Turkish
        translations: {
          delete: 'Sil',
          cancel: 'İptal',
          add: 'Ekle',
          clear: 'Temizle',
          rotate: 'Çevir',
          wizard: {
            nextStep: 'Sonraki adım',
            previousStep: 'Önceki adım',
            submit: 'Gönder',
          },
          validationPopup: {
            heading: 'Doğrulama Hatası',
            message: 'Doldurduğunuz formda yazım hataları var. Lütfen hatalı alanları düzeltiniz.',
          },
          submissionFailedPopup: {
            heading: 'Gönderim başarısız oldu',
            message: 'Formu gönderirken bir hata oluştu. Lütfen tekrar deneyin.',
          },
          booleanButtons: {
            yes: 'Evet',
            no: 'Hayır',
          },
          otherControl: {
            other: 'Diğer',
          },
          splitDate: {
            day: 'Gün',
            month: 'Ay',
            year: 'Yıl',
          },
          formWarning: {
            FORM_WITHDRAWN: {
              banner: 'Bu form kullanımdan kaldırıldı. İçerisindeki bilgiler doğru olmayabilir.',
              dialog: {
                title: 'Formu kaldır',
                content: 'Bu form kullanımdan kaldırıldı. İçerisindeki bilgiler doğru olmayabilir.',
              },
            },
          },
          annotatedImage: {
            noAnnotations: 'Ek açıklamalar kayıt edilmedi.',
          },
          repeat: {
            deleteRow: 'Satırı sil',
            deleteRowConfirmation: {
              title: 'Silme işlemini onayla',
              message: 'Bu satırı silmek istediğinize emin misiniz? bu işlem geri alınamaz.',
            },
          },
        },
      },
      hr: {
        //Croatian
        translations: {
          wizard: {
            nextStep: 'Sljedeći korak',
            previousStep: 'Prethodni korak',
            submit: 'Podnijeti',
          },
          validationPopup: {
            heading: 'Postoje pogreške u validaciji',
            message: 'Obrazac sadrži pogreške u provjeri valjanosti. Ispravite nevaljana polja.',
          },
          submissionFailedPopup: {
            heading: 'Slanje nije uspjelo',
            message: 'Došlo je do pogreške prilikom slanja obrasca. Molim te pokušaj ponovno.',
          },
          booleanButtons: {
            yes: 'Da',
            no: 'Ne',
          },
          otherControl: {
            other: 'Ostalo',
          },
          splitDate: {
            day: 'Dan',
            month: 'Mjesec',
            year: 'Godina',
          },
        },
      },
      it: {
        //Italian
        translations: {
          delete: 'Elimina',
          cancel: 'Annulla',
          add: 'Aggiunga',
          clear: 'Cancella',
          rotate: 'Gira',
          wizard: {
            nextStep: 'Passo successivo',
            previousStep: 'Passo precedente',
            submit: 'Invia',
          },
          validationPopup: {
            heading: 'Ci sono errori di convalida',
            message:
              'Il form contiene errori di convalida. Per favore correggere i campi non validi.',
          },
          submissionFailedPopup: {
            heading: 'Invio fallito.',
            message: "Si è verificato un errore durante l'invio del modulo. Per favore riprova.",
          },
          booleanButtons: {
            yes: 'Si',
            no: 'No',
          },
          otherControl: {
            other: 'Altro',
          },
          splitDate: {
            day: 'Giorno',
            month: 'Mese',
            year: 'Anno',
          },
          formWarning: {
            FORM_WITHDRAWN: {
              banner: 'Il modulo è stato disabilitato probabilmente a causa dei dati non corretti',
              dialog: {
                title: 'Il modulo si è disabilitato',
                content:
                  'Il modulo è stato disabilitato probabilmente a causa dei dati non corretti',
              },
            },
          },
          annotatedImage: {
            noAnnotations: 'Nessuna annotazione registrata',
          },
          repeat: {
            deleteRow: 'Elimina la riga',
            deleteRowConfirmation: {
              title: 'Conferma eliminazione',
              message: 'Conferma eliminazione? Non puoi annullare la modifica',
            },
          },
        },
      },
      es: {
        //Spanish
        translations: {
          delete: 'Eliminar',
          cancel: 'Cancelar',
          add: 'Añadir',
          clear: 'Borrar',
          rotate: 'Girar',
          wizard: {
            nextStep: 'Próximo paso',
            previousStep: 'Paso anterior',
            submit: 'Enviar',
          },
          validationPopup: {
            heading: 'Error de Validación',
            message:
              'El formulario contiene errores de validación. Por favor compruebe los campos inválidos.',
          },
          submissionFailedPopup: {
            heading: 'Envío Fallido',
            message: 'Se produjo un error al enviar el formulario. Inténtalo de nuevo.',
          },
          booleanButtons: {
            yes: 'Si',
            no: 'No',
          },
          otherControl: {
            other: 'Otro',
          },
          splitDate: {
            day: 'Día',
            month: 'Mes',
            year: 'Año',
          },
          formWarning: {
            FORM_WITHDRAWN: {
              banner:
                'Esta forma ha sido retirada. La información que contiene puede no ser exacta',
              dialog: {
                title: 'Forma retirada',
                content:
                  'Esta forma ha sido retirada. La información que contiene puede no ser exacta',
              },
            },
          },
          annotatedImage: {
            noAnnotations: 'No se han registrado las anotaciones',
          },
          repeat: {
            deleteRow: 'Eliminar la fila',
            deleteRowConfirmation: {
              title: 'Confirmar eliminación',
              message:
                '¿Estas seguro que quieres eliminar la fila? Esta acción no se puede deshacer',
            },
          },
        },
      },
      ru: {
        //Russian
        translations: {
          delete: 'Удалить',
          cancel: 'Отмена',
          add: 'Добавить',
          clear: 'Очистить',
          rotate: 'Повернуть',
          wizard: {
            nextStep: 'Далее',
            previousStep: 'Назад',
            submit: 'Отправить',
          },
          validationPopup: {
            heading: 'Ошибку при валидации',
            message: 'В форме присутствуют. Пожалуйста исправьте все поля с ошибками.',
          },
          submissionFailedPopup: {
            heading: 'Отправка не удалась',
            message:
              'При отправке формы произошла ошибка. Проверьте подключение и повторите попытку.',
          },
          booleanButtons: {
            yes: 'Да',
            no: 'Нет',
          },
          otherControl: {
            other: 'Другое',
          },
          splitDate: {
            day: 'День',
            month: 'Месяц',
            year: 'Год',
          },
          formWarning: {
            FORM_WITHDRAWN: {
              banner:
                'Данная форма изъята из употребления. Содержащаяся в ней информация может быть неточной.',
              dialog: {
                title: 'Форма отозвана',
                content:
                  'Данная форма изъята из употребления. Содержащаяся в ней информация может быть неточной.',
              },
            },
          },
          annotatedImage: {
            noAnnotations: 'Аннотации не записаны',
          },
          repeat: {
            deleteRow: 'Удалить строку',
            deleteRowConfirmation: {
              title: 'Подтвердить удаление',
              message:
                'Вы уверены, что хотите удалить строку? Это действие не может быть отменено.',
            },
          },
        },
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'it', 'hr', 'tr', 'ru'],
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    load: 'languageOnly',
  });

const updateMomentLocale = (locale: string) => {
  if (locale === 'cimode') {
    //Tests
    return;
  }

  if (locale === 'en' || locale === 'en-us') {
    moment.locale(locale); //En is already included
    return;
  }

  import(`moment/locale/${locale}`).then(() => {
    moment.locale(locale);
  });
};

updateMomentLocale(i18nFormInstance.language);
i18nFormInstance.on('languageChanged', updateMomentLocale);

export default i18nFormInstance;
