import { createContext, useContext, useEffect, useState } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';
import { defineAbilitiesForUser, FormManagementAbility } from './AbilityBuilder';
import { useApplicationContext } from '../AppContext';

export const AbilityContext = createContext<FormManagementAbility>(new Ability());

const Can = createContextualCan(AbilityContext.Consumer);

export const useAbilityContext = () => {
  return useContext(AbilityContext);
};

const UserAbilityProvider: React.FC = ({ children }) => {
  const { authenticatedUser } = useApplicationContext();
  const [ability, setAbility] = useState<FormManagementAbility>(
    defineAbilitiesForUser(authenticatedUser)
  );

  useEffect(() => {
    setAbility(defineAbilitiesForUser(authenticatedUser));
  }, [authenticatedUser]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export { Can, UserAbilityProvider };
